<template>
  <div
    class="layout d-flex flex-column"
    :style="{ backgroundColor: '#FCFCFC' }"
  >
    <global-nav />
    <b-container class="py-5">
      <b-row class="p-2 p-xl-0" align-h="center">
        <b-col cols="12" xl="2" v-if="user">
          <b-breadcrumb class="d-none d-xl-block">
            <b-breadcrumb-item href="#">마이페이지</b-breadcrumb-item>
            <template v-for="(item, i) in linkSort">
              <b-breadcrumb-item :href="item.href" v-if="item.active" :key="i">
                {{ item.text }}
              </b-breadcrumb-item>
            </template>
          </b-breadcrumb>
          <header class="mt-xl-4 mb-3">
            <h5 class="text-lg-20 fw-500">
              마이페이지
            </h5>
          </header>
          <div class="mb-2 mb-xl-0 pb-3 pb-xl-0 horizon-scroll">
            <ul class="d-flex d-xl-block list-unstyled mypage-links">
              <li
                class="mr-5 mr-xl-0 mb-3 text-nowrap"
                v-for="(item, i) in linkSort"
                :key="i"
              >
                <router-link tag="a" class="text-darkest" :to="item.href">
                  {{ item.text }}
                </router-link>
                <div class="lnb-wrapper">
                  <ul
                    class="d-flex d-xl-block mt-2 list-unstyled"
                    v-if="meta.isLnb"
                  >
                    <li
                      class="mr-4 mr-xl-0 mb-2 text-14"
                      v-for="(item2, l) in item.lnb"
                      :key="l"
                    >
                      <router-link
                        tag="a"
                        class="lnb text-darkest"
                        :to="item2.href"
                      >
                        {{ item2.text }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col xl="10" class="bg-white p-3 p-lg-5 shadow-sm">
          <article class="p-3 p-lg-5">
            <header
              class="pb-4 pb-lg-5 d-lg-flex align-items-start justify-content-between"
              v-if="meta.title"
            >
              <h6
                class="mb-3 mb-xl-0 text-24 text-lg-30 text-center text-xl-left"
              >
                {{ getTitle() }}
              </h6>
              <category-tab v-if="tabs" :tabs="tabs"> </category-tab>
              <b-btn
                v-if="meta.headerComponent === 'button'"
                variant="link text-secondary mx-n2 px-2"
                @click="showModal(meta.button.modalName)"
              >
                <div class="text-14">
                  {{ meta.button.text }}
                </div>
              </b-btn>
            </header>
            <router-view @set-category="tabs = $event" />
          </article>
        </b-col>
      </b-row>
    </b-container>
    <global-footer />
    <!-- modals -->
    <send-planner-introduce />
  </div>
</template>

<script>
import GlobalNav from "@/components/GlobalNav";
import GlobalFooter from "@/components/GlobalFooter.vue";
import CategoryTab from "@/components/CategoryTab.vue";
import Modals from "@/components/Modals/index";

export default {
  props: {
    value: { type: Object },
  },
  components: { GlobalNav, GlobalFooter, CategoryTab, ...Modals },
  watch: {
    path(n) {
      this.tabs = null;
    },
  },
  data() {
    return {
      tabs: null,
      links: {
        GUEST: [
          {
            text: "고객센터",
            href: "/service",
            lnb: [
              {
                text: "공지사항",
                href: "/service/notice",
              },
              {
                text: "이벤트",
                href: "/service/event",
              },
              {
                text: "문의게시판",
                href: "/service/inquiry",
              },
              {
                text: "약관",
                href: "/service/terms",
              },
            ],
          },
        ],
        CUSTOMER: [
          { text: "프로필", href: "/mypage/profile" },
          {
            text: "보험 비교 요청 내역",
            href: `/mypage/compare`,
          },
          {
            text: "고객센터",
            href: "/service",
            lnb: [
              {
                text: "공지사항",
                href: "/service/notice",
              },
              {
                text: "이벤트",
                href: "/service/event",
              },
              {
                text: "문의게시판",
                href: "/service/inquiry",
              },
              {
                text: "약관",
                href: "/service/terms",
              },
            ],
          },
          {
            text: "알림",
            href: "/mypage/notification",
          },
        ],
        PLANNER: [
          { text: "프로필", href: "/mypage/profile" },
          {
            text: "보험 비교 요청 내역",
            href: `/mypage/request`,
          },
          {
            text: "법인 비교 요청 내역",
            href: `/mypage/compare`,
          },
          {
            text: "캐시/마일리지",
            href: "/mypage/mileage",
          },
          {
            text: "고객센터",
            href: "/service",
            lnb: [
              {
                text: "공지사항",
                href: "/service/notice",
              },
              {
                text: "이벤트",
                href: "/service/event",
              },
              {
                text: "문의게시판",
                href: "/service/inquiry",
              },
              {
                text: "약관",
                href: "/service/terms",
              },
            ],
          },
          {
            text: "알림",
            href: "/mypage/notification",
          },
        ],
        COMPANY: [
          { text: "프로필", href: "/mypage/profile" },
          {
            text: "법인 비교 요청 내역",
            href: `/mypage/request`,
          },
          {
            text: "캐시/마일리지",
            href: "/mypage/mileage",
          },
          {
            text: "고객센터",
            href: "/service",
            lnb: [
              {
                text: "공지사항",
                href: "/service/notice",
              },
              {
                text: "이벤트",
                href: "/service/event",
              },
              {
                text: "문의게시판",
                href: "/service/inquiry",
              },
              {
                text: "약관",
                href: "/service/terms",
              },
            ],
          },
          {
            text: "알림",
            href: "/mypage/notification",
          },
        ],
      },
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
    linkSort() {
      if (!this.user) return this.links.GUEST;
      return this.links[this.user.userType] || [];
    },
  },
  methods: {
    getTitle() {
      if (!this.user) return this.meta.title;
      typeof this.meta.title === "object"
        ? this.meta.title[this.user.userType]
        : this.meta.title;
    },
    showModal(name) {
      this.$bvModal.show(name);
    },
  },
};
</script>
