<template>
  <div id="app" class="position-relative">
    <template v-if="loaded">
      <router-view />
      <!-- 펍용 버튼 더미 class dummy는 펍 끝난 뒤 지워야함-->
      <!-- <div class="dummy position-absolute" :style="{ bottom: '0' }">
        <b-btn variant="primary" @click="user = null">로그아웃</b-btn>
        <b-btn variant="primary" @click="user = 'customer'">고객화면</b-btn>
        <b-btn variant="primary" @click="user = 'planner'">설계사화면</b-btn>
        <b-btn variant="primary" @click="user = 'company'">법인화면</b-btn>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  async created() {
    this.setAlert();
    this.setConfirm();
  },
  async mounted() {
    await this.$nextTick();
    IMP.init("imp06286356");
  },
  computed: {
    loaded() {
      return this.$store.getters.loaded;
    },
  },
  beforeDeploy() {
    if (!localStorage.insTalk__token) this.signOut();
  },
  methods: {
    setAlert() {
      window.alert = async (msg = "", options) => {
        const opt = {
          okVariant: "link",
          okTitle: "확인",
          okVariant: "white w-100 h-100 m-0 p-3 rounded-0 border-top border-1",
          headerClass: `px-4 pt-4 pb-0 border-0 text-lg-16 text-uppercase font-weight-bold`,
          titleClass: "text-lg-16",
          headerTextVariant: "primary text-lg-16",
          contentClass: "confirm border-0 rounded-0",
          footerClass:
            "p-0 border-0 font-weight-bold text-center d-flex justify-content-center text-lg-14",
          centered: true,
          noCloseOnBackdrop: true,
          noStacking: true,
          id: "ALERT",
          size: "sm",
          // autoFocusButton: "ok",
          ...options,
        };
        const msgVNode = this.$createElement("div", {
          class: "mt-2",
          domProps: {
            innerHTML: `
            <h6 class="px-4 text-lg-14 lh-160 fw-400">${msg}</h6>
          `,
          },
        });
        return await this.$bvModal.msgBoxOk([msgVNode], opt);
      };
    },
    setConfirm() {
      window.confirm = async (msg = "", options) => {
        const opt = {
          cancelTitle: "취소",
          cancelVariant: "link px-4 text-black-50",
          okVariant: "primary w-50 h-100 m-0 p-3 rounded-0",
          okTitle: "확인",
          cancelVariant:
            "white w-50 h-100 m-0 p-3 rounded-0 border-top border-1",
          headerClass: `px-4 pt-4 pb-0 border-0 border-secondary text-lg-16 text-uppercase font-weight-bold`,
          headerTextVariant: "text-lg-32 text-center",
          titleClass: "text-lg-32",
          contentClass: "confirm border-0 rounded-0",
          footerClass:
            "p-0 border-0 d-flex align-items-center justify-content-between font-weight-bold text-lg-14",
          centered: true,
          noCloseOnBackdrop: true,
          size: "sm",
          noStacking: true,
          id: "CONFIRM",
          autoFocusButton: "ok",
          ...options,
        };
        const msgVNode = this.$createElement("div", {
          class: "mt-2",
          domProps: {
            innerHTML: `
            <h6 class="px-4 text-lg-14 lh-160 fw-400">${msg}</h6>
          `,
          },
        });
        const bool = await this.$bvModal.msgBoxConfirm([msgVNode], opt);
        if (bool !== null) {
          return bool;
        }
      };
    },
  },
};
</script>

<style lang="scss"></style>
