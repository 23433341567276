import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import DefaultLayout from "@/views/Layout/Default.vue";
import AccountLayout from "@/views/Layout/Account.vue";
import MypageLayout from "@/views/Layout/Mypage.vue";

import Main from "@/views/Main/index.js";
import Account from "@/views/Account/index.js";
import Mypage from "@/views/Mypage/index.js";

Vue.use(VueRouter);

const RouterView = {
  render: (h) => h("router-view"),
};

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Intro",
        component: Main.Main,
      },
    ],
  },
  {
    path: "/account",
    component: AccountLayout,
    children: [
      {
        path: "compare",
        name: "Compare",
        component: Account.Compare,
      },
      // {
      //   path: "sign-up/planner",
      //   name: "SignUpPlanner",
      //   component: Account.SignUpForm,
      //   meta: {
      //     type: "planner",
      //     header: {
      //       intro: "샘플텍스트입니다",
      //       title: "회원가입 및 법인 대리점/원수사 지점(사) 소개받기",
      //     },
      //   },
      // },
      // {
      //   path: "sign-up/company",
      //   name: "SignUpCompany",
      //   component: Account.SignUpForm,
      //   meta: {
      //     // requireNoAuth: true,
      //     type: "company",
      //     header: {
      //       intro: "샘플텍스트입니다",
      //       title: "법인 대리점 및 원수사 지점(사) 회원가입",
      //     },
      //   },
      // },
      {
        path: "login",
        name: "Login",
        component: Account.Login,
        meta: {
          requireNoAuth: true,
          header: {
            intro: "보험톡에 오신 것을 환영합니다.",
            title: "로그인",
          },
        },
      },
      {
        path: "find-password",
        name: "FindPassword",
        component: Account.FindPassword,
        meta: {
          requireNoAuth: true,
          header: {
            title: "비밀번호 찾기",
          },
        },
      },
      {
        path: "sign-out",
        name: "SignOut",
        component: Account.SignOut,
        meta: {
          requireAuth: true,
          header: {
            intro: "보험톡에 오신 것을 환영합니다.",
            title: "회원가입",
          },
        },
      },
      {
        path: "sign-up",
        name: "SignUp",
        component: Account.SignUp,
        meta: {
          requireNoAuth: true,
          header: {
            intro: "보험톡에 오신 것을 환영합니다.",
            title: "회원가입",
          },
        },
      },
    ],
  },
  {
    path: "/mypage",
    redirect: "/mypage/profile",
    component: MypageLayout,
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "profile",
        name: "Profile",
        component: Mypage.Profile,
        meta: {
          title: "프로필",
        },
      },
      {
        path: "compare",
        component: Mypage.Compare,
        meta: {
          title: {
            CUSTOMER: "보험 비교 요청 내역",
            PLANNER: "법인 비교 요청 내역",
          },
        },
      },
      {
        path: "request",
        component: Mypage.Request,
        meta: {
          title: {
            PLANNER: "보험 비교 요청 내역",
            COMPANY: "법인 비교 요청 내역",
          },
          headerComponent: "button",
          button: {
            text: "상세 정보 편집",
            modalName: "modal-edit-introduce",
          },
        },
      },
      {
        path: "mileage",
        name: "Mileage",
        component: Mypage.Mileage,
        meta: {
          title: "캐시/마일리지",
          headerComponent: "button",
          button: {
            text: "캐시충전",
            modalName: "modal-charge-cash",
          },
        },
      },
      // {
      //   path: "breakdown",
      //   name: "Breakdown",
      //   component: RouterView,
      //   children: [
      //     // {
      //     //   path: "customer",
      //     //   component: Mypage.Customer,
      //     //   meta: {
      //     //     title: "보험 비교 요청 내역",
      //     //   },
      //     // },
      //     {
      //       path: "planner-to-customer",
      //       component: Mypage.Compare,
      //       meta: {
      //         title: "보험 비교 요청 내역",
      //         type: "planner-to-customer",
      //         headerComponent: "button",
      //         button: {
      //           text: "본인 소개 편집",
      //           modalName: "modal-edit-planner-introduce",
      //         },
      //       },
      //     },
      //     {
      //       path: "planner-to-company",
      //       meta: {
      //         title: "법인 비교 요청 내역",
      //         type: "planner-to-company",
      //       },
      //     },
      //     {
      //       path: "company",
      //       meta: {
      //         title: "법인 대리점/지점(사) 비교 요청 내역",
      //         headerComponent: "button",
      //         button: {
      //           text: "법인 소개 편집",
      //           modalName: "modal-edit-company-introduce",
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        path: "notification",
        name: "Notification",
        component: Mypage.Notification,
        meta: {
          title: "알림",
        },
      },
    ],
  },
  {
    path: "/service",
    redirect: "/service/notice",
    component: MypageLayout,
    children: [
      {
        path: "notice",
        name: "Notice",
        component: Mypage.Notice,
        meta: {
          pagination: true,
          title: "공지사항",
          isLnb: true,
        },
      },
      {
        path: "notice/:id",
        name: "NoticeDetail",
        component: Mypage.Detail,
        meta: {
          api: "notices",
          isLnb: true,
        },
      },
      {
        path: "event",
        name: "Event",
        component: Mypage.Event,
        meta: {
          title: "이벤트",
          isLnb: true,
        },
      },
      {
        path: "event/:id",
        name: "EventDetail",
        component: Mypage.Detail,
        meta: {
          api: "events",
          isLnb: true,
        },
      },
      {
        path: "inquiry",
        name: "Inquiry",
        component: Mypage.Inquiry,
        meta: {
          title: "문의게시판",
          isLnb: true,
          headerComponent: "category",
        },
      },
      {
        path: "terms",
        name: "Terms",
        component: Mypage.Terms,
        meta: {
          title: "약관",
          isLnb: true,
          headerComponent: "category",
        },
      },
    ],
  },
  {
    path: "/mypage/payments/:id",
    name: "payments",
    component: Mypage.Cash,
    meta: {
      title: "캐시충전",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (from && Object.keys(to.query).length) {
      const sameCheck = (key) => {
        return to.query[key] === from.query[key];
      };
      const changed = Object.keys(to.query).filter((key) => sameCheck(key))[0];
      const noBehaviorList = ["startDate", "endDate", "page"];
      if (noBehaviorList.includes(changed)) return;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.requireAuth);
  const requireNoAuth = to.matched.some((record) => record.meta.requireNoAuth);
  const token =
    localStorage.getItem("insTalk__token") ||
    sessionStorage.getItem("insTalk__token");

  let user = store.getters.user;
  if (!store.getters.loaded) {
    await store.dispatch("getTypes");
    user = token ? await store.dispatch("getMe", token) : null;
    if (window.Notification && user) {
      window.Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          const messaging = firebase.messaging();

          try {
            const pushToken = await messaging.getToken();
            await store.dispatch("setPushToken", pushToken);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
    }
  }
  if (requireAuth) {
    // 사용자만 접근 가능
    if (user) {
      // 사용자가 있는 경우
      next();
    } else {
      // 사용자가 없는 경우
      next("/account/login");
    }
  } else if (requireNoAuth) {
    if (user) {
      next(from);
    } else {
      next();
    }
  } else {
    next();
  }
  store.commit("set", ["loaded", true]);
});

export default router;
