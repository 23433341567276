<template>
  <div>
    <b-card
      class="mb-4 pointer-event"
      @click="$router.push(`${$route.path}/${id}`)"
    >
      <b-card-header class="p-0">
        <div
          class="w-100 bg-img ratio-60 bg-dark"
          :style="{
            backgroundImage: `url(${s3 + imgO2T(origin)})`,
          }"
        ></div>
      </b-card-header>
      <b-card-text class="px-3 py-2">
        <span class="text-lg-16">
          {{ title }}
        </span>
        <div class="mt-2 text-right">
          <small class="text-dark text-14">
            {{ toLocaleDate(startDate) }} ~
            {{ toLocaleDate(endDate) }}
          </small>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    title: String,
    origin: String,
    startDate: { type: String, default: new Date().toISOString() },
    endDate: { type: String, default: new Date().toISOString() },
  },
};
</script>

<style lang="scss" scoped></style>
