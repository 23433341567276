<template>
  <b-modal
    :id="`modal-${item.id}`"
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    :title="`${item.relativeUser.name} 설계사님`"
    title-class="h2 pt-5"
    body-class="px-md-5"
    ok-title="확인"
    ok-variant="primary"
    ok-only
    @show="getReviews"
    @hide="resetReviews"
  >
    <div class="subtitle">설계사후기</div>

    <b-form @submit.prevent.stop="submit" v-if="canWrite">
      <div class="text-center">
        <b-form-rating
          v-model="input.starRate"
          show-value
          precision="1"
          class="border-0 d-flex justify-content-center starrate-custom"
        >
          <template #icon-empty>
            <i class="text-24 text-primary icon-star-empty"></i>
          </template>
          <template #icon-half>
            <i class="text-24 text-primary icon-star-empty"></i>
          </template>
          <template #icon-full>
            <i class="text-24 text-primary icon-star"></i>
          </template>
        </b-form-rating>
      </div>
      <label class="input-custom w-100">
        <span class="text-14">후기작성</span>
        <b-textarea :rows="5" class="w-100 h-auto" v-model="input.content" />
      </label>
      <div class="mt-3 text-right">
        <b-btn variant="primary" type="submit" class="px-5 py-3">등록</b-btn>
      </div>
    </b-form>
    <article class="my-5">
      <div class="text-center" v-if="!items.length">
        등록된 후기가 없습니다.
      </div>
      <review-item
        @remove-me="removeItem"
        v-else
        v-for="item in items"
        :item="item"
        :key="item.id"
      />

      <pagination-custom
        no-route
        v-if="pagination.totalDocs"
        :value="pagination.page"
        :total-rows="+pagination.totalDocs"
        :per-page="+pagination.limit"
        @page-click="getReviews"
      />
    </article>
  </b-modal>
</template>

<script>
import ReviewItem from "@/components/ReviewItem.vue";
export default {
  props: {
    item: { type: Object },
  },
  components: { ReviewItem },
  data() {
    return {
      input: {
        content: "",
        starRate: 5,
      },
      items: [],
      pagination: {},
    };
  },
  computed: {
    canWrite() {
      const { accept, statistics } = this.item;
      return accept && !statistics.written;
    },
  },
  methods: {
    async submit() {
      if (!this.input.content) {
        return alert("후기를 입력해주세요.");
      }
      const body = {
        ...this.input,
        user: this.item.relativeUser.id,
        request: this.item.id,
      };
      const { data } = await this.$axios.post("/reviews", body);
      // 완료 후 처리
      this.input = {
        content: "",
        starRate: 5,
      };
      data.data.relativeAuthor = this.user;
      this.items.unshift(data.data);
      this.$emit("written");
    },
    removeItem(e) {
      this.items = this.items.filter((item) => item.id !== e);
      this.item.statistics = { ...this.item.statistics, written: 0 };
    },
    async getReviews({ val }) {
      const { data } = await this.$axios.get(
        `/reviews/${this.item.relativeUser.id}`,
        {
          params: { limit: 5, page: val },
        }
      );
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    async resetReviews() {
      this.items = [];
      this.pagination = {};
    },
  },
};
</script>

<style></style>
