<template>
  <b-modal
    centered
    size="lg"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="보험 비교 요청서 편집"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-edit-compare"
    ok-title="확인"
    ok-variant="primary"
    cancel-title="취소"
    cancel-variant="light"
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <div class="pb-5">
      <header class="text-lg-20 fw-700 mb-4">
        가입하고 싶은 보험 종목 (중복 선택 가능)
      </header>
      <button-selector
        space="1"
        multiple
        v-model="input.wish"
        :items="wish"
        class="mb-4"
      />
      <div class="text-right my-4">
        * 모든 정보는 비공개 처리되며 최종단계에서 선택한 설계사에게 필수 정보만
        공개합니다 .
      </div>
    </div>
    <div class="pb-5">
      <header class="text-lg-20 fw-700 mb-4">
        추천 받고 싶은 설계사
      </header>
      <label>성별</label>
      <button-selector
        space="1"
        v-model="input.gender"
        :items="gender"
        class="mb-4"
      />
      <label>연령대</label>
      <button-selector
        space="1"
        v-model="input.age"
        :items="age"
        class="mb-4"
      />
      <label>경력</label>
      <button-selector
        space="1"
        v-model="input.career"
        :items="career"
        class="mb-4"
      />
      <label>설계사 활동 지역</label>
      <button-selector
        space="1"
        v-model="input.area"
        :items="area"
        class="mb-4"
      />
      <label>설계사 소속 보험사</label>
      <div class="border-bottom pb-3 mb-3">
        <button-checkable
          :block="false"
          checked
          text="GA 전체 (모든 손해보험, 생명보험을 취급하는 곳)"
          value="1"
        />
      </div>
      <button-checkable
        checked
        :block="false"
        text="손해보험 전체"
        value="1"
        class="mb-2"
      />
      <div class="border-bottom mb-3">
        <button-selector
          space="1"
          v-model="input.sonhae"
          :items="sonhae"
          class="mb-2"
        />
      </div>
      <button-checkable
        checked
        :block="false"
        text="생명보험 전체"
        value="1"
        class="mb-2"
      />
      <button-selector
        space="1"
        v-model="input.life"
        :items="life"
        class="mb-4"
      />
    </div>

    <div class="pb-5">
      <header class="text-lg-20 fw-700 mb-4">
        설계사에게 남기고 싶은 말씀
      </header>
      <label class="input-custom w-100 mb-3">
        내용
        <b-textarea :rows="5" class="w-100 h-auto" v-model="input.content" />
      </label>
    </div>
    <div class="pb-4">
      <header class="text-lg-20 fw-700 mb-4">
        제안받고 싶은 설계사 인원 수
      </header>
      <label class="input-custom w-100 mb-3">
        인원 수
        <b-select v-model="input.count">
          <b-form-select-option
            v-for="i in [5, 10, 15, 20]"
            :key="i"
            :value="i"
          >
            {{ i }}명
          </b-form-select-option>
        </b-select>
      </label>
    </div>
  </b-modal>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
import ButtonSelector from "@/components/ButtonSelector";
export default {
  components: { ButtonSelector, ButtonCheckable },
  props: {
    ok: {
      type: Function,
    },
  },
  data() {
    return {
      input: {
        id: null,
        password: null,
        name: null,
        birth: null,
        gender: null,
        addr: null,
        email: null,
        count: 10,

        wish: null,
        gender: null,
        age: null,
        career: null,
        area: null,

        content: null,
      },
      wish: [
        {
          text: "종합 보험",
          value: 1,
        },
        {
          text: "종신 보험",
          value: 2,
        },
        {
          text: "실손 보험",
          value: 3,
        },
        {
          text: "자녀 보험",
          value: 4,
        },
        {
          text: "수술비 보험",
          value: 5,
        },
        {
          text: "암 보험",
          value: 6,
        },
        {
          text: "운전자 보험",
          value: 7,
        },
        {
          text: "치아 보험",
          value: 8,
        },
        {
          text: "치매 보험",
          value: 9,
        },
        {
          text: "연금 보험",
          value: 10,
        },
        {
          text: "화재 보험",
          value: 11,
        },
        {
          text: "자동차 보험",
          value: 12,
        },
        {
          text: "잘 모르겠다",
          value: 13,
        },
      ],
      gender: [
        {
          text: "남성",
          value: 1,
        },
        {
          text: "여성",
          value: 2,
        },
        {
          text: "상관 없음",
          value: 3,
        },
      ],
      age: [
        {
          text: "20대",
          value: 1,
        },
        {
          text: "30대",
          value: 2,
        },
        {
          text: "40대",
          value: 3,
        },
        {
          text: "50대",
          value: 4,
        },
        {
          text: "상관 없음",
          value: 5,
        },
      ],
      career: [
        {
          text: "1년 이상",
          value: 1,
        },
        {
          text: "3년 이상",
          value: 2,
        },
        {
          text: "5년 이상",
          value: 3,
        },
        {
          text: "10년 이상",
          value: 4,
        },
        {
          text: "상관 없음",
          value: 5,
        },
      ],
      area: [
        {
          text: "서울",
          value: 1,
        },
        {
          text: "경기도",
          value: 2,
        },
        {
          text: "인천",
          value: 3,
        },
        {
          text: "대전",
          value: 4,
        },
        {
          text: "광주",
          value: 5,
        },
        {
          text: "부산",
          value: 6,
        },
        {
          text: "대구",
          value: 7,
        },
        {
          text: "충청도",
          value: 8,
        },
        {
          text: "전라도",
          value: 9,
        },
        {
          text: "경상도",
          value: 10,
        },
        {
          text: "강원도",
          value: 11,
        },
        {
          text: "제주도",
          value: 12,
        },
        {
          text: "상관 없음",
          value: 13,
        },
      ],
      sonhae: [
        {
          text: "삼성화재",
          value: 1,
        },
        {
          text: "DB손해보험",
          value: 2,
        },
        {
          text: "현대해상",
          value: 3,
        },
        {
          text: "KB손해보험",
          value: 4,
        },
        {
          text: "메리츠",
          value: 5,
        },
        {
          text: "한화손해",
          value: 6,
        },
        {
          text: "흥국쌍용",
          value: 7,
        },
        {
          text: "MG손해보험",
          value: 8,
        },
        {
          text: "농협손해보험",
          value: 9,
        },
        {
          text: "그 외 보험사",
          value: 10,
        },
      ],
      life: [
        {
          text: "삼성생명",
          value: 1,
        },
        {
          text: "한화생명",
          value: 2,
        },
        {
          text: "교보생명",
          value: 3,
        },
        {
          text: "매트라이프",
          value: 4,
        },
        {
          text: "라이나생명",
          value: 5,
        },
        {
          text: "알리안츠",
          value: 6,
        },
        {
          text: "농협생명",
          value: 7,
        },
        {
          text: "그 외 보험사",
          value: 8,
        },
      ],
    };
  },
};
</script>

<style></style>
