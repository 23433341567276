<template>
  <footer class="footer py-3">
    <b-container class="px-3 d-xl-flex align-items-center text-14">
      <b-navbar-brand to="/" class="mb-4 mb-lg-0 mr-4">
        <img src="@/assets/logo.svg" alt="" width="60px" />
      </b-navbar-brand>
      <ul class="list-unstyled d-lg-flex mx-n2 mx-lg-n3 mb-2 mb-lg-0">
        <li class="px-2 px-lg-3">
          <router-link
            class="text-nowrap text-dark"
            to="/service/terms?category=98"
          >
            서비스 이용약관
          </router-link>
        </li>

        <li class="px-2 px-lg-3">
          <router-link
            class="text-nowrap text-dark"
            to="/service/terms?category=99"
          >
            개인정보 취급방침
          </router-link>
        </li>

        <!-- <li class="px-2 px-lg-3">
          <router-link class="text-nowrap text-dark" to="/service/terms">
            전자금융거래 이용약관
          </router-link>
        </li>

        <li class="px-2 px-lg-3">
          <router-link class="text-nowrap text-dark" to="/service/terms">
            고객센터
          </router-link>
        </li> -->
      </ul>
      <section class="ml-0 ml-xl-5 d-flex text-lg-16 text-xl-right">
        <p class="text-10 mb-lg-0">
          <span>상호 : 보험톡</span>
          <!-- <span>대표 : 박정화</span> -->
          <span>사업자등록번호 : 858-04-02313</span>
          <span>주소 : 경기도 퍙택시 추담로 58-78</span>
          <!-- <span>팩스 : 02-123-1234</span> -->
          <!-- <span>통신판매업신고번호 : 제 2019-서울강서-0001호</span> -->
          <!-- <span> 서비스이용문의: 070-8261-3449</span> -->
          <span> 이메일: zzangkimchi@naver.com</span>
        </p>
      </section>
    </b-container>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
  span {
    display: inline-block;
    &:not(:last-child) {
      &:after {
        content: " | ";
        margin: 0 0.5em;
      }
    }
  }
}
</style>
