<template>
  <section>
    <b-row align-h="center">
      <b-col cols="12" md="8" class="form-dashed">
        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 0 <= step }"
        >
          {{
            userType === "PLANNER"
              ? "설계사 정보"
              : "법인 대리점 대표명/원수사 지점(사) 정보"
          }}
          <b-btn
            variant="link"
            class="text-dark py-0"
            to="/account/login"
            v-if="!user"
            >이미 회원이신가요?</b-btn
          >
        </header>
        <b-collapse :visible="step === 0">
          <w-form
            class="pb-5"
            @submit.prevent.stop="submit"
            v-slot="{ invalid }"
          >
            <b-row align-v="center">
              <b-col
                cols="12"
                md="4"
                class="mb-5 mb-md-0 text-center"
                v-if="userType === 'PLANNER'"
              >
                <div class="text-center">
                  <b-avatar
                    size="8.75rem"
                    :style="{ minWidth: '8.75rem' }"
                    class="backdrop-dim edit-profile bg-light"
                    :src="input.thumbnail ? s3 + input.thumbnail : null"
                  />
                  <label
                    v-if="!user"
                    class="position-absolute text-white"
                    :style="{ bottom: 0, left: 0, right: 0, zIndex: 2 }"
                    >편집
                    <b-form-file
                      class="d-none"
                      @change="fileControl"
                    ></b-form-file>
                  </label>
                </div>
              </b-col>
              <b-col cols="12" :md="userType === 'PLANNER' ? 8 : 12">
                <label class="input-custom w-100 mb-3">
                  휴대폰 번호 (아이디)
                  <w-input
                    type="tel"
                    maxlength="11"
                    placeholder="아이디를 입력하세요."
                    :plaintext="!!user"
                    :disabled="!!user"
                    class="bg-transparent"
                    v-model="input.username"
                    name="username"
                    :rules="{
                      required: true,
                      min: 10,
                      max: 11,
                      regex: /^[0-9].*$/,
                    }"
                    @input.native="$numberOnly($event, 'username', 11)"
                  />
                </label>
                <b-row v-if="!user">
                  <b-col>
                    <label class="input-custom w-100 mb-3" v-if="!user">
                      비밀번호
                      <w-input
                        type="password"
                        name="passwordRetype"
                        placeholder="비밀번호를 입력하세요."
                        v-model="input.passwordRetype"
                        rules="required|passwordStartWith|min:8|passwordCustom"
                      />
                    </label>
                  </b-col>
                  <b-col>
                    <label class="input-custom w-100 mb-3">
                      비밀번호 확인
                      <w-input
                        type="password"
                        name="password"
                        placeholder="비밀번호를 입력하세요."
                        v-model="input.password"
                        :rules="{
                          required: true,
                          min: 8,
                          is: input.passwordRetype,
                          passwordCustom: true,
                        }"
                      />
                    </label>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <label class="input-custom w-100 mb-3">
              {{
                userType === "PLANNER"
                  ? "이름"
                  : "법인 대리점 원수사 지점(사)장명"
              }}
              <w-input
                :plaintext="!!user"
                :disabled="!!user"
                name="displayname"
                type="text"
                placeholder="이름을 입력하세요."
                v-model="input.name"
                :rules="{ required: true, regex: /^[가-힣].*$/ }"
              />
            </label>
            <!-- 주민번호 -->

            <!-- PLANNER 정보 -->
            <template v-if="userType === 'PLANNER'">
              <label
                class="input-custom w-100 mb-3"
                v-if="userType !== 'COMPANY'"
              >
                주민번호 앞 7자리
                <div class="d-flex align-items-start" v-if="!user">
                  <w-input
                    placeholder="000000"
                    v-model="input.birth"
                    name="birth"
                    rules="required|birth"
                    maxlength="7"
                    no-wheel
                    @input.native="$numberOnly($event, 'birth', 6)"
                  />
                  <div class="mx-2 mx-md-4 mt-4">-</div>
                  <w-input
                    placeholder="0"
                    v-model="input.gender"
                    rules="required"
                    maxlength="1"
                    no-wheel
                    input-class="rounded"
                    @input.native="$numberOnly($event, 'gender', 1)"
                  >
                    <template #append>
                      <div
                        class="position-absolute mt-3 mr-4 text-dark pr-2"
                        :style="{
                          left: '2rem',
                          top: '.25rem',
                          bottom: 0,
                          zIndex: 10,
                        }"
                      >
                        &#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
                      </div>
                    </template>
                  </w-input>
                </div>
                <div v-else>{{ input.birth }} - {{ input.gender }}******</div>
              </label>
              <b-row align-v="start">
                <b-col cols="9">
                  <label class="input-custom w-100 mb-0">
                    협회등록번호
                  </label>
                </b-col>
                <b-col cols="6" md="3" class="pb-2"> </b-col>
                <b-col cols="9">
                  <label class="input-custom w-100 mb-0">
                    <w-input
                      v-if="!user"
                      :disabled="!!user"
                      :plaintext="!!user"
                      placeholder="협회등록번호를 입력하세요."
                      v-model="input.accessionNumber"
                      @input.native="$numberOnly($event, 'accessionNumber', 26)"
                    />
                    <span v-else>{{
                      input.accessionNumber || "신인 설계사"
                    }}</span>
                  </label>
                </b-col>
                <b-col cols="6" md="3" class="pb-2">
                  <button-checkable
                    v-if="!user"
                    text="신인 설계사 희망"
                    class="mt-2"
                    :checked="!input.accessionNumber"
                    @input="input.accessionNumber = null"
                  />
                </b-col>
              </b-row>
            </template>
            <!-- COMPANY 정보 -->
            <template v-else>
              <label class="input-custom w-100 mb-4">
                법인 대리점 상호명
                <w-input
                  name="branchName"
                  :disabled="!!user"
                  :plaintext="!!user"
                  placeholder="법인 대리점 상호명을 입력하세요."
                  v-model="input.info.branchName"
                  :rules="{ required: true, regex: /^[가-힣].*$/ }"
                />
              </label>
              <label class="input-custom w-100 mb-4">
                법인 대리점 등록번호
                <w-input
                  :disabled="!!user"
                  :plaintext="!!user"
                  type="text"
                  name="branchNumber"
                  placeholder="법인 대리점 등록번호를 입력하세요."
                  v-model="input.info.branchNumber"
                  :rules="{ regex: /^[0-9].*$/ }"
                ></w-input>
              </label>
              <label class="input-custom w-100 mb-4">
                주소
                <w-input
                  :disabled="!!user"
                  :plaintext="!!user"
                  type="text"
                  name="addr"
                  placeholder="주소를 입력하세요."
                  v-model="input.info.addr"
                  rules="required"
                />
              </label>
              <label class="input-custom w-100 mb-4">
                세금계산서 이메일
                <w-input
                  :disabled="!!user"
                  :plaintext="!!user"
                  type="email"
                  name="email"
                  placeholder="이메일을 입력하세요."
                  v-model="input.info.email"
                  rules="required|email"
                />
              </label>
            </template>
            <div class="d-flex justify-content-between my-5" v-if="!user">
              <div class="checkbox-wrap">
                <w-checkbox
                  class="mb-2"
                  v-model="input.agreeTerms"
                  :required="true"
                >
                  [필수] <a class="text-dark" href="">서비스이용약관</a>에
                  동의합니다.
                </w-checkbox>
                <w-checkbox
                  class="mb-2"
                  v-model="input.agreePrivacy"
                  :required="true"
                >
                  [필수] <a class="text-dark" href="">개인정보취급방침</a>에
                  동의합니다.
                </w-checkbox>
                <b-checkbox class="mb-2" v-model="input.agreeSMS">
                  [선택] 마케팅 메시지 수신(SMS)에 동의합니다.
                </b-checkbox>
              </div>
              <div class="flex-grow-1"></div>
              <b-btn
                class="py-3 px-4"
                variant="primary"
                @click="submit"
                :disabled="invalid"
              >
                {{ userType === "PLANNER" ? "설계사" : "법인" }} 회원가입
              </b-btn>
            </div>
            <div
              class="d-flex justify-content-end align-items-center mt-5"
              v-else
            >
              <b-btn class="text-dark" variant="link" to="/mypage/profile">
                회원정보 수정하기
              </b-btn>
              <b-btn class="py-3 px-4" variant="primary" @click="step++">
                다음
              </b-btn>
            </div>
          </w-form>
        </b-collapse>
        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 1 <= step }"
        >
          {{ userType === "PLANNER" ? "소속" : "보험사 정보" }}
        </header>
        <b-collapse :visible="step === 1">
          <div class="pb-5" v-if="userType === 'PLANNER'">
            <label>GA</label>
            <button-selector v-model="input.info.insType" :items="$types.ga" />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'ga-custom'"
                  @input="input.info.insType = 'ga-custom'"
                  text="직접입력"
                  value="ga-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'ga-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>
            <label>손해보험</label>
            <button-selector
              v-model="input.info.insType"
              :items="$types.sonhae"
            />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'sonhae-custom'"
                  @input="input.info.insType = 'sonhae-custom'"
                  text="직접입력"
                  value="sonhae-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'sonhae-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>
            <label>생명보험</label>
            <button-selector
              v-model="input.info.insType"
              :items="$types.life"
            />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'life-custom'"
                  @input="input.info.insType = 'life-custom'"
                  text="직접입력"
                  value="life-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'life-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>
            <template>
              <label class="input-custom w-100 mb-4">
                상세 소속
                <b-form-input
                  type="text"
                  placeholder="대리점명 혹은 지점명 입력하세요."
                  v-model="input.info.branch"
                />
              </label>
              <label>설계사 활동 지역(중복 선택 가능)</label>
              <button-selector
                v-model="input.info.area"
                multiple
                :items="$types.area"
                class="mb-5"
              />
              <div class="my-4">
                <label class="input-custom w-100 mb-3">
                  경력사항
                </label>
                <template v-for="(item, i) in input.info.careers">
                  <div :key="i">
                    <b-row class="mx-n2 mb-2 d-none d-md-flex">
                      <b-col class="px-2">
                        <label class="input-custom w-100">
                          <b-form-input
                            type="text"
                            placeholder="소속명"
                            class="mt-0"
                            v-model="item.name"
                          />
                        </label>
                      </b-col>
                      <b-col class="px-2">
                        <label class="input-custom w-100"
                          ><b-select v-model="item.type" class="mt-0">
                            <b-form-select-option
                              v-for="(option, l) in $types.insType"
                              :value="option.value"
                              :key="l"
                              >{{ option.name }}</b-form-select-option
                            >
                          </b-select></label
                        >
                      </b-col>
                      <b-col class="px-2">
                        <label class="input-custom w-100"
                          ><b-form-input
                            type="text"
                            placeholder="직급명"
                            v-model="item.position"
                            class="mt-0"
                          ></b-form-input
                        ></label>
                      </b-col>
                      <b-col class="px-2">
                        <label class="input-custom w-100"
                          ><b-select v-model="item.period" class="mt-0">
                            <b-form-select-option :value="null"
                              >기간</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(option, l) in $types.period"
                              :value="option.value"
                              :key="l"
                              >{{ option.name }}</b-form-select-option
                            >
                          </b-select></label
                        >
                      </b-col>
                      <b-col cols="1" class="px-2">
                        <b-btn
                          variant="text"
                          class="border rounded w-100 d-block"
                          :style="{ height: '48px' }"
                          @click="removeCareer(i)"
                          >-</b-btn
                        >
                      </b-col>
                    </b-row>
                    <b-card class="d-md-none mb-3">
                      <b-card-text class="p-4">
                        <label class="input-custom w-100">
                          <b-form-input
                            type="text"
                            placeholder="소속명"
                            class="mt-0"
                            v-model="item.name"
                          />
                        </label>
                        <label class="input-custom w-100"
                          ><b-select v-model="item.type" class="mt-0">
                            <b-form-select-option
                              v-for="(option, l) in $types.insType"
                              :value="option.value"
                              :key="l"
                              >{{ option.name }}</b-form-select-option
                            >
                          </b-select></label
                        >
                        <label class="input-custom w-100"
                          ><b-form-input
                            type="text"
                            placeholder="직급명"
                            v-model="item.position"
                            class="mt-0"
                          ></b-form-input
                        ></label>
                        <label class="input-custom w-100"
                          ><b-select v-model="item.period" class="mt-0">
                            <b-form-select-option :value="null"
                              >기간</b-form-select-option
                            >
                            <b-form-select-option
                              v-for="(option, l) in $types.period"
                              :value="option.value"
                              :key="l"
                              >{{ option.name }}</b-form-select-option
                            >
                          </b-select></label
                        >
                        <div class="d-flex justify-content-end">
                          <b-btn
                            variant="text"
                            class="border rounded px-4"
                            :style="{ height: '48px' }"
                            @click="removeCareer(i)"
                            >-</b-btn
                          >
                        </div>
                      </b-card-text>
                    </b-card>
                  </div>
                </template>
                <b-btn
                  v-if="input.info.careers.length < careersMax"
                  @click="addCareer"
                  variant="text border border-1 rounded w-100"
                  :style="{ minWidth: '48px', height: '48px' }"
                  >+</b-btn
                >
              </div>
              <label class="input-custom w-100 mb-0">
                자기소개
                <b-textarea
                  :rows="5"
                  class="w-100 h-auto"
                  v-model="input.info.content"
                />
                <div class="pt-2 text-right text-primary">
                  * 모든 정보는 비공개 처리되며 최종단계에서 선택한 고객님에게
                  필수 정보만 공개합니다 .
                </div>
              </label>
            </template>

            <div class="mt-5 d-flex justify-content-end">
              <b-btn variant="link" class="p-4 text-dark" @click="step--"
                >이전</b-btn
              >
              <b-btn
                variant="primary"
                class="p-4"
                @click="saveInfo"
                v-if="userType === 'PLANNER'"
              >
                {{ "법인 소개받기" }}
              </b-btn>
              <b-btn variant="primary" class="p-4" @click="request" v-else>
                {{ "저장" }}
              </b-btn>
            </div>
          </div>
          <div class="pb-5" v-else>
            <label>GA</label>
            <button-selector v-model="input.info.insType" :items="$types.ga" />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'ga-custom'"
                  @input="input.info.insType = 'ga-custom'"
                  text="직접입력"
                  value="ga-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'ga-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>
            <label>손해보험</label>
            <button-selector
              v-model="input.info.insType"
              :items="$types.sonhae"
            />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'sonhae-custom'"
                  @input="input.info.insType = 'sonhae-custom'"
                  text="직접입력"
                  value="sonhae-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'sonhae-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>
            <label>생명보험</label>
            <button-selector
              v-model="input.info.insType"
              :items="$types.life"
            />
            <b-row class="mx-n2 mb-4">
              <b-col v-bind="cols" :class="`p-2 text-nowrap`">
                <button-checkable
                  :checked="input.info.insType === 'life-custom'"
                  @input="input.info.insType = 'life-custom'"
                  text="직접입력"
                  value="life-custom"
                />
              </b-col>
              <b-col
                :class="`p-2 text-nowrap`"
                v-if="input.info.insType === 'life-custom'"
              >
                <label class="input-custom w-100 mb-0">
                  <b-form-input
                    type="text"
                    class="m-0"
                    v-model="input.info.customText"
                    @input="$emit('input', $event)"
                  />
                </label>
              </b-col>
            </b-row>

            <label class="input-custom w-100 mb-4">
              규모
              <b-select v-model="input.info.size">
                <b-form-select-option
                  v-for="(size, i) in $types.size"
                  :key="i"
                  :value="size.value"
                  >{{ size.name }}</b-form-select-option
                >
              </b-select>
            </label>

            <label class="input-custom w-100 mb-4">
              대표(지점장) 소개
              <b-textarea
                :rows="5"
                class="w-100 h-auto"
                v-model="input.info.introCeo"
              />
            </label>
            <label class="input-custom w-100 mb-4">
              법인 및 지점(사) 소개
              <b-textarea
                :rows="5"
                class="w-100 h-auto"
                v-model="input.info.introCompany"
              />
            </label>
            <label class="input-custom w-100 mb-4">
              근무지 혜택
              <b-textarea
                :rows="5"
                class="w-100 h-auto"
                v-model="input.info.benefit"
              />
            </label>

            <div class="mt-5 d-flex justify-content-end">
              <b-btn variant="link" class="p-4 text-dark" @click="step--"
                >이전</b-btn
              >
              <b-btn variant="primary" class="p-4" @click="saveInfo">
                저장
              </b-btn>
            </div>
          </div>
        </b-collapse>
        <template v-if="userType === 'PLANNER'">
          <header
            class="form-header text-lg-20 fw-700 mb-4"
            :class="{ active: 2 <= step }"
          >
            보험사 선택
          </header>
          <b-collapse :visible="step === 2">
            <div class="pb-5">
              <label>가입하고 싶은 보험사(중복 선택 가능)</label>
              <button-checkable
                @click.native="checkAll('ga')"
                :checked="allGa"
                class="pr-4 mb-2"
                :block="false"
                text="GA 전체 (모든 손해보험, 생명보험을 취급하는 곳)"
              />
              <div class="border-bottom mb-3">
                <button-selector
                  multiple
                  v-model="wish.ga"
                  :items="ga"
                  class="mb-2"
                />
              </div>
              <b-row class="mx-n2">
                <b-col class="px-2" cols="6" sm="4" md="3">
                  <button-checkable
                    @click.native="checkAll('sonhae')"
                    :checked="allSonhae"
                    block
                    text="손해보험 전체"
                    class="mb-2"
                  />
                </b-col>
              </b-row>
              <div class="border-bottom mb-3">
                <button-selector
                  multiple
                  v-model="wish.sonhae"
                  :items="$types.sonhae"
                  class="mb-2"
                />
              </div>
              <b-row class="mx-n2">
                <b-col class="px-2" cols="6" sm="4" md="3">
                  <button-checkable
                    @click.native="checkAll('life')"
                    :checked="allLife"
                    block
                    text="생명보험 전체"
                    class="mb-2"
                  />
                </b-col>
              </b-row>
              <button-selector
                multiple
                v-model="wish.life"
                :items="$types.life"
                class="mb-4"
              />
              <label class="input-custom w-100 mb-3">
                희망 규모
                <b-select v-model="wish.size">
                  <b-form-select-option
                    v-for="(size, i) in $types.size"
                    :key="i"
                    :value="size.value"
                  >
                    {{ size.name }}
                  </b-form-select-option>
                </b-select>
              </label>

              <div class="d-flex justify-content-end align-items-center mt-5">
                <b-btn class="text-dark" variant="link" @click="step--">
                  이전
                </b-btn>
                <b-btn class="py-3 px-4" variant="primary" @click="reqCompany">
                  다음
                </b-btn>
              </div>
            </div>
          </b-collapse>

          <header
            class="form-header text-lg-20 fw-700 mb-4"
            :class="{ active: 3 <= step }"
          >
            제안받고 싶은 대리점 수
          </header>
          <b-collapse :visible="step === 3">
            <label class="input-custom w-100 mb-3">
              대리점 수
              <b-select v-model="wish.count">
                <b-form-select-option :value="null"
                  >인원수를 선택하세요</b-form-select-option
                >
                <b-form-select-option
                  v-for="i in [5, 10, 15, 20]"
                  :key="i"
                  :value="i"
                >
                  {{ i }}개
                </b-form-select-option>
              </b-select>
            </label>
            <div class="mt-5">
              <b-btn variant="primary" block class="p-4" @click="request">
                법인 비교 요청하기
              </b-btn>
              <b-btn variant="link" block class="text-dark" @click="step--"
                >이전</b-btn
              >
            </div>
          </b-collapse>
          <payment :ok="signIn" />
          <payment-complete :ok="signInComplete" />
        </template>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
import ButtonSelector from "@/components/ButtonSelector";
import Modals from "@/components/Modals/index";
import regex from "@/lib/regex";

export default {
  components: { ButtonSelector, ButtonCheckable, ...Modals },
  props: {
    userType: { type: String },
    options: { type: Object, default: { useRecommend: false } },
  },
  data() {
    const isProduction = true;
    // const isProduction = process.env.NODE_ENV === "production";
    return {
      valid: false,
      step: 0,
      salt: 0,
      url: null,
      input: {
        username: isProduction ? null : "whaledoll",
        password: isProduction ? null : "password1!",
        password2: isProduction ? null : "password1!",
        name: isProduction ? null : "윤이건",
        birth: isProduction ? null : "920617",
        gender: isProduction ? null : 3,
        addr: isProduction ? null : "경기도 성남시 중원구 성남대로 1000",
        email: isProduction ? null : "whaledoll@naver.com",
        accessionNumber: null,
        thumbnail: null,
        info: {
          addr: "",
          email: "",
          size: 0,
          careers: [{ name: "", type: null, position: "", period: null }],
        },
      },
      wish: {
        insurance: isProduction ? [] : [1],
        age: null,
        career: null,
        area: null,
        content: null,
        count: 10,
        size: 0,
        ga: [],
        sonhae: [],
        life: [],
      },
      company: {
        value: null,
      },
      ga: [],
      gender: [],
      age: [],
      career: [],
      area: [],
      sonhae: [],
      life: [],
      cols: {
        cols: 6,
        sm: 4,
        md: 4,
        lg: 3,
      },
      careersMax: 5,
    };
  },
  watch: {
    "input.insType"(n) {
      this.input.customText = "";
    },
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    allSonhae() {
      return this.wish.sonhae && this.wish.sonhae.length === this.sonhae.length;
    },
    allLife() {
      return this.wish.life && this.wish.life.length === this.life.length;
    },
    allGa() {
      return this.wish.ga && this.wish.ga.length === this.ga.length;
    },
    isGA() {
      return this.allSonhae && this.allLife;
    },
  },
  async mounted() {
    const user = await this.getMe();
    this.input = { ...this.input, ...user };
    this.url = this.s3 + this.input.thumbnail;

    this.gender = this.setType(this.$types.gender);
    this.age = this.setType(this.$types.age);
    this.career = this.setType(this.$types.career);
    this.area = this.setType(this.$types.area);
    this.ga = this.setType(this.$types.ga);
    this.life = this.setType(this.$types.life);
    this.sonhae = this.setType(this.$types.sonhae);
  },
  methods: {
    signIn() {
      this.$bvModal.show("modal-payment-complete");
    },
    signInComplete() {
      this.$router.push("/");
    },
    toggleAll(type, val) {
      this.wish[type] = val ? [] : this[type].map(({ value }) => value);
    },
    checkAll(type) {
      console.log("checkAll", type);
      let val;
      switch (type) {
        case "ga":
          val = this.allGa;
          this.toggleAll(type, val);
          break;
        case "sonhae":
          val = this.allSonhae;
          this.toggleAll(type, val);
          break;
        case "life":
          val = this.allLife;
          this.toggleAll(type, val);
          break;
      }
    },
    async addCareer() {
      if (this.input.info.careers.length >= this.careersMax)
        return await alert(
          `최대 ${this.careersMax}개의 경력만 입력 가능합니다.`
        );
      this.input.info.careers.push({
        name: "",
        type: null,
        position: "",
        period: null,
      });
    },
    async removeCareer(i) {
      if (this.input.careers.length <= 1)
        return await alert("최소 1개의 경력이 필요합니다.");
      this.input.careers.splice(i, 1);
    },
    async getTypes() {
      const types = ["area", "company-ga", "company-life", "company-sonhae"];
      return await Promise.all(
        types.map((type) => this.$axios.get(`/types?category=${type}`))
      );
    },
    async submit() {
      try {
        const userType = this.userType;
        const { data } = await this.$axios.post("/account/register", {
          ...this.input,
          userType,
        });
        if (data.success) {
          localStorage.setItem("insTalk__token", data.token);
          this.$store.commit("set", ["user", data.user]);
          this.getMe();
          await alert(
            "회원가입이 완료되었습니다.<br/>다음 과정을 진행해주세요."
          );
          this.step++;
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    async saveInfo() {
      try {
        const { data } = await this.$axios.patch("/account/info", {
          ...this.input.info,
        });
        if (data.success) {
          this.getMe();
          if (this.userType === "PLANNER") {
            this.step++;
          } else {
            await alert("상세 정보가 저장되었습니다.");
            this.$router.push("/mypage/request");
          }
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    async fileControl(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("imgs", file);
      try {
        const { data } = await this.$axios.post("/files", formData);
        if (data.success) {
          this.input.thumbnail = data.data.key;
        }
      } catch (error) {}
    },
    async request() {
      try {
        console.log("request", this.wish);
        const { data } = await this.$axios.post("/compares", this.wish);
        if (data.success) {
          await alert(data.msg);
          this.$router.push("/mypage/compare");
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    reqCompany() {
      const { ga, sonhae, life } = this.wish;
      const ins = [...ga, ...sonhae, ...life];
      if (!ins.length) return alert("가입하고 싶은 보험사를 선택해주세요");
      this.step++;
    },
  },
};
</script>

<style lang="scss" scoped></style>
