<template>
  <div class="table-wrap">
    <table class="w-100">
      <tr v-for="item in items" :key="item.id">
        <td class="py-3 pl-3 pr-4 text-nowrap">
          {{ mapType(item) }}
        </td>
        <td class="py-3 w-100">
          <h6 class="fw-700 text-14 text-lg-16 text-truncate mb-0">
            {{ item.name }}
          </h6>
          <span class="fw-300 text-12 text-lg-14"
            >{{ toLocaleDateTime(item.createdAt) }}
          </span>
        </td>
        <td class="py-3 px-3 text-nowrap text-right">
          <i class="icon icon-mileage icon-20 mb-n1 mr-1"></i>
          <span>{{ item.mileage && item.mileage.toLocaleString() }}</span>
          <i class="icon icon-cash icon-20 mb-n1 mr-1 ml-4"></i>
          <span>{{ item.cash && item.cash.toLocaleString() }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },
  methods: {
    mapType(item) {
      // @ CHR 충전
      // @ USE 사용
      // @ DRW 인출
      // @ EXP 만료는 get할때 충전/적립 수정해서 보냄
      // @ RWD 적립
      const { type, expiry } = item;
      if (["RWD", "CHR"].includes(type) && expiry) item.type = "EXP";
      return {
        CHR: "충전",
        USE: "사용",
        DRW: "인출",
        EXP: "만료",
        RWD: "적립",
      }[type];
    },
  },
};
</script>

<style lang="scss" scoped></style>
