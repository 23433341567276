<template>
  <b-row :class="`mx-n${space}`" v-if="items">
    <b-col
      :class="`p-${space} text-nowrap`"
      v-for="item in items"
      :key="item.value"
      v-bind="cols"
    >
      <button-checkable
        :text="item[keyName]"
        :value="item[keyValue]"
        @input="clicked"
        :checked="check(item)"
      />
    </b-col>
    <b-col :class="`p-${space} text-nowrap`" v-bind="cols" v-if="addNull">
      <button-checkable
        text="상관 없음"
        :value="null"
        @input="clicked"
        :checked="check({ value: null })"
      />
    </b-col>
  </b-row>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
export default {
  components: {
    ButtonCheckable,
  },
  computed: {
    cols() {
      return (
        this.colsOptions || {
          cols: 6,
          sm: 4,
          md: 4,
          lg: 3,
        }
      );
    },
  },
  props: {
    addNull: { type: Boolean },
    space: { default: 2, type: Number },
    multiple: { default: false, type: Boolean },
    value: { default: null },
    keyValue: { type: String, default: "value" },
    keyName: { type: String, default: "name" },
    items: { type: Array },
    colsOptions: Object,
  },
  mounted() {
    this.$emit("input", this.multiple ? [] : this.value || null);
    this.items && this.items.map((item) => console.log(item.value));
  },
  methods: {
    clicked(v) {
      if (this.multiple) {
        this.value = this.value || [];
        const value = [...this.value];
        const idx = value.findIndex((val) => val === v);
        if (idx > -1) {
          value.splice(idx, 1);
        } else {
          value.push(v);
        }
        value.sort((a, b) => a - b);
        this.$emit("input", value);
      } else {
        this.$emit("input", v);
      }
    },
    check({ value }) {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          return this.value.includes(value);
        } else {
          return false;
        }
      } else {
        return this.value === value;
      }
    },
  },
};
</script>

<style></style>
