<template>
  <div>
    <b-collapse
      v-for="item in terms"
      :key="item.id"
      :id="'collapse-' + item.id"
      :visible="+query.category === item.category"
      accordion="terms-category"
    >
      <h6 class="fw-700">{{ item.title }}</h6>
      <p class="py-4 text-dark" v-html="item.content" />
    </b-collapse>
  </div>
</template>

<script>
import CategoryTab from "../../components/CategoryTab.vue";
export default {
  components: { CategoryTab },
  data() {
    return {
      terms: [],
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  async mounted() {
    await this.getItems();
    if (!this.query.category)
      this.$router.replace({
        path: this.$route.path,
        query: { category: this.terms[0].category },
      });
  },
  methods: {
    async getItems() {
      const { data } = await this.$axios.get("/terms/user");
      this.terms = data.data;
      const tabs = data.data.map((item) => item.relativeCategory);
      this.categories = tabs;
      this.$emit("set-category", tabs);
    },
  },
};
</script>

<style lang="scss" scoped></style>
