<template>
  <div>
    <b-row>
      <b-col cols="12" md="4" v-for="(item, i) in items" :key="i">
        <event-item v-bind="item" />
      </b-col>
    </b-row>
    <pagination-custom
      v-if="pagination.totalDocs"
      :value="+params.page"
      :total-rows="+pagination.totalDocs"
      :per-page="+pagination.limit"
      :page-click="search"
    />
  </div>
</template>

<script>
import EventItem from "@/components/EventItem";

export default {
  components: {
    EventItem,
  },
  data() {
    return {
      perPage: 9,
      nextQuery: {
        page: undefined,
      },
      items: [],
      pagination: {},
    };
  },
  computed: {
    params() {
      const val = this.$route.query;
      val.page = val.page || 1;
      return val;
    },
  },
  watch: {
    async params(n) {
      this.items = await this.getItems(n);
    },
  },
  async mounted() {
    this.queryInit({ type: null, result: null });
    this.items = await this.getItems(this.params);
    console.log(this.items);
  },
  methods: {
    async getItems(params = {}) {
      const options = { ...params, limit: this.perPage };

      const { data } = await this.$axios.get("/events", { params: options });
      const { limit, page, totalDocs } = data.data;
      this.pagination = { limit, page, totalDocs };

      return data.data.docs;
    },
  },
};
</script>
