<template>
  <b-modal
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="캐시 인출"
    title-class="h2 pt-5"
    body-class="px-5"
    id="modal-withdraw-cash"
    ok-variant="primary"
    ok-only
    @show="getMe"
  >
    <div class="px-5">
      <b-row align-v="center" class="mb-4">
        <b-col cols="12" md="4" class="fw-500 text-14">
          인출 가능 캐시
        </b-col>
        <b-col cols="12" md="8" class="fw-700 text-right">
          <i class="icon icon-cash icon-18 mb-n1 mr-1"></i>
          {{ user.cash && user.cash.toLocaleString() }}
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-4">
        <b-col cols="12" md="4" class="fw-500 text-14">
          인출할 캐시
        </b-col>
        <b-col cols="12" md="8" class="d-flex align-items-center">
          <b-input-group>
            <template #prepend>
              <b-input-group-text
                class="p-0 bg-transparent rounded-left"
                :style="{ borderRight: '0' }"
              >
                <i class="icon icon-cash icon-18 ml-2"></i>
              </b-input-group-text>
            </template>
            <template #append>
              <b-input-group-text
                class="p-0 bg-transparent rounded-right"
                :style="{ borderLeft: '0' }"
              >
                <span class="mr-2 text-14">천원</span>
              </b-input-group-text>
            </template>
            <b-form-input
              @input.native="$inputNumber($event, 'cash', 0, maxCash, true)"
              v-model="input.cash"
              :style="{ borderLeft: 0, borderRight: 0 }"
            ></b-form-input>
          </b-input-group>
          <b-btn
            variant="primary"
            @click="input.cash = maxCash"
            class="ml-2 py-0 px-3"
            :style="{ height: '38px' }"
          >
            전액신청
          </b-btn>
        </b-col>
      </b-row>
      <div class="py-4 border-top border-bottom border-light">
        <b-row align-v="center">
          <b-col cols="12" md="4" class="fw-500 text-14">
            입금 은행
          </b-col>
          <b-col cols="12" md="8">
            <label class="input-custom w-100">
              <b-form-input
                type="text"
                placeholder="은행명"
                v-model="input.bankName"
                class="mt-0"
              ></b-form-input>
            </label>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="12" md="4" class="fw-500 text-14">
            입금 계좌
          </b-col>
          <b-col cols="12" md="8">
            <label class="input-custom w-100">
              <b-form-input
                type="text"
                placeholder="입금 계좌"
                class="mt-0"
                v-model="input.account"
              ></b-form-input>
            </label>
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col cols="12" md="4" class="fw-500 text-14">
            예금주
          </b-col>
          <b-col cols="12" md="8">
            <label class="input-custom w-100">
              <b-form-input
                type="text"
                placeholder="예금주"
                class="mt-0"
                v-model="input.accountName"
              ></b-form-input>
            </label>
          </b-col>
        </b-row>
      </div>
      <div class="my-4">
        <div class="text-14 fw-500">
          인출 신청 안내
        </div>
        <ul class="pl-3  text-dark text-14" :style="{ listStyle: 'disc' }">
          <li class="">
            인출 시 등록하신 계좌로 입금됩니다.
          </li>
          <li class="">
            캐시는 1,000원 이상부터 1,000원 단위로 인출하실 수 있습니다.
          </li>
          <li class="">
            잔액이 1,000원 미만인 경우에는 전액 인출이 안됩니다.
          </li>
          <li class="">
            본인명의의 계좌가 아닌 경우 인출이 불가합니다.
          </li>
        </ul>
      </div>
    </div>
    <template #modal-footer>
      <div class="mt-5 mx-auto text-center w-100">
        <b-btn
          variant="primary"
          class="w-100"
          @click="withdrawCash"
          :disabled="!valid.state"
        >
          {{
            valid.state
              ? input.cash.toLocaleString() + ",000 캐시 인출 신청"
              : valid.message
          }}
        </b-btn>
      </div>
    </template>
  </b-modal>
</template>

<script>
import CategoryTab from "@/components/CategoryTab.vue";
export default {
  components: { CategoryTab },
  data() {
    return {
      input: {
        cash: 0,
        bankName: "",
        account: "",
        accountName: "",
      },
      bank: null,
      selected: null,
    };
  },
  computed: {
    maxCash() {
      return Math.floor(this.user.cash / 1000);
    },
    rules() {
      const { cash, bankName, account, accountName } = this.input;
      return [
        () => !!cash || "인출할 금액을 입력해주세요.",
        () => !!bankName || "은행명을 입력해주세요.",
        () => !!account || "계좌번호를 입력해주세요.",
        () => !!accountName || "예금주를 입력해주세요.",
      ];
    },
    valid() {
      let valid = { state: null, message: "" };
      const val = this.value;
      if (val === null) return { state: null, message: "" };
      valid = this.rules.reduce((result, rule) => {
        if (val === null) {
          return;
        }
        if (result.state !== false) {
          const ruleResult = rule(val);
          console.log(ruleResult);
          result =
            typeof ruleResult === "string"
              ? {
                  state: false,
                  message: ruleResult,
                }
              : {
                  state: true,
                  message: null,
                };
        }
        return result;
      }, valid);

      return valid;
    },
  },
  methods: {
    async withdrawCash() {
      try {
        const { data } = await this.$axios.patch("/points", this.input);
        if (data.success) {
          this.input = {
            cash: 0,
            bankName: "",
            account: "",
            accountName: "",
          };
          this.$bvModal.hide("modal-withdraw-cash");
          this.$emit("hide", true);
        }
      } catch (error) {}
    },
  },
};
</script>

<style></style>
