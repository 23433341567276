<template>
  <div>
    <b-form @submit.prevent.stop="submit">
      <b-row align-h="center" v-if="!input.imp_uid">
        <b-col cols="12" md="8" class="mb-3">
          <label class="input-custom w-100">
            휴대폰 번호 (아이디)
            <w-input
              type="tel"
              maxlength="11"
              placeholder="아이디를 입력하세요."
              v-model="input.username"
              name="username"
              :rules="{
                required: true,
                min: 10,
                max: 11,
                regex: /^[0-9].*$/,
              }"
              @input.native="$numberOnly($event, 'username', 11)"
            />
          </label>
        </b-col>
        <b-col cols="12" md="8" class="mb-3">
          <label class="input-custom w-100">
            이름
            <w-input
              type="text"
              name="displayname"
              placeholder="이름을 입력하세요."
              v-model="input.name"
              :rules="{ required: true, regex: /^[가-힣].*$/ }"
            />
          </label>
        </b-col>
      </b-row>
      <b-row align-h="center" v-else>
        <b-col cols="12" md="8" class="mb-3">
          <label class="input-custom w-100">
            비밀번호
            <w-input
              type="password"
              name="passwordRetype"
              placeholder="비밀번호를 입력하세요."
              v-model="input.passwordRetype"
              rules="required|passwordStartWith|min:8|passwordCustom"
            />
          </label>
        </b-col>
        <b-col cols="12" md="8" class="mb-3">
          <label class="input-custom w-100">
            비밀번호 확인
            <w-input
              type="password"
              name="password"
              placeholder="비밀번호를 입력하세요."
              v-model="input.password"
              :rules="{
                required: true,
                min: 8,
                passwordCustom: true,
                is: input.passwordRetype,
              }"
            />
          </label>
        </b-col>
      </b-row>
      <b-row align-h="center" class="my-5 pt-md-5">
        <b-col cols="12" md="4" class="mb-3 text-center">
          <b-btn
            variant="primary"
            class="w-100 py-3"
            @click="submit"
            v-if="!input.imp_uid"
          >
            비밀번호 찾기
          </b-btn>
          <b-btn variant="primary" class="w-100 py-3" @click="reset" v-else>
            비밀번호 변경
          </b-btn>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: {
        username: null,
        name: null,
      },
    };
  },
  methods: {
    async submit() {
      try {
        const { data } = await this.$axios.post(
          "/account/validate-user",
          this.input
        );

        if (!data) return;
        IMP.certification(
          {
            // param
            merchant_uid: `auth-${new Date().getTime()}`, // 주문 번호
            m_redirect_url: `${location.origin}/account/find-password`, // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
            popup: false, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
          },
          async (rsp) => {
            if (rsp.success) {
              /**
                error_code: null
                error_msg: null
                imp_uid: "imp_960419877883"
                merchant_uid: "auth-1637776419828"
                pg_provider: "danal"
                pg_type: "certification"
                success: true
              */
              const {
                data: { data, success },
              } = await this.$axios.post("/account/find-password", {
                ...rsp,
                ...this.input,
                // error_code: null,
                // error_msg: null,
                // imp_uid: "imp_960419877883",
                // merchant_uid: "auth-1637776419828",
                // pg_provider: "danal",
                // pg_type: "certification",
                // success: true,
              });
              // imp_uid 추가해서

              if (data.name === this.input.name && success) {
                this.input = { ...this.input, imp_uid: data.imp_uid };
              } else {
                alert("사용자 인증에 실패했습니다.");
              }
            } else {
              // 인증 실패 시 로직,
              await alert("인증 실패 : " + rsp.error_msg);
            }
          }
        );
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    async reset() {
      try {
        const { data } = await this.$axios.patch(
          "/account/reset-password",
          this.input
        );
        await alert("비밀번호 변경이 완료되었습니다.");
        this.$router.go(-1);
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
