<template>
  <b-modal
    centered
    size="xl"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 pb-5 px-5"
    title="본인 소개 편집"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-edit-introduce"
    ok-title="수정"
    ok-variant="primary"
    cancel-title="취소"
    cancel-variant="light"
    @ok.prevent="submit"
    @show="shown"
  >
    <div class="pb-5 px-4">
      <header class="text-lg-20 fw-700 mb-4">
        소속
      </header>
      <label>GA</label>
      <button-selector v-model="input.insType" :items="$types.ga" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'ga-custom'"
            @input="input.insType = 'ga-custom'"
            text="직접입력"
            value="ga-custom"
          />
        </b-col>
        <b-col :class="`p-2 text-nowrap`" v-if="input.insType === 'ga-custom'">
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label>손해보험</label>
      <button-selector v-model="input.insType" :items="$types.sonhae" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'sonhae-custom'"
            @input="input.insType = 'sonhae-custom'"
            text="직접입력"
            value="sonhae-custom"
          />
        </b-col>
        <b-col
          :class="`p-2 text-nowrap`"
          v-if="input.insType === 'sonhae-custom'"
        >
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label>생명보험</label>
      <button-selector v-model="input.insType" :items="$types.life" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'life-custom'"
            @input="input.insType = 'life-custom'"
            text="직접입력"
            value="life-custom"
          />
        </b-col>
        <b-col
          :class="`p-2 text-nowrap`"
          v-if="input.insType === 'life-custom'"
        >
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label class="input-custom w-100 mb-4">
        상세 소속
        <b-form-input
          type="text"
          placeholder="대리점명 혹은 지점명 입력하세요."
          v-model="input.branch"
        ></b-form-input>
      </label>
      <label>설계사 활동 지역</label>
      <button-selector
        multiple
        v-model="input.area"
        :items="$types.area"
        class="mb-5"
      />
      <div class="my-4">
        <label class="input-custom w-100 mb-4">
          경력사항
        </label>

        <template v-for="(item, i) in input.careers">
          <div :key="i">
            <b-row class="mx-n2 mb-2 d-none d-md-flex">
              <b-col class="px-2">
                <label class="input-custom w-100">
                  <b-form-input
                    type="text"
                    placeholder="소속명"
                    class="mt-0"
                    v-model="item.name"
                  ></b-form-input>
                </label>
              </b-col>
              <b-col class="px-2">
                <label class="input-custom w-100"
                  ><b-select v-model="item.type" class="mt-0">
                    <b-form-select-option
                      v-for="(option, l) in $types.insType"
                      :value="option.value"
                      :key="l"
                      >{{ option.name }}</b-form-select-option
                    >
                  </b-select></label
                >
              </b-col>
              <b-col class="px-2">
                <label class="input-custom w-100"
                  ><b-form-input
                    type="text"
                    placeholder="직급명"
                    v-model="item.position"
                    class="mt-0"
                  ></b-form-input
                ></label>
              </b-col>
              <b-col class="px-2">
                <label class="input-custom w-100"
                  ><b-select v-model="item.period" class="mt-0">
                    <b-form-select-option :value="null" disabled
                      >기간</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="(option, l) in $types.period"
                      :value="option.value"
                      :key="l"
                      >{{ option.name }}</b-form-select-option
                    >
                  </b-select></label
                >
              </b-col>
              <b-col cols="1" class="px-2">
                <b-btn
                  variant="text"
                  class="border rounded w-100 d-block"
                  :style="{ height: '48px' }"
                  @click="removeCareer(i)"
                  >-</b-btn
                >
              </b-col>
            </b-row>
            <b-card class="d-md-none mb-3">
              <b-card-text class="p-4">
                <label class="input-custom w-100">
                  <b-form-input
                    type="text"
                    placeholder="소속명"
                    class="mt-0"
                    v-model="item.name"
                  ></b-form-input>
                </label>
                <label class="input-custom w-100"
                  ><b-select v-model="item.type" class="mt-0">
                    <b-form-select-option
                      v-for="(option, l) in $types.insType"
                      :value="option.value"
                      :key="l"
                      >{{ option.name }}</b-form-select-option
                    >
                  </b-select></label
                >
                <label class="input-custom w-100"
                  ><b-form-input
                    type="text"
                    placeholder="직급명"
                    v-model="item.position"
                    class="mt-0"
                  ></b-form-input
                ></label>
                <label class="input-custom w-100"
                  ><b-select v-model="item.period" class="mt-0">
                    <b-form-select-option :value="null"
                      >기간</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="(option, l) in $types.period"
                      :value="option.value"
                      :key="l"
                      >{{ option.name }}</b-form-select-option
                    >
                  </b-select></label
                >
                <div class="d-flex justify-content-end">
                  <b-btn
                    variant="text"
                    class="border rounded px-4"
                    :style="{ height: '48px' }"
                    @click="removeCareer(i)"
                    >-</b-btn
                  >
                </div>
              </b-card-text>
            </b-card>
          </div>
        </template>
        <b-btn
          @click="addCareer"
          variant="text border border-1 rounded w-100"
          :style="{ minWidth: '48px', height: '48px' }"
          >+</b-btn
        >
      </div>
      <label class="input-custom w-100 mb-4">
        자기소개
        <b-textarea :rows="5" class="w-100 h-auto" v-model="input.content" />
        <div class="pt-2 text-right text-primary">
          * 모든 정보는 비공개 처리되며 최종단계에서 선택한 고객님에게 필수
          정보만 공개합니다.
        </div>
      </label>
    </div>
  </b-modal>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
import ButtonSelector from "@/components/ButtonSelector";
export default {
  components: { ButtonSelector, ButtonCheckable },
  data() {
    const isProduction = process.env.NODE_ENV === "production";
    return {
      input: {
        careers: [{}],
      },
      ga: [],
      area: [],
      sonhae: [],
      life: [],
      period: [],
      cols: {
        cols: 6,
        sm: 4,
        md: 3,
      },
    };
  },
  watch: {
    "input.insType"(n) {
      this.input.customText = "";
    },
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    title() {
      const arr = [
        "회원가입 및 법인 대리점/원수사 지점(사) 소개받기",
        "회원가입 및 법인 대리점/원수사 지점(사) 소개받기",
        "법인 대리점 및 원수사 지점(사) 회원가입",
      ];
      return arr[this.type];
    },
    nonLifeInsurance() {
      return _insurance.find((r) => r.type === "손해보험").items;
    },
    lifeInsurance() {
      return _insurance.find((r) => r.type === "생명보험").items;
    },
  },
  methods: {
    signIn() {
      this.$bvModal.show("modal-payment-complete");
    },
    signInComplete() {
      this.$router.push("/");
    },
    async shown() {
      const getPeriod = (max) => {
        const arr = [];
        for (let i = 1; i <= max; i++) {
          arr.push(i);
        }
        return arr.map((val) => ({ text: `${val}년`, value: val }));
      };
      const user = await this.getMe();
      this.input = { ...this.input, ...user.info };
      this.period = getPeriod(10);
      console.log(this.period);
    },
    async addCareer() {
      if (this.input.careers.length >= this.careersMax)
        return await alert(
          `최대 ${this.careersMax}개의 경력만 입력 가능합니다.`
        );
      this.input.careers.push({
        name: "",
        type: null,
        position: "",
        period: null,
      });
    },
    async submit() {
      try {
        const { data } = await this.$axios.patch("/account/info", this.input);
        this.getMe();
        if (data.success) this.$bvModal.hide("modal-edit-introduce");
      } catch (error) {}
    },
    async removeCareer(i) {
      if (this.input.careers.length <= 1)
        return await alert("최소 1개의 경력이 필요합니다.");
      this.input.careers.splice(i, 1);
    },
  },
};
</script>

<style></style>
