<template>
  <b-modal
    centered
    size="lg"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="보험 비교 요청서"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-request"
    ok-title="확인"
    ok-variant="primary"
    ok-only
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <b-row align-h="center" class="mb-5">
      <b-col cols="8" class="border px-md-5">
        <table class="w-100 my-4 text-14">
          <tbody>
            <tr>
              <td class="py-1 fw-700 px-3">이름</td>
              <td class="py-1 px-3">홍길동</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">주민번호</td>
              <td class="py-1 px-3">
                {{ 940615 }} -
                {{ 1 }}
                <!-- 검은 원 utf-8 유니코드 -->
                &#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
              </td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">휴대폰번호</td>
              <td class="py-1 px-3">010 - 0000 - 0000</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">지역</td>
              <td class="py-1 px-3">서울, 경기도</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">가입하고 싶은 보험 종목</td>
              <td class="py-1 px-3">종합보험, 종신보험</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {};
</script>

<style></style>
