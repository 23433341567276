<template>
  <b-modal
    :visible="!!item"
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-4 mx-md-5 pb-md-4 mb-5 justify-content-center"
    :title="getTitle()"
    title-class="h2 pt-4"
    body-class="px-4"
    id="modal-view-request"
    ok-title="확인"
    ok-variant="primary"
    ok-only
    @hide="$emit('hide')"
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <b-row align-h="center" class="mb-5" v-if="item">
      <b-col cols="8" class="border px-md-4">
        <table class="w-100 my-4 text-14">
          <tbody>
            <tr>
              <td class="py-2 fw-700 px-3">이름</td>
              <td class="py-2 px-3 text-nowrap">
                {{ item.relativeAuthor.name }}
              </td>
            </tr>
            <tr>
              <td class="py-2 fw-700 px-3">인적사항</td>
              <td class="py-2 px-3 text-nowrap">
                {{ item.relativeAuthor.gender % 2 ? "남성" : "여성" }},
                {{ getAge(item.relativeAuthor.birth) }}세
              </td>
            </tr>
            <tr v-if="item.state === '승인완료'">
              <td class="py-2 fw-700 px-3">주민번호</td>
              <td class="py-2 px-3 text-nowrap">
                {{ item.relativeAuthor.birth }} -
                {{ item.relativeAuthor.gender }}
                <!-- 검은 원 utf-8 유니코드 -->
                &#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
              </td>
            </tr>
            <tr v-if="item.state === '승인완료'">
              <td class="py-2 fw-700 px-3">휴대폰번호</td>
              <td class="py-2 px-3 text-nowrap">
                {{ item.relativeAuthor.username }}
              </td>
            </tr>
            <tr>
              <td class="py-2 fw-700 px-3">지역</td>
              <td class="py-2 px-3 text-nowrap">{{ item.area }}</td>
            </tr>
            <tr v-if="getTitle() === '보험 비교 요청서'">
              <td class="py-2 fw-700 px-3" :style="{ verticalAlign: 'top' }">
                가입하고싶은 보험종목
              </td>
              <td class="py-2 px-3" :style="{ verticalAlign: 'top' }">
                <div class="mx-n2">
                  <b-badge
                    variant="transparent"
                    class="fw-400 text-14 p-0 mx-2"
                    v-for="(ins, i) in item.insurance"
                    :key="i"
                  >
                    {{ ins }}
                  </b-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    getTitle() {
      if (!this.item) return null;
      const { userType } = this.user;
      const isMine = this.isMine(this.item.relativeAuthor);
      switch (userType) {
        case "CUSTOMER":
          return "보험 비교 요청서";
        case "PLANNER":
          return isMine ? "법인 비교 요청서" : "보험 비교 요청서";
        case "COMPANY":
          return "법인 비교 요청서";
      }
    },
  },
};
</script>

<style></style>
