<template>
  <w-form v-slot="{ invalid }">
    <b-modal
      centered
      size="lg"
      header-class="border-0 justify-content-center py-5"
      footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
      title="비밀번호 변경"
      title-class="h2 pt-5"
      body-class="px-4 px-md-4"
      id="modal-change-password"
      ok-title="확인"
      ok-variant="primary"
      cancel-title="취소"
      cancel-variant="light"
      :ok-disabled="invalid"
      @ok.prevent="submit"
    >
      <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
      <b-row align-h="center" class="px-5">
        <b-col cols="12" md="8" class="px-2 px-md-5">
          <label class="input-custom d-block mb-4">
            변경 비밀번호
            <w-input
              type="password"
              name="passwordRetype"
              placeholder="비밀번호를 입력하세요."
              v-model="input.originalPassword"
              rules="required|passwordStartWith|min:8|passwordCustom"
            />
          </label>
          <label class="input-custom d-block mb-4">
            변경 비밀번호
            <w-input
              type="password"
              name="passwordRetype"
              placeholder="비밀번호를 입력하세요."
              v-model="input.passwordRetype"
              rules="required|passwordStartWith|min:8|passwordCustom"
            />
          </label>
          <label class="input-custom d-block mb-4">
            변경 비밀번호 확인
            <w-input
              type="password"
              name="password"
              placeholder="비밀번호를 입력하세요."
              v-model="input.password"
              :rules="{
                required: true,
                min: 8,
                is: input.passwordRetype,
                passwordCustom: true,
              }"
            />
          </label>
        </b-col>
      </b-row>
    </b-modal>
  </w-form>
</template>

<script>
import regex from "@/lib/regex";
export default {
  data() {
    return {
      input: {
        password: null,
        newPassword: null,
        newPassword2: null,
      },
    };
  },
  watch: {
    valid(n) {
      console.log("------------------------------------");
      console.log(n);
      console.log("------------------------------------");
    },
  },
  computed: {
    valid() {
      const { password, newPassword, newPassword2 } = this.input;
      const result = {
        password: password && !!password.length,
        newPassword: newPassword && regex.password.test(newPassword),
        newPassword2: newPassword && newPassword === newPassword2,
      };

      return Object.values(result).some((val) => !val);
    },
  },
  methods: {
    async submit() {
      try {
        const { data } = await this.$axios.patch(
          "/account/password",
          this.input
        );
        if (data.success) {
          await alert("비밀번호가 변경되었습니다.");
          this.$bvModal.hide("modal-change-password");
        }
      } catch (error) {
        await alert(error.response.data.msg);
      }
    },
  },
};
</script>

<style></style>
