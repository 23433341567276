import Vue from "vue";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";

import ko from "vee-validate/dist/locale/ko.json";
import * as rules from "vee-validate/dist/rules";

/**
 * Rules Message Overwrite
 */

const customFieldMessages = {
  username: {
    required: "휴대폰 번호를 입력해주세요.",
    min: "휴대폰 번호를 확인해주세요.",
    max: "휴대폰 번호를 확인해주세요.",
    regex: "휴대폰 번호를 확인해주세요.",
  },
  password: {
    required: "비밀번호를 입력해주세요.",
    min: "비밀번호는 8 ~ 16자로 사용하세요.",
    max: "비밀번호는 8 ~ 16자로 사용하세요.",
    is: "비밀번호가 일치하지 않습니다.",
  },
  passwordRetype: {
    required: "비밀번호를 입력해주세요.",
    passwordStartWith: "첫 글자는 특수문자가 들어갈 수 없습니다.",
    min: "비밀번호는 8자 이상 입력해주세요.",
    passwordCustom:
      "영문 대/소문자, 숫자, 특수문자 중 3가지 이상을 사용하세요.",
  },
  birth: {
    required: "생년월일을 입력해주세요.",
    length: "생년월일은 6자로 입력해주세요.",
    birth: "잘못된 생년월일입니다.",
  },
  displayname: {
    required: "이름을 입력해주세요.",
    regex: "이름은 한글로 입력해주세요.",
  },
  branchName: {
    required: "법인 대리점 상호명을 입력해주세요.",
    regex: "법인 대리점 상호명은 한글로 입력해주세요.",
  },
  branchNumber: {
    regex: "법인 대리점 등록번호는 숫자만 입력해주세요.",
  },
  email: {
    required: "이메일을 입력해주세요.",
    email: "이메일 양식을 확인해주세요.",
  },
  addr: {
    required: "주소를 입력해주세요.",
  },
  // (v) => !!v || "닉네임을 입력해주세요.",
  // (v) =>
  //   (v.length >= 1 && v.length <= 20) || "닉네임은 20자 이하로 입력해주세요.",
  // (v) =>
  //   !/[^0-9ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]/.test(v) ||
  //   "닉네임은 특수기호 빼고 입력해 주세요",
};

localize("ko", {
  code: "ko",
  messages: ko.messages,
  fields: customFieldMessages,
});

const customRules = {
  birth: (v, args) => {
    return +v.substr(2, 2) < 13 && +v.substr(4, 2) < 32;
  },
  passwordStartWith: (v, args) => {
    return !/^[`!@#$%^&*()_+\-=\[\]\\{}|;':",./<>?]/.test(v);
  },
  passwordCustom: (v, args) => {
    return (
      [
        /[0-9]/.test(v),
        /[a-z]/.test(v),
        /[A-Z]/.test(v),
        /[`!@#$%^&*()_+\-=\[\]\\{}|;':",./<>?]/.test(v),
      ].filter((bool) => bool).length > 2
    );
  },
};

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
Object.keys(customRules).forEach((rule) => {
  extend(rule, customRules[rule]);
});

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
