var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.submit($event)}}},[(!_vm.input.imp_uid)?_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"input-custom w-100"},[_vm._v(" 휴대폰 번호 (아이디) "),_c('w-input',{attrs:{"type":"tel","maxlength":"11","placeholder":"아이디를 입력하세요.","name":"username","rules":{
              required: true,
              min: 10,
              max: 11,
              regex: /^[0-9].*$/,
            }},nativeOn:{"input":function($event){return _vm.$numberOnly($event, 'username', 11)}},model:{value:(_vm.input.username),callback:function ($$v) {_vm.$set(_vm.input, "username", $$v)},expression:"input.username"}})],1)]),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"input-custom w-100"},[_vm._v(" 이름 "),_c('w-input',{attrs:{"type":"text","name":"displayname","placeholder":"이름을 입력하세요.","rules":{ required: true, regex: /^[가-힣].*$/ }},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}})],1)])],1):_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"input-custom w-100"},[_vm._v(" 비밀번호 "),_c('w-input',{attrs:{"type":"password","name":"passwordRetype","placeholder":"비밀번호를 입력하세요.","rules":"required|passwordStartWith|min:8|passwordCustom"},model:{value:(_vm.input.passwordRetype),callback:function ($$v) {_vm.$set(_vm.input, "passwordRetype", $$v)},expression:"input.passwordRetype"}})],1)]),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"8"}},[_c('label',{staticClass:"input-custom w-100"},[_vm._v(" 비밀번호 확인 "),_c('w-input',{attrs:{"type":"password","name":"password","placeholder":"비밀번호를 입력하세요.","rules":{
              required: true,
              min: 8,
              passwordCustom: true,
              is: _vm.input.passwordRetype,
            }},model:{value:(_vm.input.password),callback:function ($$v) {_vm.$set(_vm.input, "password", $$v)},expression:"input.password"}})],1)])],1),_c('b-row',{staticClass:"my-5 pt-md-5",attrs:{"align-h":"center"}},[_c('b-col',{staticClass:"mb-3 text-center",attrs:{"cols":"12","md":"4"}},[(!_vm.input.imp_uid)?_c('b-btn',{staticClass:"w-100 py-3",attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(" 비밀번호 찾기 ")]):_c('b-btn',{staticClass:"w-100 py-3",attrs:{"variant":"primary"},on:{"click":_vm.reset}},[_vm._v(" 비밀번호 변경 ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }