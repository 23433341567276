import firebase from "@firebase/app";
import "@firebase/messaging";
window.firebase = firebase;
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDUEXNxT9E0mmni5BPudyde6VoxmNAz_SI",
  authDomain: "instalk-co-kr.firebaseapp.com",
  projectId: "instalk-co-kr",
  storageBucket: "instalk-co-kr.appspot.com",
  messagingSenderId: "641968773447",
  appId: "1:641968773447:web:dfbcb3f45ebdf85c4d5462",
  measurementId: "G-M4M32YY0TP",
};

firebase.initializeApp(firebaseConfig);
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BJSCdI-MLjIS2GZC4Km6_hPdq3851C8u9IxVoecI7ZHJW5hF2pGyECF3ApUbnzPoNXtLdyT7JtScU2kXqHiznko"
  );
}
