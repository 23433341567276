<template>
  <b-tabs
    class="tab-category text-dark pb-3"
    content-class="mt-3"
    nav-class="border-0 text-dark fw-400"
    active-nav-item-class="border-0 fw-700 text-black"
  >
    <template v-if="noRoute">
      <b-tab
        v-for="(item, i) in tabs"
        :key="i"
        :title="item.name"
        :active="value === item.value"
        @click="change(item)"
      ></b-tab>
    </template>
    <b-tab
      v-else
      v-for="(item, i) in tabs"
      :key="i"
      :title="item.name"
      :active="+$route.query.category === item.value"
      @click="change(item)"
    ></b-tab>
  </b-tabs>
</template>

<script>
export default {
  props: {
    tabs: Array,
    noRoute: {
      type: Boolean,
    },
    value: {},
  },
  methods: {
    change(item) {
      if (this.noRoute) {
        this.$emit("input", item.value);
      } else {
        this.$router.replace({
          path: this.$route.path,
          query: { category: item.value },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
