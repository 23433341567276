<template>
  <b-row class="py-4 border-top border-light" align-v="start">
    <b-col cols="12" md="2" class="mb-3 mb-md-0 pl-0">
      <h5 class="text-lg-20 fw-700">보험 비교</h5>
    </b-col>
    <b-col cols="12" md="10">
      <template v-if="!items.length">
        <div class="text-center">
          알림이 없습니다.
        </div>
      </template>
      <notification-item
        v-else
        v-for="item in items"
        :item="item"
        :key="item.id"
      />

      <pagination-custom
        v-if="pagination.totalDocs"
        :value="pagination.page"
        :total-rows="+pagination.totalDocs"
        :per-page="+pagination.limit"
        @page-click="updateQuery"
      />
    </b-col>
  </b-row>
</template>

<script>
import NotificationItem from "@/components/NotificationItem";
export default {
  components: {
    NotificationItem,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    async query(n) {
      await this.getItems(n);
    },
  },
  async mounted() {
    await this.getItems(this.query);
  },
  methods: {
    async getItems(n) {
      const { data } = await this.$axios.get("/noti", {
        params: { ...n },
      });
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    updateQuery(n) {
      this.routeControl("page", n);
    },

    typeColor(type) {
      if (type === "안내") {
        return "secondary";
      } else if (type === "이벤트") {
        return "blue-green";
      } else if (type === "공지사항") {
        return "primary";
      } else if (type === "알림") {
        return "purple";
      }
    },
  },
};
</script>
