<template>
  <div>
    <header class="mb-5 pb-5 text-center">
      <span class="text-lg-20 text-secondary">
        <!-- {{ header[userType].subtitle }} -->
      </span>
      <h1 class="mt-4 text-28 text-lg-40">{{ header[userType].title }}</h1>
    </header>
    <component
      :userType="userType"
      :is="userType === 'CUSTOMER' ? 'Customer' : 'PlannerAndCompany'"
      :options="options"
    />
  </div>
</template>

<script>
import Customer from "./Customer";
import PlannerAndCompany from "./PlannerAndCompany";
export default {
  data() {
    const CUSTOMER = {
      // subtitle: "샘플 텍스트입니다.",
      title: "간편 회원가입 및 보험 비교 요청",
    };
    const PLANNER = {
      // subtitle: "샘플 텍스트입니다.",
      title: "회원가입 및 법인 대리점/원수사 지점(사) 소개받기",
    };
    const COMPANY = {
      // subtitle: "샘플 텍스트입니다.",
      title: "법인 대리점 및 원수사 지점(사) 회원가입",
    };
    return {
      header: {
        CUSTOMER,
        PLANNER,
        COMPANY,
      },
      options: {},
    };
  },
  computed: {
    userType() {
      const user = this.user || this.$store.getters.tempUser;
      return user ? user.userType : null;
    },
  },
  mounted() {
    if (!this.userType) {
      this.$router.push("/");
    } else {
      this.getOptions();
    }
  },
  components: {
    Customer,
    PlannerAndCompany,
  },
  methods: {
    async getOptions() {
      const { data } = await this.$axios.get("/admin/options");
      this.options = data.data;
    },
  },
};
</script>

<style></style>
