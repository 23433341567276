<template>
  <div>
    <div v-if="!items.length" class="text-center py-5">
      데이터가 없습니다.
    </div>
    <div class="table-wrapper" v-else>
      <table>
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
            class="pointer-event"
            @click="$router.push(`${$route.path}/${item.id}`)"
          >
            <td>
              {{ item.relativeCategory.name }}
            </td>
            <td>
              {{ item.title }}
            </td>
            <td class="text-right">
              <small class="text-14 text-dark">
                {{ toLocaleDate(item.createdAt) }}
              </small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-custom
      v-if="pagination.totalDocs"
      :value="pagination.page"
      :total-rows="+pagination.totalDocs"
      :per-page="+pagination.limit"
      @page-click="updateQuery"
    />
  </div>
</template>

<script>
import CategoryTab from "../../components/CategoryTab.vue";
export default {
  components: { CategoryTab },
  data() {
    return {
      categories: [],
      items: [],
      pagination: {},
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    async query(n) {
      await this.getItems(n);
    },
  },
  async mounted() {
    await this.getCategory();
    if (!this.query.category)
      this.$router.replace({
        path: this.$route.path,
        query: { category: this.categories[0].value },
      });
    await this.getItems(this.query);
  },
  methods: {
    async getItems(n) {
      const { data } = await this.$axios.get("/notices", {
        params: { ...n },
      });
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    async getCategory() {
      const { data } = await this.$axios.get("/types", {
        params: { category: "notice" },
      });
      this.categories = data.data;
      this.$emit("set-category", data.data);
    },
    updateQuery(n) {
      this.routeControl("page", n);
    },
  },
};
</script>
