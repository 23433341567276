<template>
  <div>
    <div class="px-3">
      <b-row class="rounded-t-lg border border-light">
        <b-col cols="6" class="bg-light py-2 text-center text-14 fw-700">
          보유 캐시
          <div class="vl border-white" />
        </b-col>
        <b-col cols="6" class="bg-light py-2 text-center text-14 fw-700">
          보유 마일리지
        </b-col>
        <b-col cols="6" class="py-3 text-center text-center position-relative">
          <i class="icon icon-cash icon-20 mb-n1 mr-1"></i>
          {{ user.cash && user.cash.toLocaleString() }}
          <b-btn
            class="mx-auto p-0 position-absolute"
            variant="link"
            :style="{ right: '1rem' }"
            @click="withdrawCash"
          >
            캐시인출 &gt;
          </b-btn>
          <div class="vl border-light" />
        </b-col>
        <b-col cols="6" class="py-3 text-center">
          <i class="icon icon-mileage icon-20 mb-n1 mr-1"></i>
          {{ user.mileage && user.mileage.toLocaleString() }}
        </b-col>
      </b-row>
    </div>
    <article class="mt-5">
      <header>
        <b-row align-h="end">
          <b-col cols="12" md="5" class="mb-3 mb-md-0">
            <div class="px-3">
              <date-range-picker :startDate="startDate" :endDate="endDate" />
            </div>
          </b-col>
          <!-- <b-col cols="12" md="4" class="mb-3 mb-md-0">
            <div class="d-flex align-items-center">
              <b-form-select v-model="kind" class="mr-2">
                <b-form-select-option :value="null">종류</b-form-select-option>
                <b-form-select-option value="1">종류1</b-form-select-option>
                <b-form-select-option value="2">종류2</b-form-select-option>
              </b-form-select>
              <b-form-select v-model="option">
                <b-form-select-option :value="null">분류</b-form-select-option>
                <b-form-select-option value="1">분류1</b-form-select-option>
                <b-form-select-option value="2">분류2</b-form-select-option>
              </b-form-select>
            </div>
          </b-col> -->
          <b-col cols="12" md="3">
            <b-btn variant="primary" class="w-100">조회</b-btn>
          </b-col>
        </b-row>
      </header>
      <section class="mt-4">
        <div v-if="!items.length" class="text-center py-5">
          데이터가 없습니다.
        </div>
        <mileage-item v-else :items="items" />

        <pagination-custom
          v-if="pagination.totalDocs"
          :value="pagination.page"
          :total-rows="+pagination.totalDocs"
          :per-page="+pagination.limit"
          @page-click="updateQuery"
        />
      </section>
    </article>
    <!-- modal -->
    <withdraw-cash @hide="hide" />
    <charge-cash @hide="hide" />
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";
import MileageItem from "@/components/MileageItem.vue";
import Modals from "@/components/Modals/index";

export default {
  components: { DateRangePicker, MileageItem, ...Modals },
  data() {
    return {
      categories: [],
      items: [],
      pagination: {},
      startDate: "",
      endDate: "",
      kind: null,
      option: null,
      items: [],
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    async query(n) {
      await this.getItems(n);
    },
  },
  async mounted() {
    await this.getItems(this.query);
  },

  methods: {
    updateQuery({ val }) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: val },
      });
    },
    withdrawCash() {
      this.$bvModal.show("modal-withdraw-cash");
    },
    async getItems(n) {
      const { data } = await this.$axios.get("/points", {
        params: { limit: 5, ...n },
      });
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    hide(e) {
      if (e) {
        this.getMe();
        this.getItems(this.query);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
