<template>
  <b-row align-v="start" class="mb-4">
    <b-col cols="8" class="pl-0">
      <b-row>
        <b-col cols="3" class="pr-0 pr-md-0">
          <figure
            class="m-0 bg-img ratio-95"
            :style="{
              backgroundImage: `url(${s3 + item.thumbnail})`,
            }"
          ></figure>
          <!-- {{ item }} -->
        </b-col>
        <b-col cols="9">
          <div class="py-1 py-md-2 d-flex flex-column">
            <span class="text-14 mb-1" :class="'text-' + typeColor(item.type)">
              {{ typeText(item.type) }}
            </span>
            <h6 class="text-16 fw-700 text-truncate mb-2">
              {{ item.title }}
            </h6>
            <p class="text-14 m-0 text-truncate">
              {{ item.content }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="4" class="px-0 text-right">
      <small class="text-14 text-dark">
        {{ toLocaleDateTime(item.createdAt) }}
      </small>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    typeColor(type) {
      if (type === "INF") {
        return "secondary";
      } else if (type === "이벤트") {
        return "blue-green";
      } else if (type === "공지사항") {
        return "primary";
      } else if (type === "알림") {
        return "purple";
      }
    },
    typeText(type) {
      if (type === "INF") {
        return "안내";
      } else if (type === "이벤트") {
        return "blue-green";
      } else if (type === "공지사항") {
        return "primary";
      } else if (type === "알림") {
        return "purple";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
