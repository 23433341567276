<template>
  <div class="mt-5 d-flex justify-content-center">
    <b-pagination
      v-if="!noRoute"
      first-class="d-none"
      last-class="d-none"
      ellipsis-class="d-none"
      :value="value"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="emit"
    >
      <template #prev-text>
        <b-btn variant="text" class="mt-n2 p-0">
          <i class="icon-arr-left text-14"></i>
        </b-btn>
      </template>
      <template #next-text>
        <b-btn variant="text" class="mt-n2 p-0">
          <i class="icon-arr-right text-14"></i>
        </b-btn>
      </template>
    </b-pagination>
    <b-pagination
      v-else
      first-class="d-none"
      last-class="d-none"
      ellipsis-class="d-none"
      :value="value"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="emit"
    >
      <template #prev-text>
        <b-btn variant="text" class="mt-n2 p-0">
          <i class="icon-arr-left text-14"></i>
        </b-btn>
      </template>
      <template #next-text>
        <b-btn variant="text" class="mt-n2 p-0">
          <i class="icon-arr-right text-14"></i>
        </b-btn>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    noRoute: Boolean,
    value: Number,
    totalRows: Number,
    perPage: Number,
  },
  methods: {
    emit(e, val) {
      this.$emit("page-click", { e, val });
    },
  },
};
</script>

<style lang="scss" scoped></style>
