<template>
  <div>
    <div v-if="!items.length" class="text-center py-5">
      데이터가 없습니다.
    </div>
    <b-card
      v-else
      class="p-0 border-bottom border-light"
      :style="{ borderLeft: '0', borderRight: '0', borderRadius: '0' }"
      v-for="(item, l) in items"
      :key="l"
    >
      <b-card-header class="py-3 py-md-4 px-0 border-0">
        <h6 class="mr-auto text-lg-16">
          {{ item.title }}
        </h6>
        <b-btn variant="text" class="ml-4 mr-3 p-0" v-b-toggle="'' + item.id">
          <i class="icon-caret"></i>
        </b-btn>
      </b-card-header>
      <b-collapse :id="'' + item.id">
        Q.
        <div class="py-4 text-dark" v-text="item.content" />
        A.
        <div class="py-4 text-dark" v-html="item.answer" />
      </b-collapse>
    </b-card>

    <pagination-custom
      v-if="pagination.totalDocs"
      :value="pagination.page"
      :total-rows="+pagination.totalDocs"
      :per-page="+pagination.limit"
      @page-click="updateQuery"
    />
  </div>
</template>

<script>
import CategoryTab from "../../components/CategoryTab.vue";
export default {
  components: { CategoryTab },
  data() {
    return {
      categories: [],
      items: [],
      pagination: {},
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },
  watch: {
    async query(n) {
      await this.getItems(n);
    },
  },
  async mounted() {
    await this.getCategory();
    if (!this.query.category)
      this.$router.replace({
        path: this.$route.path,
        query: { category: this.categories[0].value },
      });
    await this.getItems(this.query);
  },
  methods: {
    async getItems(n) {
      const { data } = await this.$axios.get("/inquiry", {
        params: { ...n },
      });
      const { limit, page, totalDocs, docs } = data.data;
      console.log(data.data);
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    async getCategory() {
      const { data } = await this.$axios.get("/types", {
        params: { category: "inquiry" },
      });
      this.categories = data.data;
      this.$emit("set-category", data.data);
    },
    updateQuery({ val }) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: val },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
