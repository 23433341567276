<template>
  <div>
    <b-row
      class="py-4 border-top border-light"
      align-v="center"
      v-for="(item, i) in items"
      :key="i"
    >
      <b-col cols="12" md="3" class="mb-3 mb-md-0">
        <h6 class="text-lg-16 fw-700">
          {{ item.type }}
        </h6>
      </b-col>
      <b-col cols="5" md="2" class="px-md-0">
        <span class="text-lg-16 fw-700">{{ item.planner.name }} 설계사님 </span>
      </b-col>
      <b-col cols="3" md="1" class="text-center">
        <template v-if="item.info">
          <span class="text-lg-16" v-if="item.info.type === 'text'">
            {{ item.info.text }}
          </span>
          <b-btn
            variant="link text-darkest"
            class="ml-md-n2 p-0 fw-700"
            v-if="item.info.type === 'button'"
            @click="item.info.href"
          >
            {{ item.info.text }}
          </b-btn>
        </template>
      </b-col>
      <b-col cols="4" md="2" class="text-center px-0">
        <b-btn
          variant="link text-secondary"
          v-if="item.btn"
          @click="item.btn.href"
        >
          {{ item.btn.text }}
        </b-btn>
      </b-col>
      <b-col cols="6" md="2" class="text-left text-md-center px-0">
        <small class="pl-3 pl-md-0 text-dark text-center">
          2020.01.01 12:12
        </small>
      </b-col>
      <b-col cols="6" md="2" class="mt-3 mt-md-0 px-0 text-right">
        <b-btn
          variant="primary"
          class="px-4"
          v-if="user === 'planner'"
          @click="viewRequest(item)"
          >요청서보기</b-btn
        >
        <b-btn
          variant="primary"
          class="px-4"
          v-else
          @click="viewPlannerIntroduce(item)"
          >설계사정보보기</b-btn
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    viewRequest: {
      type: Function,
    },
    viewCustomer: {
      type: Function,
    },
    viewPlannerIntroduce: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped></style>
