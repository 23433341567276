<template>
  <b-container class="d-flex flex-column align-items-center pt-5">
    <header class="w-100 text-center">
      <!-- <span class="text-lg-20">샘플텍스트</span> -->
      <h1 class="mt-3 text-28 text-lg-54">
        <strong class="highlight">보험톡</strong>
        이 새로운 보험 문화를 만들겠습니다.
      </h1>
    </header>
    <b-row class="my-5 flex-grow-1 w-100" align-v="stretch">
      <b-col
        cols="12"
        md="4"
        class="mt-4 mt-md-0 px-0"
        v-for="(item, i) in intro"
        :key="i"
      >
        <figure
          class="bg-img ratio-50 m-0 hover-control"
          :style="{
            backgroundImage: `url(${item.src})`,
            backgroundSize: 'cover',
            maxHeight: '540px',
          }"
        >
          <div class="hover-over"></div>
          <figcaption
            class="h-100 d-flex flex-column text-center py-4 px-3 hover-item"
          >
            <div
              class="my-5 d-flex flex-column justify-content-between align-items-center flex-grow-1"
            >
              <h2 class="mb-5 text-24 text-lg-30">
                {{ item.title }} <br />
                <strong class="highlight highlight-light">
                  <span>{{ item.titleEmphasis }}</span>
                </strong>
              </h2>
              <div class="flex-grow-1"></div>
              <ul
                class="list-unstyled mb-4 py-0"
                :style="{ minHeight: '80px', width: '95%' }"
              >
                <li class="mb-0" v-for="(item2, l) in item.desc" :key="l">
                  - {{ item2 }}
                </li>
              </ul>
              <b-btn
                :variant="isHover == i ? 'white text-darkest' : 'primary'"
                class="rounded-0 w-75 mt-5 py-3"
                @mouseenter="isHover = i"
                :to="item.url"
              >
                {{ item.to }}
              </b-btn>
            </div>
          </figcaption>
        </figure>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      isHover: null,
      intro: [
        {
          title: "보험상품 비교 가입을 원하시는",
          titleEmphasis: "고객님",
          desc: [
            "다양한 회사의 다양한 상품 비교를 원하시는 고객님",
            "내가 원하는 조건에 따라 설계사를 선택하여 비교 받기를 원하시는 분",
          ],
          src: require("@/assets/images/intro-1.png"),
          hover: require("@/assets/images/intro-1-hover.png"),
          to: "고객 페이지 바로가기",
          url: "/",
        },
        {
          title: "고객을 소개받기 원하시는",
          titleEmphasis: "설계사님",
          desc: [
            "다른 법인 대리점(보험회사 포함)으로 이직을 원하시는 설계사님",
            "보험설계사가 되기를 원하시는 분",
          ],
          src: require("@/assets/images/intro-2.png"),
          hover: require("@/assets/images/intro-2-hover.png"),
          to: "설계사 페이지 바로가기",
          url: "/",
        },
        {
          title: "설계사를 소개받기 원하시는",
          titleEmphasis: "법인 대리점 대표님",
          desc: ["보험회사 지점장님 포함"],
          src: require("@/assets/images/intro-3.png"),
          hover: require("@/assets/images/intro-3-hover.png"),
          to: "법인 대리점 페이지 바로가기",
          url: "/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
