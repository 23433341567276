<template>
  <section>
    <b-row align-h="center">
      <b-col cols="12" md="10" lg="9" xl="8" class="form-dashed">
        <!-- Sign Up -->
        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 0 <= step }"
        >
          고객 정보
          <b-btn
            variant="link"
            class="text-dark py-0"
            to="/account/login"
            v-if="!user"
            >이미 회원이신가요?</b-btn
          >
        </header>

        <b-collapse :visible="step === 0">
          <w-form
            class="pb-5"
            @submit.prevent.stop="submit"
            v-slot="{ invalid }"
            v-if="!user"
          >
            <label class="input-custom w-100 mb-3">
              휴대폰 번호 (아이디)
              <w-input
                type="tel"
                maxlength="11"
                placeholder="아이디를 입력하세요."
                v-model="input.username"
                name="username"
                :rules="{
                  required: true,
                  min: 10,
                  max: 11,
                  regex: /^[0-9].*$/,
                }"
                @input.native="$numberOnly($event, 'username', 11)"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              비밀번호
              <w-input
                type="password"
                name="passwordRetype"
                placeholder="비밀번호를 입력하세요."
                v-model="input.passwordRetype"
                rules="required|passwordStartWith|min:8|passwordCustom"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              비밀번호 확인
              <w-input
                type="password"
                name="password"
                placeholder="비밀번호를 입력하세요."
                v-model="input.password"
                :rules="{
                  required: true,
                  min: 8,
                  passwordCustom: true,
                  is: input.passwordRetype,
                }"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              이름
              <w-input
                type="text"
                name="displayname"
                placeholder="이름을 입력하세요."
                v-model="input.name"
                :rules="{ required: true, regex: /^[가-힣].*$/ }"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              주민번호 앞 7자리
              <div class="d-flex align-items-start">
                <w-input
                  placeholder="000000"
                  v-model="input.birth"
                  name="birth"
                  rules="required|birth"
                  maxlength="7"
                  no-wheel
                  @input.native="$numberOnly($event, 'birth', 6)"
                />
                <div class="mx-2 mx-md-4 mt-4">-</div>
                <w-input
                  placeholder="0"
                  v-model="input.gender"
                  rules="required"
                  maxlength="1"
                  no-wheel
                  input-class="rounded"
                  @input.native="$numberOnly($event, 'gender', 1)"
                >
                  <template #append>
                    <div
                      class="position-absolute mt-4 mr-4 text-dark pr-2"
                      :style="{
                        left: '2rem',
                        top: 0,
                        bottom: 0,
                        zIndex: 10,
                      }"
                    >
                      &#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
                    </div>
                  </template>
                </w-input>
              </div>
            </label>
            <label class="input-custom w-100 mb-3">
              거주지
              <w-select
                text-field="name"
                value-field="value"
                v-model="input.area"
                :options="area"
                rules="required"
              />
            </label>
            <div class="d-flex justify-content-between my-5">
              <div class="checkbox-wrap">
                <w-checkbox
                  class="mb-2"
                  v-model="input.agreeTerms"
                  :required="true"
                >
                  [필수] <a class="text-dark" href="">서비스이용약관</a>에
                  동의합니다.
                </w-checkbox>
                <w-checkbox
                  class="mb-2"
                  v-model="input.agreePrivacy"
                  :required="true"
                >
                  [필수] <a class="text-dark" href="">개인정보취급방침</a>에
                  동의합니다.
                </w-checkbox>
                <b-checkbox class="mb-2" v-model="input.agreeSMS">
                  [선택] 마케팅 메시지 수신(SMS)에 동의합니다.
                </b-checkbox>
              </div>
              <div class="flex-grow-1"></div>
              <b-btn
                class="py-3 px-4"
                variant="primary"
                @click="submit"
                :disabled="invalid"
              >
                간편 회원가입
              </b-btn>
            </div>
          </w-form>
          <div class="pb-5" v-else>
            <label class="input-custom w-100 mb-3">
              휴대폰 번호 (아이디)
              <b-input
                disabled
                plaintext
                type="text"
                placeholder="아이디를 입력하세요."
                v-model="input.username"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              이름
              <b-input
                disabled
                plaintext
                type="text"
                placeholder="이름을 입력하세요."
                v-model="input.name"
              />
            </label>
            <label class="input-custom w-100 mb-3">
              주민번호 앞 7자리
              <div class="d-flex align-items-center">
                {{ input.birth }} - {{ input.gender }}******
              </div>
            </label>
            <label class="input-custom w-100 mb-3" v-if="user && area.length">
              거주지<br />
              {{ textArea(user.area) }}
            </label>
            <div class="d-flex justify-content-end align-items-center mt-5">
              <b-btn class="text-dark" variant="link" to="/mypage/profile">
                회원정보 수정하기
              </b-btn>
              <b-btn class="py-3 px-4" variant="primary" @click="next">
                보험 비교 요청
              </b-btn>
            </div>
          </div>
        </b-collapse>
        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 1 <= step }"
        >
          가입하고 싶은 보험 종목 (중복 선택 가능)
        </header>
        <b-collapse :visible="step === 1">
          <div class="pb-5">
            <button-selector
              multiple
              v-model="wish.insurance"
              :items="$types.insurance"
              class="mb-4"
            />
            <div class="text-right my-4">
              * 모든 정보는 비공개 처리되며 최종단계에서 선택한 설계사에게 필수
              정보만 공개합니다 .
            </div>
            <div class="d-flex justify-content-end align-items-center mt-5">
              <b-btn class="text-dark" variant="link" @click="step--">
                이전
              </b-btn>
              <b-btn class="py-3 px-4" variant="primary" @click="next">
                다음
              </b-btn>
            </div>
          </div>
        </b-collapse>

        <template v-if="options.useRecommend">
          <header
            class="form-header text-lg-20 fw-700 mb-4"
            :class="{ active: 2 <= step }"
          >
            추천 받고싶은 설계사
          </header>
          <b-collapse :visible="step === 2">
            <div class="pb-5">
              <label>성별</label>
              <button-selector
                v-model="wish.gender"
                :items="gender"
                add-null
                class="mb-4"
              />
              <label>연령대</label>
              <button-selector
                v-model="wish.age"
                :items="age"
                add-null
                class="mb-4"
              />
              <label>경력</label>
              <button-selector
                v-model="wish.career"
                :items="career"
                add-null
                class="mb-4"
              />
              <label>설계사 활동 지역</label>
              <button-selector
                v-model="wish.area"
                key-name="name"
                :items="area"
                add-null
                class="mb-4"
              />
              <label>설계사 소속 보험사(중복 가능, 가나다순)</label>
              <button-checkable
                @click.native="checkAll('ga')"
                :checked="allGa"
                class="pr-4 mb-2"
                :block="false"
                text="GA 전체 (모든 손해보험, 생명보험을 취급하는 곳)"
              />
              <div class="border-bottom mb-3">
                <button-selector
                  multiple
                  v-model="wish.ga"
                  :items="ga"
                  class="mb-2"
                />
              </div>
              <b-row class="mx-n2">
                <b-col class="px-2" cols="6" sm="4" lg="3">
                  <button-checkable
                    @click.native="checkAll('sonhae')"
                    :checked="allSonhae"
                    block
                    text="손해보험 전체"
                    class="mb-2"
                  />
                </b-col>
              </b-row>
              <div class="border-bottom mb-3">
                <button-selector
                  multiple
                  v-model="wish.sonhae"
                  :items="sonhae"
                  class="mb-2"
                />
              </div>
              <b-row class="mx-n2">
                <b-col class="px-2" cols="6" sm="4" lg="3">
                  <button-checkable
                    @click.native="checkAll('life')"
                    :checked="allLife"
                    block
                    text="생명보험 전체"
                    class="mb-2"
                  />
                </b-col>
              </b-row>
              <button-selector
                multiple
                v-model="wish.life"
                :items="life"
                class="mb-4"
              />

              <div class="d-flex justify-content-end align-items-center mt-5">
                <b-btn class="text-dark" variant="link" @click="step--">
                  이전
                </b-btn>
                <b-btn class="py-3 px-4" variant="primary" @click="next">
                  다음
                </b-btn>
              </div>
            </div>
          </b-collapse>
        </template>

        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 3 <= step }"
        >
          제안받고 싶은 설계사 인원 수
        </header>
        <b-collapse :visible="step === 3">
          <label class="input-custom w-100 mb-3">
            인원 수
            <b-select v-model="wish.count">
              <b-form-select-option
                v-for="i in [5, 10, 15, 20]"
                :key="i"
                :value="i"
              >
                {{ i }}명
              </b-form-select-option>
            </b-select>
          </label>
          <div class="d-flex justify-content-end align-items-center mt-5">
            <b-btn
              class="text-dark"
              variant="link"
              @click="options.useRecommend ? step-- : (step -= 2)"
            >
              이전
            </b-btn>
            <b-btn class="py-3 px-4" variant="primary" @click="next">
              다음
            </b-btn>
          </div>
        </b-collapse>

        <header
          class="form-header text-lg-20 fw-700 mb-4"
          :class="{ active: 4 <= step }"
        >
          보험비교 시 원하시는 사항을 자유롭게 적어주세요~
        </header>
        <b-collapse :visible="step === 4">
          <div class="pb-5">
            <label class="input-custom w-100">
              내용
              <b-textarea
                :rows="5"
                class="w-100 h-auto"
                v-model="wish.content"
                placeholder="보험과 관련된 내용을 적어주세요"
              />
            </label>
            <div class="mt-5">
              <b-btn variant="primary" block class="p-4" @click="request">
                보험 비교 요청하기
              </b-btn>
              <b-btn variant="link" block class="text-dark" @click="step--"
                >이전</b-btn
              >
            </div>
          </div>
        </b-collapse>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
import ButtonSelector from "@/components/ButtonSelector";
export default {
  components: { ButtonSelector, ButtonCheckable },
  props: {
    options: { type: Object, default: { useRecommend: false } },
  },
  data() {
    const isProduction = process.env.NODE_ENV === "production";
    return {
      valid: false,
      step: 0,
      members: 5,
      input: {
        // username: null,
        // password: null,
        // password2: null,
        // name: null,
        // birth: null,
        // gender: null,
        // addr: null,
        // email: null,
        // area: null,
        username: isProduction ? null : "01049302614",
        password: isProduction ? null : "password1!",
        password2: isProduction ? null : "password1!",
        name: isProduction ? null : "윤이건",
        birth: isProduction ? null : "920617",
        gender: isProduction ? null : 3,
        addr: isProduction ? null : "경기도 성남시 중원구 성남대로 1000",
        email: isProduction ? null : "whaledoll@naver.com",
        area: isProduction ? null : "0",
      },
      wish: {
        insurance: isProduction ? [] : [1],
        age: null,
        career: null,
        area: null,
        content: null,
        count: 10,
        sonhae: [],
        life: [],
        ga: [],
      },
      ga: [],
      gender: [],
      age: [],
      career: [],
      area: [],
      sonhae: [],
      life: [],
    };
  },
  async mounted() {
    if (this.user) {
      this.input = { ...this.input, ...(await this.getMe()) };
    }
    this.gender = this.setType(this.$types.gender);
    this.age = this.setType(this.$types.age);
    this.career = this.setType(this.$types.career);
    this.area = this.setType(this.$types.area);
    this.ga = this.setType(this.$types.ga);
    this.life = this.setType(this.$types.life);
    this.sonhae = this.setType(this.$types.sonhae);
  },
  computed: {
    allSonhae() {
      return this.wish.sonhae && this.wish.sonhae.length === this.sonhae.length;
    },
    allLife() {
      return this.wish.life && this.wish.life.length === this.life.length;
    },
    allGa() {
      return this.wish.ga && this.wish.ga.length === this.ga.length;
    },
    state() {
      return this.$refs && this.$refs.form;
    },
  },
  methods: {
    textArea(area) {
      if (!area) return "-";
      if (this.area.length) {
        return this.area.find(({ value }) => value === +area).name;
      } else {
        return "-";
      }
    },
    checkAll(type) {
      let val;
      switch (type) {
        case "ga":
          val = this.allGa;
          this.toggleAll(type, val);
          break;
        case "sonhae":
          val = this.allSonhae;
          this.toggleAll(type, val);
          break;
        case "life":
          val = this.allLife;
          this.toggleAll(type, val);
          break;
      }
    },
    toggleAll(type, val) {
      this.wish[type] = val ? [] : this[type].map(({ value }) => value);
    },
    async submit() {
      console.log("submit");
      try {
        const { data } = await this.$axios.post(
          "/account/register",
          this.input
        );
        if (data.success) {
          localStorage.setItem("insTalk__token", data.token);
          this.getMe();
          await alert(
            "회원가입이 완료되었습니다.<br/>다음 과정을 진행해주세요."
          );
          this.next;
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    async request() {
      try {
        console.log("request", this.wish);
        const { data } = await this.$axios.post("/compares", this.wish);
        if (data.success) {
          await alert(data.msg);
          this.$router.push("/mypage/compare");
        }
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
    async next() {
      switch (this.step) {
        case 0:
          return !!this.user
            ? this.step++
            : await alert("회원 정보가 없습니다.");
        case 1:
          return !!this.wish.insurance.length
            ? this.options.useRecommend
              ? this.step++
              : (this.step += 2)
            : await alert("선택된 보험 종목이 없습니다.");
        case 2:
          const { sonhae, life, ga } = this.wish;
          return [...sonhae, ...life, ...ga].length ||
            !this.options.useRecommend
            ? this.step++
            : await alert("선택된 소속 보험사가 없습니다.");
        default:
          this.step++;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
