<template>
  <b-modal
    centered
    size="lg"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="유료 회원 결제가 완료되었습니다."
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-payment-complete"
    ok-title="확인"
    ok-variant="primary"
    ok-only
    @ok="ok"
  >
    <div class="subtitle">유료 회원 결제 완료</div>
    <b-row align-h="center" class="mb-5">
      <b-col cols="8" class="border px-md-5">
        <table class="w-100 my-4 text-14">
          <tbody>
            <tr>
              <td class="py-1 fw-700 px-3">거래번호</td>
              <td class="py-1 px-3">123123123</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">구분</td>
              <td class="py-1 px-3">유료 회원 결제</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">결제일시</td>
              <td class="py-1 px-3">2020.08.15 12:23</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">결제방법</td>
              <td class="py-1 px-3">국민카드 1234</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">결제금액</td>
              <td class="py-1 px-3">10,000원</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">적립 캐시</td>
              <td class="py-1 px-3">10,000캐시</td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">무료 제공 마일리지(10%)</td>
              <td class="py-1 px-3">1,000p</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    ok: {
      type: Function,
    },
  },
};
</script>

<style></style>
