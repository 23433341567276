<template>
  <b-modal
    :visible="show"
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-4 px-md-5 pb-5 mb-5 d-block"
    :title="`${user.userType === 'PLANNER' ? '본인' : '법인'} 소개 보내기`"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-send-planner-introduce"
    ok-title="확인"
    ok-variant="primary"
    cancel-title="취소"
    cancel-variant="light"
    @hide="$emit('hide', true)"
    @show="price = user.userType === 'PLANNER' ? 3000 : 5000"
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <div class="px-md-5" v-if="show">
      <div class="mb-4">
        현재 비밀번호
        <label class="input-custom w-100 d-flex position-relative">
          <b-form-input
            type="password"
            placeholder="비밀번호를 입력하세요."
            v-model="input.password"
            class="pr-5"
          ></b-form-input>
        </label>
      </div>
      <div class="mb-4">
        <div class="d-flex justify-content-between">
          캐시
          <span class="text-14"
            >보유 : {{ user.cash && user.cash.toLocaleString() }}</span
          >
        </div>
        <label class="input-custom w-100 d-flex position-relative">
          <b-form-input
            disabled
            plaintext
            :value="calcInput.cash && calcInput.cash.toLocaleString()"
            class="pr-5 bg-transparent"
          ></b-form-input>
        </label>
      </div>
      <div class="mb-4">
        <div class="d-flex justify-content-between">
          마일리지
          <span class="text-14"
            >보유 : {{ user.mileage && user.mileage.toLocaleString() }}</span
          >
        </div>
        <label class="input-custom w-100 d-flex position-relative">
          <b-form-input
            @input.native="$inputNumber($event, 'mileage', 0, maxMileage, true)"
            :placeholder="`최대 ${maxMileage}원`"
            v-model="input.mileage"
            class="pr-5"
          ></b-form-input>
        </label>
        <div class="text-right text-primary text-14">
          *마일리지는 총 금액의 20%까지만 사용 가능합니다.
        </div>
      </div>
      <div class="mt-3 text-center fw-500">
        {{ price.toLocaleString() }}점을 사용하여 본인 소개를 보내시겠습니까?<br />
        <span class="text-danger"
          >사용된 캐시와 마일리지는 환불되지 않습니다.</span
        >
      </div>
    </div>
    <template #modal-footer>
      <b-row align-v="center" class="mx-n2">
        <b-col cols="6" md="4" class="px-2">
          <b-btn variant="light" class="w-100" block @click="hide">
            취소
          </b-btn>
        </b-col>
        <b-col cols="6" md="4" class="px-2">
          <b-btn variant="primary" class="w-100" block @click="chargeCash">
            캐시 충전
          </b-btn>
        </b-col>
        <b-col cols="12" md="4" class="px-2 position-relative">
          <b-btn
            variant="primary"
            class="w-100"
            block
            @click="sendIntroduce"
            :disabled="!valid.state"
          >
            {{ `${user.userType === "PLANNER" ? "본인" : "법인"} 소개 보내기` }}
          </b-btn>
          <div
            class="text-danger position-absolute text-center text-14 mt-1"
            :style="{ right: 0, left: 0, top: `100%` }"
            v-if="!valid.state"
          >
            {{ valid.message }}
          </div>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    visible: { type: Object },
    target: { type: Number },
    ok: {
      type: Function,
    },
  },
  data() {
    return {
      price: 3000,
      input: {
        password: null,
        mileage: null,
      },
    };
  },
  computed: {
    show() {
      return !!this.visible;
    },
    rules() {
      const { password } = this.input;
      const { cash, mileage } = this.user;
      return [
        () => cash + mileage >= this.price || "캐시와 마일리지가 부족합니다.",
        () => !!password || "비밀번호를 입력해주세요.",
      ];
    },
    valid() {
      let valid = { state: null, message: "" };
      const val = this.value;
      if (val === null) return { state: null, message: "" };
      valid = this.rules.reduce((result, rule) => {
        if (val === null) {
          return;
        }
        if (result.state !== false) {
          const ruleResult = rule(val);
          console.log(ruleResult);
          result =
            typeof ruleResult === "string"
              ? {
                  state: false,
                  message: ruleResult,
                }
              : {
                  state: true,
                  message: null,
                };
        }
        return result;
      }, valid);

      return valid;
    },
    maxMileage() {
      const priceMax = this.price * 0.2;
      return Math.min(priceMax, this.user.mileage);
    },
    calcInput() {
      console.log(typeof this.input.mileage);
      const mileage = this.input.mileage
        ? +this.input.mileage.replace(/\D/g, "")
        : 0;
      return this.visible
        ? {
            ...this.input,
            compare: this.visible.id,
            cash: this.price - mileage,
            target: this.visible.relativeAuthor.id,
          }
        : {};
    },
  },
  methods: {
    hide() {
      this.$emit("hide", true);
    },
    chargeCash() {
      this.$bvModal.hide("modal-send-planner-introduce");
      this.$router.push("/mypage/mileage");
    },
    async sendIntroduce() {
      // todo 팝업 이후로 옮겨야함
      const mileage = this.input.mileage
        ? +this.input.mileage.replace(/\D/g, "")
        : 0;
      try {
        const { data } = await this.$axios.post("/requests", {
          ...this.calcInput,
          mileage,
        });
        await alert("본인소개를 보냈습니다.");
        this.$emit("hide", true); // refresh
      } catch (error) {
        alert(error.response.data.msg);
      }
    },
  },
};
</script>

<style></style>
