import "@babel/polyfill";
import "mutationobserver-shim";
import "./registerServiceWorker";
import Vue from "vue";
import "./plugins/axios";
import "./plugins/messaging";
import "./plugins/bootstrap-vue";
import "./plugins/weblab";
import "./plugins/validate";
import App from "./App.vue";
import router from "./router";
import "./styles/index.scss";
import store from "./store";
import common from "./plugins/common";
import PaginationCustom from "@/components/PaginationCustom";
import './registerServiceWorker'

Vue.config.productionTip = false;

Vue.use(common);
Vue.component("pagination-custom", PaginationCustom);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
