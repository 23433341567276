<template>
  <div>
    <!-- <b-row align-h="end" class="mb-4">
      <b-col md="6">
        <date-range-picker v-model="query"></date-range-picker>
      </b-col>
    </b-row> -->
    <!-- 보험 비교 요청내역 -->
    <table class="w-100">
      <tbody class="border-top" v-if="user">
        <div v-if="!items.length" class="py-5 text-center">
          데이터가 없습니다.
        </div>
        <tr v-else v-for="item in items" :key="item.id" class="border-bottom">
          <td class="py-3 px-2 text-lg-18 fw-700">
            <!-- {{ getState(item) }} -->
            {{ item.state }}
          </td>
          <td class="py-3 px-2">
            <span class="text-lg-16 fw-500"
              >{{ item.relativeAuthor.name }}
              {{ user.userType === "COMPANY" ? "설계사" : "고객" }}님
            </span>
          </td>
          <td class="py-3 px-2">
            <small class="text-dark text-center">
              {{ toLocaleDateTime(item.createdAt) }}
            </small>
          </td>
          <td class="py-3 px-2">
            <b-btn
              variant="link"
              class="text-lg-16 text-secondary text-underline py-0"
              @click="request(item)"
              :disabled="loading.request"
              v-if="['요청 가능'].includes(item.state)"
            >
              {{ user.userType === "PLANNER" ? "본인" : "기업" }} 소개 보내기
            </b-btn>
            <b-btn
              variant="link"
              class="text-lg-16 text-secondary text-underline py-0"
              @click="cancelRequest(item)"
              :disabled="loading.request"
              v-if="['승인대기'].includes(item.state)"
            >
              {{ user.userType === "PLANNER" ? "본인" : "기업" }} 소개 취소
            </b-btn>
          </td>
          <td class="py-3 px-2">
            <b-btn
              variant="link text-secondary"
              v-if="item.btn"
              @click="item.btn.href"
            >
              {{ item.btn.text }}
            </b-btn>
          </td>
          <td class="py-3 px-2">
            <b-btn
              variant="link text-secondary"
              v-if="item.btn"
              @click="item.btn.href"
            >
              {{ item.btn.text }}
            </b-btn>
            <!-- <b-btn class="text-dark text-center" v-if="item.myRequest">
              {{
                item.myRequest.createdAt
                  .substr(0, 16)
                  .split("T")
                  .join(" ")
              }}
              보냄
            </b-btn> -->
          </td>
          <td class="text-right" v-if="user.userType === 'PLANNER'">
            <b-btn
              variant="primary"
              class="px-4"
              @click="viewRequest(item)"
              v-if="['승인완료', '승인대기', '요청 가능'].includes(item.state)"
              >{{
                item.state === "승인완료" ? "고객 정보 보기" : "요청서 보기"
              }}</b-btn
            >
          </td>
          <td class="text-right" v-else>
            <b-btn
              variant="primary"
              class="px-4"
              @click="viewRequest(item)"
              v-if="['승인완료', '승인대기', '요청 가능'].includes(item.state)"
            >
              설계사 소개 보기
            </b-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-custom
      v-if="pagination.totalDocs"
      :value="pagination.page"
      :total-rows="+pagination.totalDocs"
      :per-page="+pagination.limit"
      @page-click="updateQuery"
    />
    <!-- modal -->
    <component
      :visible="isEdit"
      :is="
        user.userType === 'COMPANY'
          ? 'edit-company-introduce'
          : 'edit-planner-introduce'
      "
    />
    <component
      :is="user.userType === 'PLANNER' ? 'view-request' : 'planner-introduce'"
      :item="currentRequest"
      @hide="currentRequest = null"
    />
    <send-planner-introduce @hide="hideSend" :visible="currentCompare" />
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";
import InsurancePlanner from "@/components/InsurancePlanner.vue";
import BreakdownItem from "@/components/BreakdownItem.vue";
import Modals from "@/components/Modals/index";

export default {
  components: {
    EditPlannerIntroduce: Modals.EditPlannerIntroduce,
    EditCompanyIntroduce: Modals.EditCompanyIntroduce,
    ViewCustomer: Modals.ViewCustomer,
    ViewRequest: Modals.ViewRequest,
    PlannerIntroduce: Modals.PlannerIntroduce,
    SendPlannerIntroduce: Modals.SendPlannerIntroduce,
    DateRangePicker,
    InsurancePlanner,
    BreakdownItem,
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
    query() {
      return this.$route.query;
    },
  },

  watch: {
    query(n) {
      this.getItems(n);
    },
  },
  data() {
    return {
      loading: {
        request: false,
      },
      isEdit: false,
      pagination: {},
      items: [],
      currentCustomer: null,
      currentRequest: null,
      currentCompare: null,
    };
  },
  mounted() {
    if (!this.query.startDate || !this.query.endDate) {
      const endDate = this.toLocaleDate(new Date());
      const startDate = this.toLocaleDate(endDate, -7);
      this.$router.replace({
        path: this.$route.path,
        query: {
          startDate,
          endDate,
        },
      });
    } else {
      this.getItems(this.query);
    }
  },
  methods: {
    updateQuery({ val }) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: val },
      });
    },
    async getItems(params) {
      try {
        const { data } = await this.$axios.get("/compares/request", { params });
        // todo pagination
        const { limit, page, totalDocs, docs } = data.data;
        this.items = docs;
        this.pagination = { limit, page, totalDocs };
      } catch (error) {}
    },
    async cancelRequest(item) {
      const { id } = item.myRequest;
      const bool = await confirm("정말 취소하시겠습니까?", { size: "sm" });

      if (bool) {
        try {
          this.loading.request = true;
          const { data } = await this.$axios.delete(`/requests/${id}`);
          console.log("data", data);
          await this.getItems();
          this.loading.request = false;
        } catch (error) {
          this.loading.request = false;
        }
      }
    },
    async request(item) {
      const { userType, info } = this.user;
      const { insType, area, size } = info;
      const infoObj =
        userType === "PLANNER"
          ? [
              {
                rule: !insType,
                msg: "등록된 소속이 없습니다. 등록하시겠습니까?",
              },
              {
                rule: area && !area.length,
                msg: "등록된 활동지역이 없습니다. 등록하시겠습니까?",
              },
            ]
          : [
              {
                rule: !insType,
                msg: "등록된 보험사가 없습니다. 등록하시겠습니까?",
              },
              {
                rule: !size,
                msg: "등록된 규모가 없습니다. 등록하시겠습니까?",
              },
            ];
      const needConfirm = infoObj.reduce(
        (res, obj) => {
          if (res.state) {
            return res;
          } else {
            return {
              state: obj.rule,
              msg: obj.rule ? obj.msg : null,
            };
          }
        },
        { state: false, msg: null }
      );

      if (needConfirm.state) {
        const bool = await confirm(needConfirm.msg);
        if (bool) this.isEdit = true;
        return;
      }

      try {
        this.loading.request = true;
        this.currentCompare = item;
      } catch (error) {
        this.loading.request = false;
      }
    },
    async hideSend(e) {
      this.currentCompare = null;
      this.loading.request = false;
      if (e) {
        await Promise.all[(this.getMe(), this.getItems())];
      }
    },
    viewRequest(item) {
      console.log(item);
      this.currentRequest = this.objectTypesFormatter(item);
      this.$bvModal.show("modal-view-request");
    },
  },
};
</script>

<style lang="scss" scoped></style>
