<template>
  <div>
    <header class="d-flex align-items-center justify-content-between">
      <!-- <b-avatar size="1.875rem"></b-avatar> -->
      <div class="ml-3 mr-auto">
        <strong class="text-14 fw-700">{{ item.relativeAuthor.name }}</strong>
        <span class="ml-3 text-14" :style="{ color: '#666666' }">{{
          toLocaleDateTime(item.createdAt)
        }}</span>
      </div>
      <b-dropdown
        variant="transparant"
        no-caret
        class="ml-2 mr-0 p-0"
        size="sm"
        v-if="item.relativeAuthor.id === user.id"
      >
        <template #button-content>
          <i class="icon-more text-24"></i>
        </template>
        <b-dropdown-item @click="editable = true">수정</b-dropdown-item>
        <b-dropdown-item @click="remove" href="#">삭제</b-dropdown-item>
      </b-dropdown>
      <div class="mx-0">
        <span class="text-primary" v-if="!editable">
          <i class="icon-star "></i>
          {{ starRate.toFixed(1) }}
        </span>
        <b-form-rating
          v-else
          v-model="starRate"
          show-value
          precision="1"
          class="border-0 d-flex justify-content-center starrate-custom"
        >
          <template #icon-empty>
            <i class="text-16 text-primary icon-star-empty"></i>
          </template>
          <template #icon-half>
            <i class="text-16 text-primary icon-star-empty"></i>
          </template>
          <template #icon-full>
            <i class="text-16 text-primary icon-star"></i>
          </template>
        </b-form-rating>
      </div>
    </header>
    <b-form @submit.stop.prevent="submit" class="px-2 mb-4">
      <b-textarea
        :plaintext="!editable"
        :disabled="!editable"
        v-model="content"
        class="d-inline text-14"
        :style="{ color: '#666666' }"
      />
      <div class="d-flex justify-content-end">
        <b-btn v-if="editable" class="px-3 py-2" variant="primary" type="submit"
          >수정</b-btn
        >
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  props: {
    item: { type: Object },
  },
  data() {
    return {
      editable: false,
      starRate: 0,
      content: null,
    };
  },
  mounted() {
    this.initContent();
  },
  methods: {
    async submit() {
      try {
        const { content, starRate } = this;
        const { data } = await this.$axios.patch(`/reviews/${this.item.id}`, {
          content,
          starRate,
        });
        this.editable = false;
      } catch (error) {}
    },
    async remove() {
      try {
        const { data } = await this.$axios.delete(`/reviews/${this.item.id}`);
        this.$emit("remove-me", this.item.id);
      } catch (error) {}
    },
    initContent() {
      this.content = this.item.content;
      this.starRate = this.item.starRate;
      this.editable = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
