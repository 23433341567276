<template>
  <b-modal
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="설계사 소개"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-view-request"
    ok-title="확인"
    ok-variant="primary"
    ok-only
    :visible="!!item"
    @shown="shown"
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <div class="d-flex justify-content-center">
      <category-tab v-model="currentTab" no-route :tabs="tabs" />
    </div>
    <b-collapse
      accordion="planner-introduce"
      :visible="currentTab === 'tab-planner-info'"
    >
      <div class="d-flex justify-content-center">
        <b-avatar
          size="8.75rem"
          :src="s3 + item.relativeAuthor.thumbnail"
        ></b-avatar>
      </div>
      <b-row align-h="center" class="mt-5">
        <b-col cols="11" md="8" class="border px-md-3">
          <table class="w-100 my-4 text-14">
            <tbody>
              <tr>
                <td class="py-1 fw-700 px-3">이름</td>
                <td class="py-1 px-3">
                  {{ item.relativeAuthor.name }}
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3">성별</td>
                <td class="py-1 px-3">
                  {{ item.relativeAuthor.gender % 2 ? "남성" : "여성" }}
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3">연령대</td>
                <td class="py-1 px-3">
                  {{ getAge(item.relativeAuthor.birth, true) }}대
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3">소속</td>
                <td class="py-1 px-3">
                  {{ currentCareer }}
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3">활동지역</td>
                <td class="py-1 px-3">
                  {{ info.area && info.area.join(", ") }}
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3" :style="{ verticalAlign: 'top' }">
                  경력사항
                </td>
                <td class="py-1 px-3">
                  <ul class="list-unstyled p-0 m-0">
                    <li
                      class="mb-1 text-14"
                      :class="idx ? 'fw-300' : 'fw-400'"
                      v-for="(career, idx) in cptdCareers"
                      v-text="career"
                      :key="idx"
                    />
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="py-1 fw-700 px-3" :style="{ verticalAlign: 'top' }">
                  자기소개
                </td>
                <td class="py-1 px-3" v-text="item.content"></td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-collapse>
    <b-collapse
      accordion="planner-introduce"
      :visible="currentTab === 'tab-planner-review'"
    >
      <article class="my-5 px-md-5">
        <div class="text-center" v-if="!items.length">
          등록된 후기가 없습니다.
        </div>
        <review-item v-else v-for="item in items" :item="item" :key="item.id" />

        <pagination-custom
          no-route
          v-if="pagination.totalDocs"
          :value="pagination.page"
          :total-rows="+pagination.totalDocs"
          :per-page="+pagination.limit"
          @page-click="getReviews"
        />
      </article>
    </b-collapse>
  </b-modal>
</template>

<script>
import CategoryTab from "@/components/CategoryTab.vue";
import ReviewItem from "@/components/ReviewItem.vue";

export default {
  components: { CategoryTab, ReviewItem },
  props: {
    value: Boolean,
    expiry: Boolean,
    item: Object,
    viewRequest: Function,
    viewReview: Function,
    types: Object,
  },
  data() {
    return {
      items: [],
      pagination: {},
      currentTab: "tab-planner-info",
      tabs: [
        {
          name: "정보",
          value: "tab-planner-info",
        },
        {
          name: "후기",
          value: "tab-planner-review",
        },
      ],
      info: {},
    };
  },
  computed: {
    cptdCareers() {
      if (this.item.relativeAuthor.info) {
        const { careers } = this.item.relativeAuthor.info;
        if (careers) {
          let total = 0;
          const result = careers.map((c) => {
            total += c.period;
            return `${c.name} ${c.position} ${c.period}년`;
          });
          result.unshift(`총 경력 ${total}년`);
          return total ? result : "-";
        } else return "-";
      } else return "-";
    },
    currentCareer() {
      if (this.item.relativeAuthor.info) {
        const { insType, branch, customText } = this.item.relativeAuthor.info;
        if (!insType) return "-";
        // const [type, idx, custom] = insType.split("-");
        if (typeof insType === "string") {
          return `${customText} ${branch}`;
        } else {
          const { insurance, ga, sonhae, life } = this.$types;
          return `${
            [...insurance, ...ga, ...sonhae, ...life].find(
              (t) => t.value === insType
            ).name
          } ${branch}`;
        }
      } else return "-";
    },
  },
  methods: {
    async getReviews({ e, val }) {
      const { data } = await this.$axios.get(
        `/reviews/${this.item.relativeAuthor.id}`,
        {
          params: { limit: 5, page: val },
        }
      );
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    shown() {
      this.info = this.objectTypesFormatter(this.item.relativeAuthor.info);
      this.getReviews({});
    },
  },
};
</script>

<style></style>
