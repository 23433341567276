<template>
  <div>
    <b-row align-h="end" class="mb-4">
      <b-col md="6">
        <date-range-picker :value="query" />
      </b-col>
    </b-row>
    <div v-if="!items.length" class="text-center py-5">
      데이터가 없습니다.
    </div>
    <b-card class="p-0 border-0" v-else v-for="item in items" :key="item.id">
      <b-card-header class="py-3 py-md-5 px-0">
        <h6 class="mr-auto text-lg-20 fw-700">
          {{ getState(item) }}
        </h6>
        {{ toLocaleDate(item.createdAt) }}
        <b-btn
          variant="link"
          class="text-secondary p-0 ml-auto"
          @click="viewRequest(item)"
          >요청서 보기</b-btn
        >
        <span class="ml-4" v-if="item.relativeRequests.length"
          >제안서 {{ item.relativeRequests.length }} / {{ item.count }}개</span
        >
        <span class="ml-4" v-else>요청 대기 ({{ item.count }}개)</span>
        <b-btn
          :disabled="!item.relativeRequests.length"
          :style="{ opacity: !item.relativeRequests.length ? 0.2 : 1 }"
          variant="text"
          class="ml-4 mr-3 p-0"
          v-b-toggle="`inprogress-${item.id}`"
        >
          <i class="icon-caret text-24"></i>
        </b-btn>
      </b-card-header>
      <b-collapse
        :id="`inprogress-${item.id}`"
        class="p-3 p-md-4 bg-gray border-bottom"
        accordion="breakdown"
      >
        <b-card-body class="p-0">
          <b-row align-h="end">
            <b-col cols="12" offset-md="2" md="10">
              <header
                v-if="!item.expiry"
                class="mb-4 d-md-flex align-items-center justify-content-between"
              >
                <div class="mb-3 mb-md-0 d-flex w-100">
                  <b-form-checkbox
                    :checked="isCheckedAll(item)"
                    @change="checkAll(item, $event)"
                    >모두 선택</b-form-checkbox
                  >
                </div>

                <b-btn
                  :disabled="!getCheckedCount(item) || loading.accept"
                  variant="primary"
                  class="w-sm-100 ml-md-4 mr-0 text-nowrap"
                  @click="acceptItems(item)"
                >
                  {{
                    getCheckedCount(item)
                      ? `선택한 ${
                          user.userType === "CUSTOMER" ? "설계사" : "법인"
                        } ${getCheckedCount(item)}${
                          user.userType === "CUSTOMER" ? "명" : "곳"
                        }에게 제안받기`
                      : `${
                          user.userType === "CUSTOMER" ? "설계사를" : "법인을"
                        } 선택해주세요`
                  }}
                </b-btn>
              </header>
              <b-row class="mx-n2">
                <b-col
                  cols="12"
                  md="6"
                  v-for="(req, i) in item.relativeRequests"
                  :key="i"
                  class="p-2"
                >
                  <insurance-planner
                    v-model="req.checked"
                    :expiry="item.expiry"
                    :types="{ area, ga, life, sonhae }"
                    :item="req"
                    :viewRequest="viewRequest"
                    :viewReview="viewReview"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>

    <pagination-custom
      v-if="pagination.totalDocs"
      :value="pagination.page"
      :total-rows="+pagination.totalDocs"
      :per-page="+pagination.limit"
      @page-click="updateQuery"
    />
    <!-- modal -->
    <edit-compare />
    <!-- <request-compare />
    <planner-review />
    <send-planner-introduce /> -->
    <view-request :item="currentRequest" @hide="currentRequest = null" />
    <!-- <view-customer />
    <planner-introduce /> -->
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";
import InsurancePlanner from "@/components/InsurancePlanner.vue";
import BreakdownItem from "@/components/BreakdownItem.vue";
import Modals from "@/components/Modals/index";

export default {
  components: {
    DateRangePicker,
    InsurancePlanner,
    BreakdownItem,
    ...Modals,
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
    query() {
      return this.$route.query;
    },
  },
  watch: {
    query(n) {
      this.getItems(n);
    },
  },
  data() {
    return {
      loading: {
        accept: false,
      },
      pagination: {},
      currentRequest: null,
      range: [],
      items: [],
      area: [],
      ga: [],
      life: [],
      sonhae: [],
    };
  },
  async mounted() {
    if (!this.query.startDate || !this.query.endDate) {
      const endDate = this.toLocaleDate(new Date());
      const startDate = this.toLocaleDate(endDate, -7);
      this.$router.replace({
        path: this.$route.path,
        query: {
          startDate,
          endDate,
        },
      });
    } else {
      this.getItems(this.query);
    }
  },
  methods: {
    getState({ count, relativeRequests, expiry }) {
      if (expiry) return "종료";
      else if (+count > relativeRequests.length) return "진행";
      else return "완료";
    },
    updateQuery({ val }) {
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: val },
      });
    },
    async getItems(params) {
      const { data } = await this.$axios.get("/compares", { params });
      this.items = data.data.docs.map((doc) => {
        doc.relativeRequests = doc.relativeRequests.map((item) => ({
          ...item,
          checked: false,
          statistics: item.statistics || {
            starRate: 0,
            total: 0,
            written: 0,
          },
        }));
        return doc;
      });
      // todo pagination
      const { limit, page, totalDocs, docs } = data.data;
      this.items = docs;
      this.pagination = { limit, page, totalDocs };
    },
    async acceptItems(item) {
      const { relativeRequests } = item;
      // accept
      const requests = relativeRequests
        .filter((item) => item.checked)
        .map((item) => item.id);
      this.loading.accept = true;
      try {
        const { data } = await this.$axios.patch("/requests", { requests });
        item.expiry = true;
        console.log(item.requests);
        item.relativeRequests = item.relativeRequests.map((req) => ({
          ...req,
          accept: requests.includes(req.id),
        }));
        console.log(item.requests);
        this.loading.accept = false;
      } catch (error) {}
      // todo pagination
    },
    getCheckedCount(item) {
      return item.relativeRequests
        .map((req) => req.checked)
        .filter((val) => val).length;
    },
    isCheckedAll(item) {
      return !item.relativeRequests
        .map((req) => req.checked)
        .some((val) => !val);
    },
    checkAll(item, value) {
      item.relativeRequests = item.relativeRequests.map((req) => ({
        ...req,
        checked: value,
      }));
    },
    editCompare() {
      this.$bvModal.show("modal-edit-compare");
    },
    editCompareSubmit() {
      window.alert("요청서편집완료");
    },
    viewReview() {
      this.$bvModal.show("modal-planner-review");
    },
    sendPlannerIntroduce() {
      this.$bvModal.show("modal-send-planner-introduce");
    },
    viewRequest(compare) {
      const modalData = this.objectTypesFormatter(compare);
      modalData.relativeAuthor = this.user;
      modalData.isMine = true;
      this.currentRequest = modalData;
      this.$bvModal.show("modal-view-request");
    },
    viewCustomer() {
      this.$bvModal.show("modal-view-customer");
    },
    viewPlannerIntroduce() {
      this.$bvModal.show("modal-planner-introduce");
    },
  },
};
</script>

<style lang="scss" scoped></style>
