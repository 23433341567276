<template>
  <b-modal
    centered
    size="md"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="캐시충전"
    title-class="h2 pt-5"
    body-class="px-4 px-md-5"
    id="modal-charge-cash"
    ok-variant="primary"
    ok-only
    @show="init"
  >
    <div class="d-flex justify-content-center">
      <category-tab no-route :tabs="tabs" v-model="currentType" />
    </div>
    <b-row align-h="center">
      <b-col cols="11" md="8">
        <header class="my-3 text-18 text-lg-20">
          충전금액 선택
        </header>

        <b-form-group>
          <b-form-radio-group
            id="charge-amount"
            v-model="selected"
            name="charge-amount"
            class="d-flex flex-column"
          >
            <b-form-radio
              v-for="(opt, l) in options"
              :key="l"
              :value="opt.id"
              class="my-2 w-100 "
              variant="secondary"
            >
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <span
                  >{{ opt.price && opt.price.toLocaleString() }}원 (
                  <i class="icon icon-mileage icon-18 mb-n1 mr-1"></i>
                  <span>{{ opt.per }}%</span>
                  )
                </span>
                <div class="ml-auto fw-700">
                  <i class="icon icon-mileage icon-18 mb-n1 mr-1"></i>
                  <span>{{ opt.mileage && opt.mileage.toLocaleString() }}</span>
                </div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer>
      <small
        >환불 요청시 남은 캐쉬의 70%가 환불됩니다(마일리지는 환불불가)</small
      >
      <b-row align-h="center" align-v="stretch" class="w-100">
        <b-col cols="12" md="6">
          <b-btn variant="primary" class="w-100" @click="chargeCash">
            {{
              selected !== null &&
                options[selected].price &&
                options[selected].price.toLocaleString()
            }}
            캐시 충전
          </b-btn>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import CategoryTab from "@/components/CategoryTab.vue";
export default {
  components: { CategoryTab },
  data() {
    return {
      currentType: "tab-credit-card",
      tabs: [
        {
          name: "신용/체크카드",
          value: "tab-credit-card",
        },
        // {
        //   name: "휴대폰",
        //   value: "tab-phone",
        // },
      ],
      selected: null,
      options: [],
    };
  },

  methods: {
    init() {
      // 성공시
      // https://myservice.com/payments/complete?imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=true
      // 실패시
      // https://myservice.com/payments/complete?imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=false&error_code=에러_코드(현재_정리된_체계는_없음)&error_msg=에러_메시지

      this.getPrices();
    },
    chargeCash() {
      IMP.request_pay(
        {
          amount: this.options[this.selected].price,
          buyer_name: this.user.name,
          buyer_email: "",
          name: `보험톡 캐시 충전 ${this.options[
            this.selected
          ].price.toLocaleString()}원`,
          m_redirect_url: `${location.origin}/mypage/payments/${this.selected}`,
        },
        async (rsp) => {
          //결제 후 호출되는 callback함수
          if (rsp.success) {
            const { data } = await this.$axios.post("/payments", {
              ...rsp,
              selected: this.selected,
            });
            this.$bvModal.hide("modal-charge-cash");
            this.$emit("hide", true);
            // todo 결제정보 저장
          } else {
            alert("결제실패 : " + rsp.error_msg);
          }
        }
      );
    },
    async getPrices() {
      const { data } = await this.$axios.get("/payments/prices");
      this.options = data.data.map((item) => ({
        ...item,
        mileage: (item.price * item.per) / 100,
      }));
      this.selected = this.options.length ? this.options[0].id : null;
    },
  },
};
</script>

<style></style>
