<template>
  <b-btn
    :block="block"
    class="d-flex align-items-center rounded px-2 px-md-3 text-14 text-lg-16"
    :style="{ height: '48px' }"
    :variant="checked ? 'primary text-darkest' : 'outline-light text-dark'"
    @click="$emit('input', value)"
  >
    <div class="mr-2 rounded-circle" :class="checked ? 'bg-white' : 'bg-light'">
      <i class="icon-check text-20 mx-0"></i>
    </div>
    {{ text }}
  </b-btn>
</template>

<script>
export default {
  props: {
    block: { default: true },
    value: { default: null },
    text: { default: "-" },
    checked: { type: Boolean },
  },
};
</script>

<style></style>
