<template>
  <article
    class="d-flex align-items-center justify-content-end date-range-picker"
    v-if="value"
  >
    <label class="d-flex align-items-center text-nowrap"
      >{{ value.startDate }}
      <b-form-datepicker
        id="start-date"
        class=""
        size="sm"
        button-only
        right
        button-variant="transparant"
        @input="push('startDate', $event)"
      >
        <template #button-content>
          <i class="icon-calendar text-18"></i>
        </template>
      </b-form-datepicker>
    </label>

    <div class="mx-2">~</div>

    <label class="d-flex align-items-center text-nowrap"
      >{{ value.endDate }}
      <b-form-datepicker
        id="start-date"
        class=""
        size="sm"
        button-only
        right
        button-variant="transparant"
        @input="push('endDate', $event)"
      >
        <template #button-content>
          <i class="icon-calendar text-18"></i>
        </template>
      </b-form-datepicker>
    </label>
  </article>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  methods: {
    push(type, e) {
      // this.value[type] = e;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          [type]: e,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
