var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_c('validation-provider',{ref:"provider",attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-input-group',{staticClass:"mb-3"},[_c('label',{staticClass:"flex-grow-1 mb-0"},[_c('span',{class:{
            'text-danger':
              _vm.rules && _vm.getValidationState(validationContext) === false,
          },domProps:{"textContent":_vm._s(_vm.label)}}),_c('b-form-group',{staticClass:"m-0"},[_c('b-input',_vm._b({staticClass:"py-2",class:{ 'px-3': !_vm.$attrs.plaintext },on:{"input":_vm.inputListener}},'b-input',Object.assign({}, _vm.$attrs,
              _vm.$props,
              {class: _vm.inputClass,
              state:
                _vm.rules && _vm.getValidationState(validationContext) === false
                  ? false
                  : null}),false)),_c('b-form-invalid-feedback',{staticClass:"text-happy text-right position-absolute",style:({ top: '100%' })},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")]),_vm._l((_vm.$slots),function(_,slot){return [_vm._t(slot)]}),_vm._l((_vm.$scopedSlots),function(_,slot){return [_vm._t(slot,null,null,{
                state: _vm.rules && _vm.getValidationState(validationContext),
              })]})],2)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }