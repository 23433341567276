<template>
  <div>
    <b-row align-h="end" class="mb-4">
      <b-col md="6">
        <date-range-picker
          :startDate="startDate"
          :endDate="endDate"
        ></date-range-picker>
      </b-col>
    </b-row>
    <template v-if="user.userType == 'CUSTOMER'">
      <b-card class="p-0 border-0">
        <b-card-header class="py-3 py-md-5 px-0">
          <h6 class="mr-auto text-lg-20 fw-700">
            진행
          </h6>
          <span class="ml-4">제안서 2개</span>
          <b-btn variant="text" class="ml-4 mr-3 p-0" v-b-toggle.inprogress>
            <i class="icon-caret text-10 mr-2"></i>
          </b-btn>
        </b-card-header>
        <b-collapse
          id="inprogress"
          class="p-3 p-md-4 bg-gray border-bottom"
          accordion="breakdown"
        >
          <b-card-body class="p-0">
            <b-row align-h="end">
              <b-col cols="12" offset-md="2" md="10">
                <header
                  class="mb-4 d-md-flex align-items-center justify-content-between"
                >
                  <div class="mb-3 mb-md-0 d-flex w-100">
                    <b-form-checkbox>모두선택</b-form-checkbox>
                    <b-btn
                      variant="link"
                      class="text-secondary p-0 ml-auto"
                      @click="editCompare"
                      >요청서 편집</b-btn
                    >
                  </div>

                  <b-btn
                    variant="primary"
                    class="w-sm-100 ml-md-4 mr-0 text-nowrap"
                    >선택한 설계사 5명에게 제안받기</b-btn
                  >
                </header>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    v-for="(item, i) in 1"
                    :key="i"
                    class="mb-3"
                  >
                    <insurance-planner
                      :viewRequest="viewRequest"
                      :viewReview="viewReview"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card class="p-0 border-0">
        <b-card-header class="py-3 py-md-5 px-0">
          <h6 class="mr-auto text-lg-20 fw-700">
            완료
          </h6>
          <strong class="ml-4">GA </strong>
          <strong class="ml-4">설계사명 외 2명</strong>
          <span class="ml-4">제안서 2개</span>
          <b-btn variant="text" class="ml-4 mr-3 p-0" v-b-toggle.finished>
            <i class="icon-caret text-10 mr-2"></i>
          </b-btn>
        </b-card-header>
        <b-collapse
          id="finished"
          class="p-3 p-md-4 bg-gray border-bottom"
          accordion="breakdown"
        >
          <b-card-body class="p-0">
            <b-row align-h="end">
              <b-col cols="12" offset-md="2" md="10">
                <header
                  class="mb-4 d-md-flex align-items-center justify-content-between"
                >
                  <div class="mb-3 mb-md-0 d-flex w-100">
                    <b-form-checkbox>모두선택</b-form-checkbox>
                    <b-btn
                      variant="link"
                      class="text-secondary p-0 ml-auto"
                      @click="editCompare"
                      >요청서 편집</b-btn
                    >
                  </div>

                  <b-btn
                    variant="primary"
                    class="w-sm-100 ml-md-4 mr-0 text-nowrap"
                    >선택한 설계사 5명에게 제안받기</b-btn
                  >
                </header>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    v-for="(item, i) in 1"
                    :key="i"
                    class="mb-3"
                  >
                    <insurance-planner />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </template>
    <template v-if="user.userType == 'PLANNER'">
      <!-- 보험 비교 요청내역 -->
      <breakdown-item
        v-if="meta.type === 'planner-to-customer'"
        :items="plannersBreakdown"
        :viewRequest="viewRequest"
        :viewCustomer="viewCustomer"
      />
      <!-- 법인 비교 요청내역 -->
      <div class="" v-if="meta.type === 'planner-to-company'">
        <b-card class="p-0 border-0">
          <b-card-header class="py-3 py-md-5 px-0">
            <h6 class="mr-auto text-lg-20 fw-700">
              진행
            </h6>
            <span class="ml-4">제안서 2개</span>
            <b-btn variant="text" class="ml-4 mr-3 p-0" v-b-toggle.inprogress>
              <i class="icon-caret text-10 mr-2"></i>
            </b-btn>
          </b-card-header>
          <b-collapse
            id="inprogress"
            class="p-3 p-md-4 bg-gray border-bottom"
            accordion="breakdown"
          >
            <b-card-body class="p-0">
              <b-row align-h="end">
                <b-col cols="12" offset-md="2" md="10">
                  <header
                    class="mb-4 d-flex align-items-center justify-content-between"
                  >
                    <b-form-checkbox>모두선택</b-form-checkbox>
                    <b-btn
                      variant="link"
                      class="text-secondary p-0 ml-auto"
                      @click="editCompare"
                      >요청서 편집</b-btn
                    >
                    <b-btn
                      variant="primary"
                      class="w-sm-100 ml-4 mr-0 text-nowrap"
                      >선택한 설계사 5명에게 제안받기</b-btn
                    >
                  </header>
                  <b-row>
                    <b-col
                      cols="6"
                      v-for="(item, i) in 5"
                      :key="i"
                      class="mb-3"
                    >
                      <insurance-planner />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card class="p-0 border-0">
          <b-card-header class="py-3 py-md-5 px-0">
            <h6 class="mr-auto text-lg-20 fw-700">
              완료
            </h6>
            <strong class="ml-4">GA </strong>
            <strong class="ml-4">설계사명 외 2명</strong>
            <span class="ml-4">제안서 2개</span>
            <b-btn variant="text" class="ml-4 mr-3 p-0" v-b-toggle.finished>
              <i class="icon-caret text-10 mr-2"></i>
            </b-btn>
          </b-card-header>
          <b-collapse
            id="finished"
            class="p-3 p-md-4 bg-gray border-bottom"
            accordion="breakdown"
          >
            <b-card-body class="p-0">
              <b-row align-h="end">
                <b-col cols="12" offset-md="2" md="10">
                  <header
                    class="mb-4 d-flex align-items-center justify-content-between"
                  >
                    <b-form-checkbox>모두선택</b-form-checkbox>
                    <b-btn variant="link" class="text-secondary p-0 ml-auto"
                      >요청서 편집</b-btn
                    >
                    <b-btn
                      variant="primary"
                      class="w-sm-100 ml-4 mr-0 text-nowrap"
                      >선택한 설계사 5명에게 제안받기</b-btn
                    >
                  </header>
                  <b-row>
                    <b-col
                      cols="6"
                      v-for="(item, i) in 5"
                      :key="i"
                      class="mb-3"
                    >
                      <insurance-planner :viewRequest="viewRequest()" />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
    <template v-if="user.userType == 'COMPANY'">
      <breakdown-item
        :items="companyBreakdown"
        :viewRequest="viewRequest"
        :viewCustomer="viewCustomer"
        :viewPlannerIntroduce="viewPlannerIntroduce"
      />
    </template>

    <!-- modal -->
    <edit-compare :ok="editCompareSubmit" />
    <request-compare />
    <planner-review />
    <send-planner-introduce />
    <view-request />
    <view-customer />
    <planner-introduce />
  </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";
import InsurancePlanner from "@/components/InsurancePlanner.vue";
import BreakdownItem from "@/components/BreakdownItem.vue";
import Modals from "@/components/Modals/index";

export default {
  components: {
    DateRangePicker,
    InsurancePlanner,
    BreakdownItem,
    ...Modals,
  },
  computed: {
    meta() {
      return this.$route.meta;
    },
  },
  watch: {
    meta() {
      return this.$route.meta;
    },
  },
  data() {
    return {
      startDate: "2020-01-01",
      endDate: "2021-01-11",
    };
  },
  methods: {
    editCompare() {
      this.$bvModal.show("modal-edit-compare");
    },
    editCompareSubmit() {
      window.alert("요청서편집완료");
    },
    viewReview() {
      this.$bvModal.show("modal-planner-review");
    },
    sendPlannerIntroduce() {
      this.$bvModal.show("modal-send-planner-introduce");
    },
    viewRequest() {
      this.$bvModal.show("modal-view-request");
    },
    viewCustomer() {
      this.$bvModal.show("modal-view-customer");
    },
    viewPlannerIntroduce() {
      this.$bvModal.show("modal-planner-introduce");
    },
  },
};
</script>

<style lang="scss" scoped></style>
