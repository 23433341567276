import Vue from "vue";
import Vuex from "vuex";
import types from "@/lib/types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loaded: false,
    token: null,
    tempUser: null,
    user: null,
    types,
  },
  mutations: {
    // o => overwrite
    set(s, [k, v, o]) {
      s[k] = o ? { ...s[k], ...v } : v;
    },
  },
  getters: {
    token(s) {
      return s.token;
    },
    user(s) {
      return s.user;
    },
    tempUser(s) {
      return s.tempUser;
    },
    loaded(s) {
      return s.loaded;
    },
    types(s) {
      return s.types;
    },
  },
  actions: {
    async getMe({ commit }, payload) {
      const token =
        payload ||
        localStorage.getItem("insTalk__token") ||
        sessionStorage.getItem("insTalk__token");
      if (token) {
        try {
          Vue.axios.defaults.headers.common["Authorization"] = token;
          const { data } = await Vue.axios.get("/account/me", {
            params: { token },
          });
          const user = data.user;
          commit("set", ["user", user]);
          commit("set", ["loaded", true]);
          return user;
        } catch (error) {
          commit("set", ["loaded", true]);
          return null;
        }
      } else {
        commit("set", ["loaded", true]);
        return null;
      }
    },
    async getTypes({ commit }) {
      try {
        const types = [
          "area",
          "insurance",
          "company-ga",
          "company-life",
          "company-sonhae",
        ];
        const [area, insurance, ga, life, sonhae] = await Promise.all(
          types.map((type) => Vue.axios.get(`/types?category=${type}`))
        );
        commit("set", [
          "types",
          {
            area: area.data.data,
            insurance: insurance.data.data,
            ga: ga.data.data,
            life: life.data.data,
            sonhae: sonhae.data.data,
          },
          true,
        ]);
      } catch (error) {
        alert(error);
      }
    },
    async setPushToken({ commit }, pushToken) {
      console.log("setPushToken");
      try {
        const { data } = await Vue.axios.patch("/account/push", { pushToken });
      } catch (error) {
        return null;
      }
    },
  },
});
