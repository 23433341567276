<template>
  <b-form @submit.prevent.stop="submit">
    <b-row align-h="center">
      <b-col cols="12" md="8" class="pb-4">
        <label class="input-custom w-100">
          휴대폰 번호 (아이디)
          <b-form-input
            v-model="input.username"
            type="text"
            placeholder="아이디를 입력하세요."
          ></b-form-input>
        </label>
      </b-col>
      <b-col cols="12" md="8" class="pb-4">
        <label class="input-custom w-100">
          비밀번호
          <b-form-input
            v-model="input.password"
            type="password"
            placeholder="비밀번호를 입력하세요."
          ></b-form-input>
        </label>
      </b-col>
      <b-col cols="12" md="8" class="pb-4" v-if="err">
        <small class="text-danger">
          {{ err }}
        </small>
      </b-col>
      <b-col cols="12" md="8" class="pb-4">
        <div class="d-flex justify-content-between">
          <label for="input-custom">
            <b-form-checkbox v-model="keepLogin">
              로그인 유지
            </b-form-checkbox>
          </label>
          <b-btn
            variant="link text-secondary"
            class="p-0"
            to="/account/find-password"
          >
            비밀번호 찾기
          </b-btn>
        </div>
      </b-col>
    </b-row>
    <div class="flex-grow-1"></div>
    <b-row align-h="center">
      <b-col cols="12" md="4" class="pb-4 text-center">
        <b-btn variant="primary" type="submit" class="w-100 py-3">
          로그인
        </b-btn>
        <b-btn variant="link text-primary mt-4" to="/account/sign-up">
          회원가입
        </b-btn>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  name: "SignIn",
  data() {
    const isProduction = process.env.NODE_ENV === "production";
    return {
      input: {
        username: isProduction ? null : "whaledoll",
        password: isProduction ? null : "password1!",
      },
      keepLogin: false,
      valid: false,
      err: null,
    };
  },
  methods: {
    async submit() {
      try {
        const { data } = await this.$axios.post("/account/signin", this.input);
        if (data.success) {
          if (this.keepLogin) {
            localStorage.setItem("insTalk__token", data.token);
            sessionStorage.removeItem("insTalk__token");
          } else {
            sessionStorage.setItem("insTalk__token", data.token);
            localStorage.removeItem("insTalk__token");
          }
          await this.$store.dispatch("getMe", data.token);
          // 푸시토큰 & 토픽 등록
          if (window.Notification) {
            window.Notification.requestPermission().then(async (permission) => {
              if (permission === "granted") {
                console.log("Notification permission granted.");
                const messaging = firebase.messaging();

                try {
                  const pushToken = await messaging.getToken();
                  await this.$store.dispatch("setPushToken", pushToken);
                } catch (error) {
                  console.log(error);
                }
              } else {
                console.log("Unable to get permission to notify.");
              }
            });
          }
          this.$router.push("/");
        }
      } catch (error) {
        const { data } = error.response;
        this.err = data.msg;
      }
    },
  },
};
</script>
