<template>
  <b-container>
    <b-row class="mt-5 w-100 px-md-5" align-h="center" align-v="stretch" align>
      <b-col
        cols="12"
        md="4"
        class="text-center mb-4 mt-5 px-0 pl-4 px-md-3"
        v-for="(item, i) in items"
        :key="i"
      >
        <section
          class="ml-md-0 h-100 px-4 d-flex flex-column align-items-center justify-content-end"
          :style="{ backgroundColor: '#f1f1f1' }"
        >
          <img
            :src="item.src"
            alt=""
            class="mt-n5"
            :style="{ width: `inherit` }"
          />
          <div class="flex-grow-1"></div>
          <h2 class="mb-5 text-lg-30" v-html="item.title" />
          <div class="flex-grow-1"></div>
          <span class="text-lg-14 text-darkest" v-if="item.more">
            {{ item.more }}
          </span>
          <b-btn
            variant="primary"
            block
            class="py-3 my-4"
            @click.prevent="go(item)"
          >
            {{ item.to }}
          </b-btn>
        </section>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("@/assets/images/signin-1.png"),
          title: "고객님",
          more: "무료 회원가입",
          to: "설계사님 소개받기 (회원가입)",
          url: "/account/compare",
          userType: "CUSTOMER",
        },
        {
          src: require("@/assets/images/signin-2.png"),
          title: "설계사님",
          more: null,
          to: "고객 소개받기 및 법인 대리점/원수사 지점(사) 소개받기(회원가입)",
          url: "/account/compare",
          userType: "PLANNER",
        },
        {
          src: require("@/assets/images/signin-3.png"),
          title: "법인 대표님/<br/>원수사 지점장님",
          more: null,
          to: "설계사님 소개받기 (회원가입)",
          url: "/account/compare",
          userType: "COMPANY",
        },
      ],
    };
  },
  methods: {
    go(item) {
      this.$store.commit("set", ["tempUser", { userType: item.userType }]);
      this.$router.push(item.url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
