<template>
  <b-navbar tag="nav" class="gnb bg-white py-4 text-nowrap">
    <b-container class="px-3">
      <b-navbar-brand to="/">
        <img src="@/assets/logo.svg" alt="" />
      </b-navbar-brand>
      <!-- <div class="ml-4 mr-auto">
        <b-btn variant="text" class="p-0" to="/main">
          보험
        </b-btn>
      </div> -->
      <b-navbar-nav class="ml-auto">
        <section class="d-flex text-lg-16">
          <div class="mr-4" v-if="user == null">
            <b-btn
              variant="link"
              class="text-dark text-decoration-none text-hangang mr-4 p-0"
              to="/account/sign-up"
            >
              회원가입
            </b-btn>
            <b-btn
              variant="link"
              class="text-secondary text-decoration-none text-hangang p-0"
              to="/account/login"
            >
              로그인
            </b-btn>
          </div>
          <div class="d-flex align-items-center" v-else>
            <b-btn variant="text" class="mr-2 p-0" to="/mypage/notification">
              <i class="icon-bell"> </i>
            </b-btn>
            <b-btn variant="text" class="mr-4 p-0" to="/mypage/profile">
              {{ user.name }}님
            </b-btn>
            <b-badge variant="primary py-1 px-2 mr-2">{{
              txtV2T(user.userType)
            }}</b-badge>
            <b-btn variant="text" class="p-0" to="/mypage">
              마이페이지
            </b-btn>
          </div>
        </section>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
export default {};
</script>
