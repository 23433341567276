<template>
  <div class="layout d-flex flex-column justify-content-between bg-lightest">
    <global-nav />
    <div class="p-2 p-md-0">
      <b-container
        class="py-5 my-4 bg-white shadow-sm flex-grow-1 d-flex flex-column"
      >
        <header class="mb-5 pb-5 text-center" v-if="meta.header">
          <span
            class="text-lg-20 text-secondary text-hangang"
            v-if="meta.header"
          >
            {{ meta.header.intro }}
          </span>
          <h1 class="mt-4 text-28 text-lg-40">
            {{ meta.header.title }}
          </h1>
        </header>
        <div class="flex-grow-1" v-if="!meta.hideHeader"></div>
        <router-view />
      </b-container>
    </div>

    <global-footer />
  </div>
</template>

<script>
import GlobalNav from "@/components/GlobalNav";
import GlobalFooter from "@/components/GlobalFooter.vue";
export default {
  components: { GlobalNav, GlobalFooter },
};
</script>
