<template>
  <div v-if="userType">
    <b-container>
      <b-row align-v="stretch" class="half-container">
        <b-col cols="12" md="6" class="py-5">
          <header class="mb-5">
            <small
              class="text-secondary text-lg-16"
              v-text="cptdContent.top.subtitle"
            />
            <h1
              class="mt-1 mb-2 text-28 text-lg-40"
              v-html="cptdContent.top.title"
            />
            <small
              class="text-dark text-lg-16"
              v-text="cptdContent.top.content"
            />
          </header>
          <ul class="list-checked pl-3" v-if="cptdContent.top.li">
            <li
              class="mb-2"
              v-for="(l, i) in cptdContent.top.li"
              :key="i"
              v-text="l"
            />
          </ul>
          <b-btn
            variant="primary"
            class="rounded-0 mt-4 py-2 px-5"
            @click="goCompare"
            v-html="cptdContent.top.btn.text"
          >
          </b-btn>
        </b-col>
        <b-col cols="12" md="6" class="px-5 half-container">
          <div
            class="bg-img half-image right"
            :style="{
              backgroundImage: `url(${cptdContent.top.src})`,
            }"
          ></div>
        </b-col>
      </b-row>
    </b-container>
    <!-- 고객  화면  -->
    <template v-if="userType == 'CUSTOMER'">
      <b-container fluid class="bg-lightest py-5">
        <header class="text-center mt-md-5">
          <h2 class="text-lg-40">
            보험상품 비교견적 흐름도
          </h2>
        </header>
        <section class="my-5 py-5">
          <img
            :src="require('@/assets/images/service-flow-customer.png')"
            alt="보험상품 비교견적 흐름도 이미지"
            class="w-50 d-none d-md-block mx-auto"
          />
          <img
            :src="require('@/assets/images/service-flow-customer_m.svg')"
            alt="보험상품 비교견적 흐름도 이미지"
            class="w-100 d-md-none d-block mx-auto"
          />
        </section>
      </b-container>
    </template>

    <!-- 설계사 화면  -->
    <template v-if="userType == 'PLANNER'">
      <b-container>
        <b-row align-v="stretch" class="flex-column-reverse flex-md-row">
          <b-col cols="12" md="6" class="pb-5 half-container">
            <div
              class="bg-img half-image left"
              :style="{
                backgroundImage: `url(${require('@/assets/images/planner-2.png')})`,
              }"
            ></div>
          </b-col>
          <b-col cols="12" md="6" class="py-5">
            <header class="mb-5 py-mb-5">
              <small class="text-secondary text-lg-16"
                >보험톡에 오신 걸 환영합니다.</small
              >
              <h1 class="mt-1 mb-2 text-28 text-lg-40">
                보험톡이 우리 동네 제대로된 <br />
                법인 대리점을 소개시켜 드립니다
              </h1>
              <small class="text-dark text-lg-16">
                보험 대리점은 보험회사를 포함합니다.
              </small>
            </header>
            <ul class="list-checked mb-5 pl-3 text-left">
              <li class="mb-2">
                나의 소득을 더 높여줄 관리자가 있는 법인(원수사)을 찾는 분
              </li>
              <li class="mb-2">
                타 법인(원수사)으로 이직을 고민하고 계신 분
              </li>
              <li class="mb-2">
                보험 설계사를 새롭게 시작하시고 싶으신 분
              </li>
            </ul>
            <div class="d-flex align-items-center">
              <b-btn
                variant="light"
                class="rounded-0 mr-4 mt-4 py-3 py-md-4 px-md-5"
                to="/account/sign-up"
              >
                회원가입
              </b-btn>
              <b-btn
                variant="primary"
                class="rounded-0 mt-4 py-md-4 px-md-5"
                to="/account/compare"
              >
                법인 대리점 및 원수사 지점 비교 요청
              </b-btn>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container fluid class="bg-lightest py-5">
        <header class="text-center mt-md-5">
          <h2 class="text-lg-40">
            보험상품 비교견적 흐름도
          </h2>
          <div class="mt-4 d-flex justify-content-center">
            <b-form-radio-group
              class="d-flex radio-flow"
              buttons
              button-variant="outline-light"
              v-model="serviceFlow"
            >
              <b-form-radio
                v-model="serviceFlow"
                value="customer"
                class="mx-2 rounded-pill btn-radio px-3"
                >고객 - 설계사</b-form-radio
              >
              <b-form-radio
                v-model="serviceFlow"
                value="company"
                class="mx-2 rounded-pill btn-radio px-3"
                >설계사 - 법인</b-form-radio
              >
            </b-form-radio-group>
          </div>
        </header>
        <section class="my-5 py-5">
          <div v-show="serviceFlow === 'customer'">
            <img
              :src="require('@/assets/images/service-flow-planner-1.png')"
              alt="보험상품 비교견적 흐름도 이미지"
              class="w-50 d-none d-md-block mx-auto"
            />
            <img
              :src="require('@/assets/images/service-flow-planner-1_m.svg')"
              alt="보험상품 비교견적 흐름도 이미지"
              class="w-100 d-md-none d-block mx-auto"
            />
          </div>
          <div v-show="serviceFlow === 'company'">
            <img
              :src="require('@/assets/images/service-flow-planner-2.png')"
              alt="보험상품 비교견적 흐름도 이미지"
              class="w-50 d-none d-md-block mx-auto"
            />
            <img
              :src="require('@/assets/images/service-flow-planner-2_m.svg')"
              alt="보험상품 비교견적 흐름도 이미지"
              class="w-100 d-md-none d-block mx-auto"
            />
          </div>
        </section>
      </b-container>
    </template>

    <!-- 법인 화면  -->
    <template v-if="userType == 'COMPANY'">
      <b-container fluid class="bg-lightest py-5">
        <header class="text-center mt-md-5">
          <h2 class="text-lg-40">
            설계사님 소개 흐름도
          </h2>
        </header>
        <section class="my-5 py-5">
          <img
            :src="require('@/assets/images/service-flow-company.png')"
            alt="보험상품 비교견적 흐름도 이미지"
            class="w-50 d-none d-md-block mx-auto"
          />
          <img
            :src="require('@/assets/images/service-flow-company_m.svg')"
            alt="보험상품 비교견적 흐름도 이미지"
            class="w-100 d-md-none d-block mx-auto"
          />
        </section>
      </b-container>
    </template>
    <!-- modals -->
    <edit-compare :ok="editCompareSubmit" />
  </div>
  <b-container v-else class="d-flex flex-column align-items-center pt-5">
    <header class="w-100 text-center">
      <!-- <span class="text-lg-20">샘플텍스트</span> -->
      <h1 class="mt-3 text-28 text-lg-54">
        <strong class="highlight">보험톡</strong>
        이 새로운 보험 문화를 만들겠습니다.
      </h1>
    </header>
    <b-row class="my-5 flex-grow-1 w-100 hover-control" align-v="stretch">
      <b-col
        cols="12"
        md="4"
        class="mt-4 mt-md-0 px-0"
        v-for="(item, i) in intro"
        :key="i"
        @mouseenter="isHover = i"
        @mouseleave="isHover = null"
        :style="{
          maxHeight: '540px',
          backgroundImage: `url(${item.src})`,
          backgroundSize: 'cover',
        }"
      >
        <div class="hover-over" :class="isHover == i ? 'active' : ''"></div>

        <div
          class="h-100 text-center py-4 px-3 hover-item"
          :class="isHover == i ? 'active' : ''"
        >
          <div
            class="mt-3 h-100 d-flex flex-column justify-content-between align-items-center"
          >
            <h2 class="mb-5 text-24 text-lg-30">
              {{ item.title }} <br />
              <strong class="highlight highlight-light">
                <span>{{ item.titleEmphasis }}</span>
              </strong>
            </h2>
            <div class="flex-shrink-1 flex-fill mt-5 mb-auto">
              <ul class="list-unstyled py-0 " :style="{ width: '95%' }">
                <li class="mb-0" v-for="(item2, l) in item.desc" :key="l">
                  - {{ item2 }}
                </li>
              </ul>
            </div>
            <b-btn
              :variant="isHover == i ? 'white text-darkest' : 'primary'"
              class="rounded-0 w-75 mt-auto mb-5 py-3"
              @click.prevent="go(item)"
            >
              {{ item.to }}
            </b-btn>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Modals from "@/components/Modals/index";
// import EditCompare from "../../coxmponents/Modals/EditCompare.vue";
export default {
  components: { ...Modals },
  computed: {
    cptdContent() {
      if (!this.userType) return;
      const top = this.top[this.userType.toLowerCase()];
      return { top };
    },
    userType() {
      const user = this.user;
      return user ? user.userType : this.$route.query.userType;
    },
  },
  data() {
    return {
      isHover: null,
      serviceFlow: "customer",
      intro: [
        {
          title: "보험상품 비교 가입을 원하시는",
          titleEmphasis: "고객님",
          desc: [
            "다양한 회사의 다양한 상품 비교를 원하시는 고객님",
            "내가 원하는 조건에 따라 설계사를 선택하여 비교 받기를 원하시는 분",
          ],
          src: require("@/assets/images/intro-1.png"),
          hover: require("@/assets/images/intro-1-hover.png"),
          to: "고객 페이지 바로가기",
          url: "/",
          userType: "CUSTOMER",
        },
        {
          title: "고객을 소개받기 원하시는",
          titleEmphasis: "설계사님",
          desc: [
            "다른 법인 대리점(보험회사 포함)으로 이직을 원하시는 설계사님",
            "보험설계사가 되기를 원하시는 분",
          ],
          src: require("@/assets/images/intro-2.png"),
          hover: require("@/assets/images/intro-2-hover.png"),
          to: "설계사 페이지 바로가기",
          url: "/",
          userType: "PLANNER",
        },
        {
          title: "설계사를 소개받기 원하시는",
          titleEmphasis: "법인 대리점 대표님",
          desc: ["보험회사 지점장님 포함"],
          src: require("@/assets/images/intro-3.png"),
          hover: require("@/assets/images/intro-3-hover.png"),
          to: "법인 대리점 페이지 바로가기",
          url: "/",
          userType: "COMPANY",
        },
      ],
      top: {
        customer: {
          subtitle: "보험톡에 오신 걸 환영합니다.",
          title: "보험톡이 소개해 드립니다.",
          content:
            "보험톡이 우리동네 우수설계사 소개를 통해 고객님께 최적화된 상품을 비교해드립니다.",
          li: [
            "다양한 회사의 다양한 상품 비교를 원하시는 분",
            "온라인으로 보험 가입하기를 원하시는 분",
            "내가 원하는 조건에 따라 설계사를 선택하여 비교 받기를 원하시는 분",
            "제대로 설계하는 설계사를 모르시는 분",
            "기존의 설계사와 연락이 안되시는 분",
          ],
          btn: {
            text: "보험상품 비교 요청<br />(무료 회원가입)",
            to: "/account/compare",
          },
          src: require("@/assets/images/customer.png"),
        },
        planner: {
          subtitle: "보험톡에 오신 걸 환영합니다.",
          title:
            "보험톡이 보험 가입을 원하는 <br />고객님을 소개시켜 드립니다.",
          content: "보험톡이 소개해 드립니다.",
          li: [
            "지인 영업만으론 힘드셨던 분",
            "상품은 좋은데 권유할 고객이 없으셨던 분",
            "고가의 고객DB 구매가 부담스러우셨던 분",
            "새로운 마케팅 기회를 얻고 싶으신 분",
            "내가 찾아가는 것이 아니라 고객님이 나를 찾아오게 하는 영업을 하고싶으신 분",
          ],
          btn: {
            text: "고객 소개받기(회원가입)",
            to: "/account/compare",
          },
          src: require("@/assets/images/planner-1.png"),
        },
        company: {
          subtitle: "보험톡에 오신 걸 환영합니다.",
          title: "보험톡이 우리동네 설계사님을<br/>소개해 드립니다",
          content: "보험톡이 소개해 드립니다.",
          li: [
            "설계사님을 소개받고 싶으신 분",
            "우리 법인(지점)을 주변에 소개하고 싶으신 분",
            "리쿠르트 비용이 부담스러우신 분",
          ],
          btn: {
            text: "설계사님 소개받기(회원가입)",
            to: "/account/compare",
          },
          src: require("@/assets/images/company.png"),
        },
      },
    };
  },
  methods: {
    go(item) {
      this.$router.push({
        path: this.$route.path,
        query: { userType: item.userType },
      });
    },
    goCompare() {
      console.log("goCompare");
      this.$store.commit("set", ["tempUser", { userType: this.userType }]);
      this.$router.push({
        path: "/account/compare",
      });
    },
    editCompare() {
      this.$bvModal.show("modal-edit-compare");
    },
    editCompareSubmit() {
      window.alert("비교요청서 수정 완료");
    },
  },
};
</script>

<style lang="scss" scoped></style>
