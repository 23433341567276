import moment from "moment";
import "moment/locale/ko";
import regex from "../lib/regex";

export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        meta() {
          const meta = this.$route.matched.reduce(
            (obj, m) => {
              return { ...obj, ...m.meta };
            },
            { hideSidebar: true, hideHeader: false }
          );
          return meta;
        },
        user() {
          return this.$store.getters.user;
        },
        $types() {
          return this.$store.getters.types;
        },
      },
      data() {
        return {
          s3: "https://insurance-talk-images.s3.ap-northeast-2.amazonaws.com/",
          progress: {
            status: false,
            per: 0,
          },
        };
      },
      filters: {
        getDDay(value) {
          const now = new Date();
          const gap = new Date(value).getTime() - now.getTime();
          const result = Math.ceil(gap / (1000 * 60 * 60 * 24));
          return result > 0 ? `D - ${result}` : "모집 종료";
        },
      },
      methods: {
        imgO2T(src) {
          return src.split(".").join("_tmb.");
        },
        txtV2T(val) {
          const map = {
            CUSTOMER: "고객",
            PLANNER: "설계사",
            COMPANY: "기업",
          };
          return map[val];
        },
        setType(arr, type) {
          // value 기준으로 정렬
          arr.sort((a, b) => {
            +a.sort - b.sort;
          });
          // type이 있는 경우 prefix 추가
          return arr.map((item) => ({
            ...item,
            value: type ? `${type}-${item.value}` : item.value,
          }));
        },
        // getArea(area) {
        //   return area.map((val) => {
        //     if (val === null) return "상관 없음";
        //     const item = this.$types.area.find((a) => a.value === val);
        //     return item ? item.name : "-";
        //   });
        // },
        getAge(date, floor) {
          var dob = "19" + date;
          var year = Number(dob.substr(0, 4));
          var month = Number(dob.substr(4, 2)) - 1;
          var day = Number(dob.substr(6, 2));
          var today = new Date();
          var age = today.getFullYear() - year;
          if (
            today.getMonth() < month ||
            (today.getMonth() == month && today.getDate() < day)
          ) {
            age--;
          }
          return floor ? Math.floor(age / 10) + "0" : age;
        },
        typesFormatter(type, value) {
          if (type === null || type === undefined) return "-";
          const data = ["area", "gender"].includes(type)
            ? [...this.$types[type], { name: "상관 없음", value: null }]
            : this.$types[type];
          if (type === "gender") {
            return value % 2 ? "남성" : "여성";
          } else if (Array.isArray(value)) {
            return value.map((val) => {
              const obj = data.find((t) => t.value === val);
              return obj ? obj.name : "(알 수 없음)";
            });
          } else {
            const obj = data.find((t) => t.value === value);
            return obj ? obj.name : "(알 수 없음)";
          }
        },
        objectTypesFormatter(object) {
          const typeKeys = Object.keys(this.$types);
          return Object.keys(object).reduce((obj, key) => {
            if (typeKeys.includes(key)) {
              obj[key] = this.typesFormatter(key, object[key]);
            } else {
              obj[key] = object[key];
            }
            return obj;
          }, {});
        },
        toLocaleDate(value, addDate) {
          console.log("@@", addDate);
          let m = moment(value);
          m = addDate ? m.add(addDate, "days") : m;
          return m.format("YYYY-MM-DD");
        },
        toLocaleDateTime(value, addDate = 0) {
          let m = moment(value);
          m = addDate ? m.add(addDate, "days") : m;
          return m.format("YYYY-MM-DD hh:mm:ss");
        },
        getFileSize(x) {
          const s = ["bytes", "KB", "MB", "GB", "TB", "PB"];
          const e = Math.floor(Math.log(x) / Math.log(1024));
          return x ? (x / Math.pow(1024, e)).toFixed(0) + " " + s[e] : 0;
        },
        async downloadFile({ file, name }) {
          try {
            const { data } = await this.$axios({
              url: `/files/download?key=${file}`,
              method: "GET",
            });
            const byteCharacters = atob(data.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            const url = window.URL.createObjectURL(new Blob([byteArray]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.log(error);
          }
        },
        async signOut() {
          localStorage.removeItem("insTalk__token");
          sessionStorage.removeItem("insTalk__token");
          try {
            window.firebase.messaging().deleteToken();
          } catch (error) {}

          this.$store.commit("set", ["user", null]);
          this.axios.defaults.headers.common["Authorization"] = undefined;
          this.$router.push("/account/login");
        },
        async getMe() {
          return await this.$store.dispatch("getMe");
        },
        checkValue(type, val) {
          let value;

          switch (type) {
            case "password":
              value = regex.password.test(val)
                ? false
                : "비밀번호는 영어, 숫자 포함 8글자 이상 입력하세요.";
              break;
            case "password2":
              value =
                this.input.password !== this.input.password2
                  ? "비밀번호가 다릅니다."
                  : false;
              break;
          }
        },
        async getGeoCode(query) {
          const url = `/reverse-geocode`;
          let { data } = await this.$axios.get(url, {
            params: {
              query: query,
            },
          });
          data = JSON.parse(data.data);
          const result = data.results;
          if (!result.length) {
            await alert("검색 결과가 없습니다.");
          } else {
            const addr = result[0];
            const { lng, lat } = addr.geometry.location;
            return { lng, lat };
          }
        },
        getMobile({ openMobile, mobile }) {
          return openMobile ? mobile : "비공개";
        },
        getEmail({ openEmail, email }) {
          return openEmail ? email : "비공개";
        },
        queryInit(opt) {
          this.nextQuery = {
            ...opt,
            ...this.params,
          };
        },
        Search(e, page) {
          this.routeControl("page", page);
        },
        routeControl(key, value) {
          const path = this.$route.path;
          const query = { ...this.nextQuery, [key]: value };

          Object.keys(query).forEach((key) => {
            if (query[key] !== false && !query[key]) {
              delete query[key];
            }
          });

          window.scrollTo(0, 0, "smooth");

          try {
            this.$router.push({ path, query });
          } catch (error) {}
        },
        isMine(relativeUser) {
          const user = this.$store.getters && this.$store.getters.user;
          return relativeUser && user && relativeUser.id === user.id;
        },
        $numberOnly(e, key, maxLength, comma) {
          if (e && e.target) {
            let val = e.target.value.replace(/\D/g, ""); //숫자가 아닌것을 제거, 즉 [0-9]를 제외한 문자 제거; /[^0-9]/g 와 같은 표현v
            val = val.substr(0, maxLength);
            if (comma) {
              val = (+val).toLocaleString();
            }
            this.input[key] = val;
          }
        },
        $inputNumber(e, key, min, max, comma) {
          if (e && e.target) {
            let val = e.target.value.replace(/\D/g, ""); //숫자가 아닌것을 제거, 즉 [0-9]를 제외한 문자 제거; /[^0-9]/g 와 같은 표현
            if (min > val) val = min;
            else if (max < val) val = max;

            if (comma) {
              val = (+val).toLocaleString();
            }
            this.input[key] = val;
          }
        },

        // Quill

        async fileUpload(file, useProgress) {
          const formData = new FormData();
          formData.append("file", file);

          let config = useProgress
            ? {
                onUploadProgress: (e) => {
                  this.progress = {
                    status: e.total > e.loaded,
                    per: ((e.loaded / e.total) * 100).toFixed(),
                  };
                },
              }
            : {};

          const { data } = await this.$axios.post(
            "/files/image",
            formData,
            config
          );

          if (data.status.code === "0000") {
            return data.data;
          } else {
            this.files = [];
            await alert(data.status.message);
          }
        },
        async imageAdded(file, Editor, cursorLocation, resetUploader) {
          const result = await this.fileUpload(file);
          let url = result.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        },
        imageRemoved(el) {
          // todo
        },
      },
    });
  },
};
