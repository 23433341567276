<template>
  <div>
    <header
      class="d-md-flex align-items-end justifty-content-between pb-4 border-bottom border-light"
    >
      <h6 class="ml-0 mb-3 mb-md-0 text-18 text-lg-16 flex-grow-1">
        {{ item.title }}
      </h6>
      <small class="text-14 text-dark" v-if="$route.path.includes('/notice')">
        {{ toLocaleDate(item.createdAt) }}
      </small>
      <small
        class="text-14 text-dark"
        v-else-if="$route.path.includes('/event')"
      >
        {{ item.range.join(" ~ ") }}
      </small>
    </header>
    <section class="py-4">
      <img
        v-if="$route.path.includes('/event')"
        :src="s3 + item.origin"
        alt=""
        class="w-100 mb-3"
      />
      <div v-html="item.content" />
    </section>
  </div>
</template>

<script>
export default {
  name: "Detail",
  data() {
    return {
      item: {},
    };
  },
  async mounted() {
    this.item = await this.getItem();
    console.log("------------------------------------");
    console.log(this.item);
    console.log("------------------------------------");
  },
  methods: {
    async getItem() {
      const { data } = await this.$axios.get(
        `/${this.meta.api}/${this.$route.params.id}`
      );
      return data.data;
    },
  },
};
</script>

<style lang="scss" scoped></style>
