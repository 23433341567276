<template>
  <div>
    <b-form @submit.prevent.stop="submit">
      <b-row align-v="center">
        <b-col
          cols="12"
          md="3"
          class="mb-5 mb-md-0 text-center d-flex align-items-center justify-content-center"
          v-if="user.userType == 'PLANNER'"
        >
          <div class="text-center">
            <b-avatar
              size="8.75rem"
              class="backdrop-dim edit-profile bg-light"
              :src="url"
            />
            <label
              class="position-absolute text-white"
              :style="{ bottom: 0, left: 0, right: 0, zIndex: 2 }"
              >편집
              <b-form-file
                class="d-none"
                @click.prevent="fileChecker"
                @change="fileControl"
              ></b-form-file>
            </label>
          </div>
        </b-col>
        <b-col cols="12" :md="user.userType == 'PLANNER' ? '9' : '12'">
          <label class="input-custom w-100 mb-3">
            휴대폰 번호 (아이디)
            <w-input
              disabled
              :plaintext="true"
              type="text"
              placeholder="아이디를 입력하세요."
              v-model="input.username"
            ></w-input>
          </label>
          <label class="input-custom w-100 mb-3">
            비밀번호
            <b-input-group class="mb-2">
              <b-form-input
                type="text"
                class="bg-white"
                placeholder="비밀번호 변경하기"
                disabled
                :style="{ borderRight: '0 ' }"
              ></b-form-input>
              <template #append>
                <b-input-group-text
                  class="mt-2 p-0 bg-transparent"
                  :style="{ borderLeft: '0 ' }"
                >
                  <b-btn variant="text" class="mr-2 p-0" @click="editPassword">
                    <i class="icon-edit text-black"></i>
                  </b-btn>
                </b-input-group-text>
              </template>
            </b-input-group>
          </label>
        </b-col>
      </b-row>
      <label class="input-custom w-100 mb-4" v-if="user.userType !== 'COMPANY'">
        이름
        <w-input
          type="text"
          placeholder="이름을 입력하세요."
          v-model="input.name"
          name="displayname"
          :rules="{ required: true, regex: /^[가-힣].*$/ }"
        />
      </label>
      <label class="input-custom w-100 mb-3" v-if="user.userType !== 'COMPANY'">
        주민번호 앞 7자리
        <div class="d-flex align-items-start">
          <w-input
            placeholder="000000"
            v-model="input.birth"
            name="birth"
            rules="required|birth"
            maxlength="7"
            no-wheel
            @input.native="$numberOnly($event, 'birth', 6)"
          />
          <div class="mx-2 mx-md-4 mt-4">-</div>
          <w-input
            placeholder="0"
            v-model="input.gender"
            rules="required"
            maxlength="1"
            no-wheel
            input-class="rounded"
            @input.native="$numberOnly($event, 'gender', 1)"
          >
            <template #append>
              <div
                class="position-absolute mt-4 mr-4 text-dark pr-2"
                :style="{
                  left: '2rem',
                  top: 0,
                  bottom: 0,
                  zIndex: 10,
                }"
              >
                &#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
              </div>
            </template>
          </w-input>
        </div>
      </label>
      <label class="input-custom w-100 mb-3" v-if="user.userType === 'COSTOMER'"
        >거주지
        <w-select
          text-field="name"
          value-field="value"
          v-model="input.area"
          :options="area"
          rules="required"
        />
      </label>
      <label class="input-custom w-100 mb-4" v-if="user.userType == 'PLANNER'">
        협회등록번호
        <w-input
          type="text"
          placeholder="협회등록번호를 입력하세요."
          v-model="input.accessionNumber"
        ></w-input>
      </label>
      <template v-else-if="user.userType === 'COMPANY' && input.info">
        <label class="input-custom w-100 mb-3">
          법인 대리점 대표명
          <w-input
            type="text"
            placeholder="이름을 입력하세요."
            v-model="input.name"
            name="displayname"
            :rules="{ required: true, regex: /^[가-힣].*$/ }"
          ></w-input>
        </label>
        <label class="input-custom w-100 mb-3">
          법인 대리점 상호명
          <w-input
            name="branchName"
            placeholder="법인 대리점 상호명을 입력하세요."
            v-model="input.info.branchName"
            :rules="{ required: true, regex: /^[가-힣].*$/ }"
          />
        </label>
        <label class="input-custom w-100 mb-3">
          법인 대리점 등록번호
          <w-input
            type="text"
            name="branchNumber"
            placeholder="법인 대리점 등록번호를 입력하세요."
            v-model="input.info.branchNumber"
            :rules="{ regex: /^[0-9].*$/ }"
          ></w-input>
        </label>
        <label class="input-custom w-100 mb-3">
          주소
          <w-input
            type="text"
            name="addr"
            placeholder="주소를 입력하세요."
            v-model="input.info.addr"
            rules="required"
          />
        </label>
        <label class="input-custom w-100 mb-3">
          세금계산서 이메일
          <w-input
            type="email"
            name="email"
            placeholder="이메일을 입력하세요."
            v-model="input.info.email"
            rules="required|email"
          />
        </label>
      </template>

      <b-checkbox class="mb-2" v-model="input.agreeSMS">
        [선택] 마케팅 메시지 수신(SMS)에 동의합니다.
      </b-checkbox>
      <div class="d-flex">
        <b-btn variant="link text-secondary" class="mr-5 p-0">회원탈퇴</b-btn>
        <b-btn variant="link text-secondary" class="p-0" to="/account/sign-out"
          >로그아웃</b-btn
        >
      </div>
      <b-row align-h="end" class="mt-5">
        <b-col cols="6" md="3">
          <b-btn variant="light" class="w-100 py-3 py-md-4">취소</b-btn>
        </b-col>
        <b-col cols="6" md="3">
          <b-btn variant="primary" type="submit" class="w-100 py-3 py-md-4"
            >수정</b-btn
          >
        </b-col>
      </b-row>
    </b-form>

    <!-- modal -->
    <change-password />
  </div>
</template>

<script>
import Modals from "@/components/Modals/index";
import ChangePassword from "../../components/Modals/ChangePassword.vue";

export default {
  components: { ...Modals },

  data() {
    return {
      input: {},
      area: [],
      url: null,
      rules: {
        require: [(v) => !!v || "필수 입력값입니다."],
        username: [
          (v) => !!v || "필수 입력값입니다.",
          (v) => !/[^0-9]/.test(v) || "숫자만 입력해주세요.",
          (v) =>
            (v.length >= 10 && v.length < 12) ||
            "휴대폰번호 길이을 확인해주세요.",
        ],
        password: [
          (v) => !!v || "필수 입력값입니다.",
          (v) =>
            (v.length >= 8 && v.length <= 16) ||
            "비밀번호는 8 ~ 16자로 사용하세요.",
          (v) =>
            [
              /^[0-9].*$/.test(v),
              /[a-z]/.test(v),
              /[A-Z]/.test(v),
              /[`!@#$%^&*()_+\-=\[\]\\{}|;':",./<>?]/.test(v),
            ].filter((bool) => bool).length > 2 ||
            "영문 대/소문자, 숫자, 특수문자 중 3가지 이상을 사용하세요.",
        ],
        passwordRetype: [
          (v) => !!v || "필수 입력값입니다.",
          (v) => this.input.password === v || "비밀번호가 일치하지 않습니다.",
        ],
        name: [
          (v) => !!v || "필수 입력값입니다.",
          (v) =>
            !/[^가-힣a-zA-Z]/.test(v) ||
            "한글과 영문 대/소문자를 사용하세요. (특수기호, 공백 사용 불가)",
        ],
        birth: [
          (v) => !!v || "필수 입력값입니다.",
          (v) => v.length === 6 || "6자리로 입력해주세요.",
          (v) =>
            (+v.substr(2, 2) < 13 && +v.substr(4, 2) < 32) ||
            "잘못된 생년월일입니다.",
        ],
        gender: [
          (v) => !!v || "필수 입력값입니다.",
          (v) => (1 <= v && v <= 4) || "주민등록번호 뒤 1자리를 확인해주세요.",
        ],
      },
    };
  },
  async mounted() {
    this.input = { ...this.input, ...(await this.$store.dispatch("getMe")) };
    this.url = this.s3 + this.input.thumbnail;
    this.getArea();
  },
  methods: {
    async getArea() {
      const { data } = await this.$axios.get("/types", {
        params: { category: "area" },
      });
      console.log(data.data);
      this.area = data.data;
    },
    async submit() {
      try {
        const { data } = await this.$axios.patch("/account/me", this.input);
        if (data.success) {
          await alert("회원정보가 변경되었습니다.");
          this.$router.go(-1);
        }
      } catch (error) {}
    },
    async fileChecker(e) {
      if (
        !(await confirm(
          "프로필 사진은 파일 업로드시 즉시 수정됩니다. 수정하시겠습니까?"
        ))
      ) {
        return;
      }
    },
    async fileControl(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("thumbnail", file);
      try {
        const { data } = await this.$axios.patch(
          "/account/me/thumbnail",
          formData
        );
        if (data.success) {
          await alert("프로필 사진이 변경되었습니다.");
          this.url = data.data;
        }
      } catch (error) {}
    },
    editPassword() {
      this.$bvModal.show("modal-change-password");
    },
  },
};
</script>

<style lang="scss" scoped></style>
