<template>
  <div>캐시 result: {{ data }}</div>
</template>

<script>
export default {
  computed: {
    query() {
      return this.$route.query;
    },
  },
  data() {
    return {
      data: null,
    };
  },
  async mounted() {
    this.payment();
  },

  methods: {
    async payment() {
      const { id: selected } = this.$route.params;
      const { imp_success, error_msg } = this.$route.query;
      console.log(this.$route.query);
      if (imp_success !== "true") {
        await alert(
          error_msg ? error_msg.split(" | ")[1] : "결제에 실패했습니다."
        );
        return this.$router.push("/mypage/mileage");
      }
      const { data } = await this.$axios.post("/payments/m", {
        ...this.query,
        selected,
      });
      if (data.success) {
        this.$router.push("/mypage/mileage");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
