<template>
  <b-modal
    :visible="!!item"
    centered
    size="lg"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-md-5 mx-md-5 pb-md-5 mb-5 justify-content-center"
    title="고객 정보"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-view-customer"
    ok-title="확인"
    ok-variant="primary"
    ok-only
    @hide="$emit('hide')"
  >
    <!-- <div class="subtitle">유료 회원 결제 완료</div> -->
    <b-row align-h="center" class="mb-5" v-if="item">
      <b-col cols="8" class="border px-md-5">
        <table class="w-100 my-4 text-14">
          <tbody>
            <tr>
              <td class="py-1 fw-700 px-3">이름</td>
              <td class="py-1 px-3 text-nowrap">
                {{ item.relativeAuthor.name }}
              </td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">주민번호</td>
              <td class="py-1 px-3 text-nowrap">
                {{ item.relativeAuthor.birth }} -
                {{ 1 }}&#11044;&#11044;&#11044;&#11044;&#11044;&#11044;
              </td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">휴대폰번호</td>
              <td class="py-1 px-3 text-nowrap">
                {{ item.relativeAuthor.username }}
              </td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">지역</td>
              <td class="py-1 px-3 text-nowrap">
                <span class="text-14">{{ item.area }}</span>
              </td>
            </tr>
            <tr>
              <td class="py-1 fw-700 px-3">가입하고 싶은 보험 종목</td>
              <td class="py-1 px-3 text-nowrap">종합보험, 종신보험</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: { type: Object },
  },
};
</script>

<style></style>
