<template>
  <b-modal
    centered
    size="xl"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 pb-5 px-5"
    title="법인 소개 편집"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-edit-introduce"
    ok-title="수정"
    ok-variant="primary"
    cancel-title="취소"
    cancel-variant="light"
    @ok.prevent="submit"
    @show="shown"
  >
    <div class="pb-5 px-4">
      <header class="text-lg-20 fw-700 mb-4">
        보험사 선택
      </header>
      <label>GA</label>
      <button-selector v-model="input.insType" :items="$types.ga" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'ga-custom'"
            @input="input.insType = 'ga-custom'"
            text="직접입력"
            value="ga-custom"
          />
        </b-col>
        <b-col :class="`p-2 text-nowrap`" v-if="input.insType === 'ga-custom'">
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label>손해보험</label>
      <button-selector v-model="input.insType" :items="$types.sonhae" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'sonhae-custom'"
            @input="input.insType = 'sonhae-custom'"
            text="직접입력"
            value="sonhae-custom"
          />
        </b-col>
        <b-col
          :class="`p-2 text-nowrap`"
          v-if="input.insType === 'sonhae-custom'"
        >
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label>생명보험</label>
      <button-selector v-model="input.insType" :items="$types.life" />
      <b-row class="mx-n2 mb-4">
        <b-col v-bind="cols" :class="`p-2 text-nowrap`">
          <button-checkable
            :checked="input.insType === 'life-custom'"
            @input="input.insType = 'life-custom'"
            text="직접입력"
            value="life-custom"
          />
        </b-col>
        <b-col
          :class="`p-2 text-nowrap`"
          v-if="input.insType === 'life-custom'"
        >
          <label class="input-custom w-100 mb-4">
            <b-form-input
              type="text"
              class="m-0"
              v-model="input.customText"
              @input="$emit('input', $event)"
            ></b-form-input>
          </label>
        </b-col>
      </b-row>
      <label class="input-custom w-100 mb-4">
        규모
        <b-select v-model="input.size">
          <b-form-select-option
            v-for="(size, i) in $types.size"
            :key="i"
            :value="size.value"
            >{{ size.name }}</b-form-select-option
          >
        </b-select>
      </label>

      <label class="input-custom w-100 mb-4">
        대표(지점장) 소개
        <b-textarea :rows="5" class="w-100 h-auto" v-model="input.introCeo" />
      </label>
      <label class="input-custom w-100 mb-4">
        법인 및 지점(사) 소개
        <b-textarea
          :rows="5"
          class="w-100 h-auto"
          v-model="input.introCompany"
        />
      </label>
      <label class="input-custom w-100 mb-4">
        근무지 혜택
        <b-textarea :rows="5" class="w-100 h-auto" v-model="input.benefit" />
      </label>
    </div>
  </b-modal>
</template>

<script>
import ButtonCheckable from "@/components/ButtonCheckable";
import ButtonSelector from "@/components/ButtonSelector";
import _ga from "../../lib/ga.js";
import _insurance from "../../lib/insurance.js";
import Modals from "@/components/Modals/index";
export default {
  props: {
    ok: {
      type: Function,
    },
  },
  components: { ButtonSelector, ButtonCheckable, ...Modals },
  data() {
    return {
      input: {},
      ga: [],
      life: [],
      sonhae: [],
      period: [],
      area: [],
      cols: {
        cols: 6,
        sm: 4,
        md: 3,
      },
    };
  },
  methods: {
    async shown() {
      const user = await this.getMe();
      this.input = { ...this.input, ...user.info };
    },
    async submit(e, ee) {
      try {
        const { data } = await this.$axios.patch("/account/info", this.input);
        this.getMe();
        if (data.success) this.$bvModal.hide("modal-edit-introduce");
      } catch (error) {}
    },
  },
};
</script>

<style></style>
