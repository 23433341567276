<template>
  <article class="bg-white shadow-sm p-3 p-md-4" v-if="item.relativeUser">
    <!-- :style="{ opacity: expiry && !item.accept ? 0.4 : 1 }" -->
    <div class="d-flex align-items-center">
      <b-form-checkbox
        v-if="!expiry"
        :checked="value"
        @input="$emit('input', $event)"
      ></b-form-checkbox>
      <header class="d-flex align-items-center">
        <b-avatar
          v-if="item.relativeUser.userType === 'PLANNER'"
          size="3.125rem"
          class="mr-2 mr-md-3"
          :src="s3 + item.relativeUser.thumbnail"
        />
        <div v-if="isPlanner">
          <h6 class="fw-700 text-truncate text-14 text-lg-16">
            {{ item.relativeUser.name }} ({{
              item.relativeUser.gender % 2 ? "남" : "여"
            }}
            {{ getAge(item.relativeUser.birth) }}세)
          </h6>
          <span class="text-dark text-14 text-truncate"
            >{{ currentCareer }}
          </span>
        </div>
        <div v-else>
          <h6 class="fw-700 text-truncate text-16 text-lg-18">
            {{ item.relativeUser.info.branchName }}
          </h6>
          <!-- <span class="text-dark text-14 text-truncate">{{
            currentCareer
          }}</span> -->
        </div>
      </header>
    </div>
    <div class="mt-3 text-14" v-if="isPlanner">
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">활동지역</strong>
        </b-col>
        <b-col cols="8" class="px-1">
          {{ typesFormatter("area", item.relativeUser.info.area).join(", ") }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">경력사항</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          <ul class="list-unstyled p-0 m-0">
            <li
              class="mb-1 text-14"
              v-for="(career, idx) in cptdCareers"
              :class="idx ? 'fw-300' : 'fw-400'"
              v-text="career"
              :key="idx"
            />
          </ul>
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">자기소개</strong>
        </b-col>
        <b-col cols="8" class="px-1">
          {{ item.relativeUser.info && item.relativeUser.info.content }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">연락처</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          {{ item.relativeUser.username }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1" align-v="center">
        <b-col cols="4" class="text-nowrap px-1">
          <strong class="text-nowrap">평점 / 후기</strong>
        </b-col>
        <b-col cols="8" class="d-flex align-items-center px-1">
          <i class="icon-star text-24 text-primary ml-n2"></i>
          {{ item.statistics ? item.statistics.starRate.toFixed(1) : "-" }}
          ({{ item.statistics ? item.statistics.total : "-" }}개)

          <b-btn
            variant="text"
            class="py-0 px-1"
            v-b-modal="`modal-${item.id}`"
          >
            <i class="icon-next text-24"></i>
          </b-btn>
          <PlannerReview :item="item" @written="item.statistics.written++" />
        </b-col>
      </b-row>
      <!-- <b-row class="mt-5" align-h="center">
        <b-col cols="8">
          <b-btn variant="primary" class="w-100" @click="viewRequest"
            >요청서 보기</b-btn
          >
        </b-col>
      </b-row> -->
    </div>
    <div class="px-2 mt-3" v-else>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">구분</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          {{ currentCareer }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">대표</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          {{ item.relativeUser.name }} 대표
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">주소</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          {{ item.relativeUser.info.addr }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">규모</strong>
        </b-col>
        <b-col cols="8" class="text-truncate px-1">
          {{ typesFormatter("size", item.relativeUser.info.size) }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">대표 소개</strong>
        </b-col>
        <b-col cols="8 px-1">
          {{ item.relativeUser.info.introCeo }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">법인 소개</strong>
        </b-col>
        <b-col cols="8 px-1">
          {{ item.relativeUser.info.introCompany }}
        </b-col>
      </b-row>
      <b-row class="mb-2 mx-n1">
        <b-col cols="4" class="px-1">
          <strong class="text-nowrap">근무지 혜택</strong>
        </b-col>
        <b-col cols="8 px-1">
          {{ item.relativeUser.info.benefit }}
        </b-col>
      </b-row>
    </div>
  </article>
</template>

<script>
import Modals from "@/components/Modals/index";
export default {
  props: {
    value: Boolean,
    expiry: Boolean,
    item: Object,
    viewRequest: Function,
    viewReview: Function,
    types: Object,
  },
  components: {
    PlannerReview: Modals.PlannerReview,
  },
  computed: {
    isPlanner() {
      return this.item.relativeUser
        ? this.item.relativeUser.userType === "PLANNER"
        : null;
    },
    cptdCareers() {
      if (this.item.relativeUser.info) {
        const { careers } = this.item.relativeUser.info;
        if (careers) {
          let total = 0;
          const result = careers.map((c) => {
            total += c.period || 0;
            return `${c.name} ${c.position} ${c.period || 0}년`;
          });
          result.unshift(`총 경력 ${total}년`);
          return total ? result : [];
        } else return [];
      } else return [];
    },
    currentCareer() {
      if (this.item.relativeUser.info) {
        const { insType, branch } = this.item.relativeUser.info;
        const { ga, life, sonhae } = this.$store.getters.types;
        if (this.item.relativeUser.userType === "PLANNER") {
          if (typeof insType === "string") {
            // custom
            return `${this.item.relativeUser.info.customText} ${branch}`;
          } else if (insType < 0) {
            // 아마 이 경우는 없을 듯.
            return `-`;
          } else {
            const types = [...ga, ...life, ...sonhae];
            console.log(
              types.map((item) => item.value),
              insType
            );
            const current = types.find((t) => t.value === insType);
            return current ? `${current.name} ${branch || branchName}` : "-";
          }
        } else {
          // Company의 경우
          return this.typesFormatter("insType", type);
        }
      } else return "-";
    },
  },
};
</script>

<style lang="scss" scoped></style>
