<template>
  <div class="layout d-flex flex-column">
    <global-nav />
    <router-view class="flex-grow-1" />
    <global-footer />
  </div>
</template>

<script>
import GlobalNav from "@/components/GlobalNav";
import GlobalFooter from "@/components/GlobalFooter.vue";
export default {
  // name: "Home",
  components: { GlobalNav, GlobalFooter },
};
</script>
