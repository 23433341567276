<template>
  <b-modal
    centered
    size="lg"
    header-class="border-0 justify-content-center py-5"
    footer-class="border-0 px-5 mx-5 pb-5 mb-5"
    title="유료 회원 결제"
    title-class="h2 pt-5"
    body-class="px-4"
    id="modal-payment"
    ok-title="결제"
    ok-variant="secondary"
    cancel-title="취소"
    cancel-variant="light"
    @ok="ok"
  >
    <div class="px-5 mb-5 pb-5">
      <h5 class="mb-2">유료 회원 서비스</h5>
      <p class="text-dark">
        There are advances being made in science and technology everyday, and a
        good exa mple of this is the LCD monitor. LCD monitors have several
        benefits over the old chun ky computer monitors that most users are
        familiar with. Old computer monito rs, tak e up quite a bit of desktop
        space, put out a ton of heat, drain a ton of energy, and mo stoften have
        low picture quality and resolution. Modern advances with the LCD comp
        uter monitors have changed a lot of that. The screen itself is flat,
        There are advances being made in science and technology everyday, and a
        good example of th is is the LCD monitor. LCD monitors have several
        benefits over the old chunky com puter mon itors that most users are
        familiar with. Old computer monitors, take up quite a bit of desktop
        space, put out a ton of heat, drain a ton of energy, and most often have
        low picture quality and resolution. Modern advances with the LCD
        computer monitors h ave changed a lot of that. The screen itself is
        flat, a
      </p>
      <div class="text-right mt-4">
        <span class="text-primary">유료 회원 결제 금액: 기본 10,000원 이상</span
        ><br />
        <small class="text-secondary"
          >*적립 캐시 10,000 + 무료 제공 마일리지 10%: 1,000</small
        >
      </div>
    </div>
    <div class="px-5 mb-5">
      <div class="mb-2">
        <small>결제 유의사항 동의 안내</small>
      </div>
      <p class="text-dark p-3 border border-light">
        환불 시 적립 캐시의 80%를 돌려드립니다 환불 시 적립 캐시의 80%를
        돌려드립니다 환불 시 적립 캐시의 80%를 돌려드립니다 환불 시 적립 캐시의
        80%를 돌려드립니다 환불 시 적립 캐시의 80%를 돌려드립니다 환불 시 적립
        캐시의 80%를 돌려드립니다 환불 시 적립 캐시의 80%를 돌려드립니다 환불 시
        적립 캐시의 80%를 돌려드립니다 환불 시 적립 캐시의 80%를 돌려드립니다
        환불 시 적립 캐시의 80%를 돌려드립니다.
      </p>
      <b-checkbox>위 사항을 확인하였으며 동의합니다. </b-checkbox>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    ok: {
      type: Function,
    },
  },
};
</script>

<style></style>
